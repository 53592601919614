"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RatingHeader = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var RatingHeader = _styledComponents.default.div.attrs(function (props) {
  return {
    className: 'stackend-review-score'
  };
}).withConfig({
  displayName: "RatingHeaderstyle__RatingHeader",
  componentId: "sc-q5t5jp-0"
})([".stackend &{display:flex;flex-direction:column;gap:", ";padding:", ";h3{margin:0;}}"], function (props) {
  return props.theme.margins.medium;
}, function (props) {
  return props.theme.margins.medium;
});
exports.RatingHeader = RatingHeader;