"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddToBasketComments = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
var _LiveEventModule = require("../modules/LiveEventModule.style");
var _PlainCommentListing = require("./PlainCommentListing.style");
var _styledComponents2 = require("../styled-components");
var _ScrolledView = require("../ui/ScrolledView.style");
var sc = _interopRequireWildcard(require("../style-common/styled-component-variables.js"));
var _StackendTheme = require("@stackend/react/theme/StackendTheme");
var _ComponentType = _interopRequireDefault(require("@stackend/react/theme/ComponentType"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var AddToBasketComments = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-add-to-basket-comments', props.className)
  };
}).withConfig({
  displayName: "AddToBasketCommentsstyle__AddToBasketComments",
  componentId: "sc-qft8n-0"
})([".stackend &{.notifications{height:100%;background:none;border:none;overflow:hidden;", "{margin-top:0;max-width:unset;}", "{display:block;", "{.stackend-loading-text{text-align:center;}", "{padding-top:0;justify-content:start;}.notifications-body{padding-bottom:0.5em;}.notification-body-left{", "{display:flex;}", "{display:flex;}}.notification-head-right{", "{display:flex;}.stackend-reply-comment-button{display:none;}}.stackend-product-link{padding-bottom:0px;}}}}}"], _LiveEventModule.Comments, _PlainCommentListing.PlainCommentListing, _ScrolledView.ScrolledView, _ScrolledView.ScrolledElements, _styledComponents2.AuthorName, _styledComponents2.AuthorImage, _styledComponents2.AuthorName);
exports.AddToBasketComments = AddToBasketComments;