"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ToggleButtonWrapper = exports.ToggleButton = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _adminVars = require("../styled-components/Stackend/admin-vars");
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
var _StackendTheme = require("@stackend/react/theme/StackendTheme");
var _ComponentState = _interopRequireDefault(require("@stackend/react/theme/ComponentState"));
var _ComponentType = _interopRequireDefault(require("@stackend/react/theme/ComponentType"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var ToggleButton = _styledComponents.default.span.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-toggle-button', props.className)
  };
}).withConfig({
  displayName: "ToggleButtonstyle__ToggleButton",
  componentId: "sc-1sxzfsc-0"
})([".stackend &{display:inline-flex;align-items:center;gap:0.5em;margin-right:1em;> label{cursor:pointer;}}"]);
exports.ToggleButton = ToggleButton;
var ToggleButtonWrapper = _styledComponents.default.span.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-toggle-button-wrapper', props.className)
  };
}).withConfig({
  displayName: "ToggleButtonstyle__ToggleButtonWrapper",
  componentId: "sc-1sxzfsc-1"
})([".stackend &{position:relative;margin:3px;width:3em;height:1.5em;cursor:pointer;> input[type='checkbox']{cursor:pointer;height:100%;left:0;margin:0;opacity:0;position:absolute;top:0;width:100%;z-index:0;+ span{background-color:transparent;border:1px solid ", ";border-radius:2em;box-shadow:none;box-sizing:border-box;display:block;height:100%;position:relative;transition:background 300ms ease-in-out,transform 300ms ease-in-out;width:100%;&:after{background:", ";border-radius:50%;content:' ';height:1.25em;width:1.25em;left:1px;position:absolute;top:1px;transform:translateX(0);transition:background 300ms ease-in-out,transform 300ms ease-in-out;}}&:checked + span{background:", ";&:after{transform:translateX(1.5em);background:", ";}}&:focus + span{outline:", " solid ", ";}&:disabled + span{cursor:not-allowed;background:", ";}}}"], function (props) {
  return props.theme && props.theme.button ? (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BUTTON, 'borderColor') : _adminVars.colors.text;
}, function (props) {
  return props.theme && props.theme.color ? props.theme.color : _adminVars.colors.text;
}, function (props) {
  return props.theme && props.theme.button ? (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BUTTON_DEFAULT, 'backgroundColor') : _adminVars.colors.positive;
}, function (props) {
  return props.theme && props.theme.button ? (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BUTTON_DEFAULT, 'color') : _adminVars.colors.textInverse;
}, function (props) {
  return props.theme ? props.theme.borderWidth : '1px';
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BUTTON, 'outlineColor');
}, function (props) {
  return props.theme ? (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BUTTON, 'color', _ComponentState.default.DISABLED) : _adminVars.colors.input.disabled;
});
exports.ToggleButtonWrapper = ToggleButtonWrapper;