"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.DateTimePicker = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var DateTimePicker = _styledComponents.default.span.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-date-time-picker', props.className)
  };
}).withConfig({
  displayName: "DateTimePickerstyle__DateTimePicker",
  componentId: "sc-hji7o1-0"
})([".stackend &{overflow:hidden;display:inline-flex;align-items:center;label{margin-right:0.5em;}}"]);
exports.DateTimePicker = DateTimePicker;
var _default = DateTimePicker;
exports.default = _default;