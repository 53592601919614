"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReverseCreatedDateComparator = ReverseCreatedDateComparator;
exports.default = CreatedDateComparator;
var _CreatedDateAware = _interopRequireDefault(require("@stackend/api/api/CreatedDateAware"));
var _XcapObject = _interopRequireDefault(require("@stackend/api/api/XcapObject"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * Sort objects by created date. Latest first. Stabilize by id
 * @param a
 * @param b
 * @constructor
 */
function CreatedDateComparator(a, b) {
  if (a === b) {
    return 0;
  }
  if (a.createdDate === b.createdDate) {
    return b.id - a.id;
  }
  return b.createdDate - a.createdDate;
}

/**
 * Sort objects by created date. Oldest first. Stabilize by id
 * @param a
 * @param b
 * @constructor
 */
function ReverseCreatedDateComparator(a, b) {
  return CreatedDateComparator(b, a);
}