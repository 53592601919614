"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addAccordion = addAccordion;
exports.closeAccordion = closeAccordion;
exports.default = accordionReducer;
exports.handleAccordionStatus = handleAccordionStatus;
exports.isAccordionOpen = isAccordionOpen;
exports.openAccordion = openAccordion;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var TOGGLE_ACCORDION_STATUS = 'TOGGLE_ACCORDION_STATUS';
var SET_INITIAL_ACCORDION_STATUS = 'SET_INITIAL_ACCORDION_STATUS';
var CHANGE_ACCORDION_STATUS = 'CHANGE_ACCORDION_STATUS';
var ADD_TO_ACCORDION_GROUP = 'ADD_TO_ACCORDION_GROUP';
var CREATE_AND_ADD_TO_ACCORDION_GROUP = 'CREATE_AND_ADD_TO_ACCORDION_GROUP';
function changeAccordionStatus(name, isOpen, position) {
  return {
    type: TOGGLE_ACCORDION_STATUS,
    name: name,
    isOpen: isOpen,
    position: position
  };
}
function setInitialAccordionStatus(_ref) {
  var name = _ref.name,
    initiallyOpened = _ref.initiallyOpened,
    _ref$accordionGroupNa = _ref.accordionGroupName,
    accordionGroupName = _ref$accordionGroupNa === void 0 ? null : _ref$accordionGroupNa;
  return {
    type: SET_INITIAL_ACCORDION_STATUS,
    name: name,
    initiallyOpened: initiallyOpened,
    accordionGroupName: accordionGroupName
  };
}
function closeAccordion(_ref2) {
  var name = _ref2.name;
  return {
    type: CHANGE_ACCORDION_STATUS,
    name: name,
    isOpen: false
  };
}
function openAccordion(_ref3) {
  var name = _ref3.name,
    position = _ref3.position;
  return {
    type: CHANGE_ACCORDION_STATUS,
    name: name,
    isOpen: true,
    position: position
  };
}
function addToAccordionGroup(_ref4) {
  var name = _ref4.name,
    accordionGroupName = _ref4.accordionGroupName;
  return {
    type: ADD_TO_ACCORDION_GROUP,
    name: name,
    accordionGroupName: accordionGroupName
  };
}
function createAndAddToAccordionGroup(_ref5) {
  var name = _ref5.name,
    accordionGroupName = _ref5.accordionGroupName;
  return {
    type: CREATE_AND_ADD_TO_ACCORDION_GROUP,
    name: name,
    accordionGroupName: accordionGroupName
  };
}
function addAccordion(_ref6) {
  var name = _ref6.name,
    initiallyOpened = _ref6.initiallyOpened,
    accordionGroupName = _ref6.accordionGroupName;
  return function (dispatch, getState) {
    if (!!accordionGroupName) {
      var groupObject = getState().accordionReducer.accordionGroups[accordionGroupName];
      if (!groupObject) {
        dispatch(createAndAddToAccordionGroup({
          name: name,
          accordionGroupName: accordionGroupName
        }));
      } else {
        if (!!initiallyOpened) {
          groupObject.map(function (name) {
            dispatch(closeAccordion({
              name: name
            }));
          });
        }
        dispatch(addToAccordionGroup({
          name: name,
          accordionGroupName: accordionGroupName
        }));
      }
      dispatch(setInitialAccordionStatus({
        name: name,
        initiallyOpened: initiallyOpened,
        accordionGroupName: accordionGroupName
      }));
    } else {
      dispatch(setInitialAccordionStatus({
        name: name,
        initiallyOpened: initiallyOpened
      }));
    }
  };
}
function handleAccordionStatus(_ref7) {
  var name = _ref7.name,
    accordionGroupName = _ref7.accordionGroupName,
    isOpen = _ref7.isOpen,
    position = _ref7.position;
  return function (dispatch, getState) {
    if (!!accordionGroupName) {
      var groupObject = getState().accordionReducer.accordionGroups[accordionGroupName];
      if (!isOpen && !!groupObject) {
        groupObject.map(function (name) {
          dispatch(closeAccordion({
            name: name
          }));
        });
      }
    }
    dispatch(changeAccordionStatus(name, isOpen, position));
  };
}
function isAccordionOpen(name) {
  return function (_dispatch, getState) {
    var accordionReducer = getState().accordionReducer;
    return accordionReducer[name] && accordionReducer[name].isOpen;
  };
}
var initialState = {
  accordionGroups: {}
};
function accordionReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case TOGGLE_ACCORDION_STATUS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.name, _objectSpread(_objectSpread({}, state[action.name]), {
        isOpen: !action.isOpen,
        position: action.position
      })));
    case SET_INITIAL_ACCORDION_STATUS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.name, {
        isOpen: action.initiallyOpened,
        accordionGroupName: action.accordionGroupName
      }));
    case ADD_TO_ACCORDION_GROUP:
      return _objectSpread(_objectSpread({}, state), {}, {
        accordionGroups: _objectSpread(_objectSpread({}, state.accordionGroups), _defineProperty({}, action.accordionGroupName, [].concat(_toConsumableArray(state.accordionGroups[action.accordionGroupName]), [action.name])))
      });
    case CREATE_AND_ADD_TO_ACCORDION_GROUP:
      return _objectSpread(_objectSpread({}, state), {}, {
        accordionGroups: _objectSpread(_objectSpread({}, state.accordionGroups), _defineProperty({}, action.accordionGroupName, [action.name]))
      });
    case CHANGE_ACCORDION_STATUS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.name, _objectSpread(_objectSpread({}, state[action.name]), {
        isOpen: action.isOpen,
        position: action.position
      })));
    default:
      return state;
  }
}