"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProfileMenuProfileWrapper = exports.ProfileMenuItems = exports.ProfileMenuItem = exports.ProfileMenuHeader = exports.ProfileMenuFeed = exports.ProfileMenu = exports.ProfileContentScroll = exports.ProfileContent = exports.MIN_WIDTH = exports.ICON_SIZE = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
var _styledComponentVariables = require("../style-common/styled-component-variables");
var _CloseAndBackButton = require("../ui/CloseAndBackButton.style");
var _AddToBasketComments = require("../comments/AddToBasketComments.style");
var _media = _interopRequireDefault(require("@stackend/react/style-common/media"));
var _CounterBadge = _interopRequireDefault(require("../ui/CounterBadge.style"));
var _ProfileMenuBasket = require("./ProfileMenuBasket.style");
var _ProfileMenuProduct = require("./ProfileMenuProduct.style");
var _styledComponents2 = require("../styled-components");
var _ToggleButton = require("../ui/ToggleButton.style");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var ICON_SIZE = '2em';
exports.ICON_SIZE = ICON_SIZE;
var MIN_WIDTH = '30em';
exports.MIN_WIDTH = MIN_WIDTH;
var ProfileContent = _styledComponents.default.div.attrs(function (props) {
  return {
    className: 'stackend-profile-menu-content'
  };
}).withConfig({
  displayName: "ProfileMenustyle__ProfileContent",
  componentId: "sc-3wbs4t-0"
})([".stackend &{background:", ";display:flex;flex-direction:column;width:", ";height:100vh;box-shadow:0 0 5px 0 ", ";z-index:", ";", "{width:100vw;height:100%;}", " ", ";}"], function (props) {
  return props.theme.backgroundColor;
}, MIN_WIDTH, function (props) {
  return props.theme.borderColor + '7F';
}, _styledComponentVariables.zIndexes.userMenu + 1, _media.default.tabletScreen, _ProfileMenuBasket.ProfileMenuBasket, _ProfileMenuProduct.ProfileMenuProduct);
exports.ProfileContent = ProfileContent;
var ProfileMenuHeader = _styledComponents.default.div.attrs(function (props) {
  return {
    className: 'stackend-profile-menu-feed-header'
  };
}).withConfig({
  displayName: "ProfileMenustyle__ProfileMenuHeader",
  componentId: "sc-3wbs4t-1"
})([".stackend &{display:flex;width:100%;place-content:end space-between;padding-right:10px;.stackend-menu-header-title{margin-top:1rem;margin-right:1rem;margin-left:1rem;font-weight:bold;}", "{margin-top:1rem;margin-right:1rem;margin-left:1rem;", "{font-size:2em;border-radius:0px;padding:10px;box-shadow:rgba(0,0,0,0.498) 0px 0px 5px 0px;}}}"], _CloseAndBackButton.CloseAndBackButton, _media.default.tabletScreen);
exports.ProfileMenuHeader = ProfileMenuHeader;
var ProfileContentScroll = _styledComponents.default.div.attrs(function (props) {
  return {
    className: 'stackend-profile-menu-content-scroll'
  };
}).withConfig({
  displayName: "ProfileMenustyle__ProfileContentScroll",
  componentId: "sc-3wbs4t-2"
})([".stackend &{overflow-y:auto;overflow-x:hidden;display:flex;flex-direction:column;width:100%;height:100%;padding:10px;", "{padding-bottom:calc(", " + ", ");}", "{flex:1 0 100%;}.stackend-basket{padding:0;overflow:initial;.stackend-basket-list-item.stackend-basket-list-item{padding-left:0;padding-right:0;margin:0;}}}"], _media.default.tabletScreen, ICON_SIZE, ICON_SIZE, _AddToBasketComments.AddToBasketComments);
exports.ProfileContentScroll = ProfileContentScroll;
var ProfileMenuItems = _styledComponents.default.ol.attrs(function (props) {
  return {
    className: 'stackend-profile-menu-items'
  };
}).withConfig({
  displayName: "ProfileMenustyle__ProfileMenuItems",
  componentId: "sc-3wbs4t-3"
})([".stackend &{display:flex;flex-direction:column;place-self:center;background-color:#fff;box-shadow:0 0 5px 0 #0000007f;padding:20px;border-top-left-radius:", ";border-bottom-left-radius:", ";gap:15px;}"], function (props) {
  return props.theme.shopifyBorderRadius;
}, function (props) {
  return props.theme.shopifyBorderRadius;
});
exports.ProfileMenuItems = ProfileMenuItems;
var ProfileMenu = _styledComponents.default.nav.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-profile-menu', props.className)
  };
}).withConfig({
  displayName: "ProfileMenustyle__ProfileMenu",
  componentId: "sc-3wbs4t-4"
})([".stackend &{position:fixed;top:50%;transform:translate(0,-50%);right:0;display:flex;z-index:", ";&.stackend-profile-menu-expanded{height:100%;top:0;transform:none;}@keyframes slide-menu{0%{transform:translateX(0%);}100%{transform:translateX( calc( -100% + ", " ) );}}&.stackend-profile-menu-expanded{right:0;}&.stackend-profile-menu-closing{}&.stackend-profile-menu-collapsed{", "{box-shadow:none;display:none;}}", "{&.stackend-profile-menu-expanded{width:100vw;right:unset;animation:none;justify-content:center;", "{position:absolute;z-index:", ";place-content:center;bottom:0;width:100%;flex-direction:row;border-radius:0px;margin-left:unset;gap:20%;}", "{margin:unset;z-index:", ";}}}}"], _styledComponentVariables.zIndexes.userMenu, function (props) {
  return props.theme.margins.small + ' + ' + ICON_SIZE + ' + ' + props.theme.margins.small;
}, ProfileContent, _media.default.tabletScreen, ProfileMenuItems, _styledComponentVariables.zIndexes.userMenu + 2, _CloseAndBackButton.CloseAndBackButton, _styledComponentVariables.zIndexes.userMenu + 2);
exports.ProfileMenu = ProfileMenu;
var ProfileMenuItem = _styledComponents.default.li.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-profile-menu-item', props.className)
  };
}).withConfig({
  displayName: "ProfileMenustyle__ProfileMenuItem",
  componentId: "sc-3wbs4t-5"
})([".stackend &{list-style:none;&.stackend-profile-menu-selected{> a{div{&.account{background-image:url(", ");}&.ai{background-image:url(", ");}&.shopping_bag{background-image:url(", ");}&.notifications_none{background-image:url(", ");}&.notifications_active{background-image:url(", ");}}}}> a{width:", ";height:", ";line-height:1em;display:flex;align-items:center;justify-content:center;text-decoration:none;color:", ";position:relative;div{background-repeat:no-repeat;background-size:cover;width:100%;height:100%;display:block;&.account{background-image:url(", ");}&.ai{background-image:url(", ");}&.shopping_bag{background-image:url(", ");}&.notifications_none{background-image:url(", ");}&.notifications_active{background-image:url(", ");}}", "{position:absolute;font-size:0.5em;font-weight:bold;top:-1em;right:-1em;}}}"], require('../ui/icons/img/account-outline-black.svg'), require('../ui/icons/img/ai-black-flag.svg'), require('../ui/icons/img/shopping_bag-black.svg'), require('../ui/icons/img/notifications-outline-black.svg'), require('../ui/icons/img/notifications-outline-black.svg'), ICON_SIZE, ICON_SIZE, function (props) {
  return props.theme.color;
}, require('../ui/icons/img/account-outline-white.svg'), require('../ui/icons/img/ai-white-flag.svg'), require('../ui/icons/img/shopping_bag.svg'), require('../ui/icons/img/notifications-outline-white.svg'), require('../ui/icons/img/notifications-outline-white.svg'), _CounterBadge.default);
exports.ProfileMenuItem = ProfileMenuItem;
var ProfileMenuFeed = _styledComponents.default.div.attrs(function (props) {
  return {
    className: 'stackend-profile-menu-feed'
  };
}).withConfig({
  displayName: "ProfileMenustyle__ProfileMenuFeed",
  componentId: "sc-3wbs4t-6"
})([".stackend &{margin-top:0.7em;.stackend-blog-editor{background:unset;border:0px;}", "{display:flex;flex-direction:column;grid-template-columns:unset;place-items:unset;}", "{display:none;}}"], _styledComponents2.FeedWrapper, _ToggleButton.ToggleButton);
exports.ProfileMenuFeed = ProfileMenuFeed;
var ProfileMenuProfileWrapper = _styledComponents.default.div.attrs(function (props) {
  return {
    className: 'stackend-profile-menu-profile-wrapper'
  };
}).withConfig({
  displayName: "ProfileMenustyle__ProfileMenuProfileWrapper",
  componentId: "sc-3wbs4t-7"
})([".stackend &{padding:20px;background:", ";border-radius:", ";}"], function (props) {
  return props.theme.shopifyMainColor;
}, function (props) {
  return props.theme.shopifyBorderRadius;
});
exports.ProfileMenuProfileWrapper = ProfileMenuProfileWrapper;