"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Categories = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var Categories = _styledComponents.default.div.withConfig({
  displayName: "Categoriesstyle__Categories",
  componentId: "sc-15hmobn-0"
})([".stackend &{position:relative;display:inline-block;vertical-align:top;width:100%;overflow:auto;-webkit-overflow-scrolling:touch;margin-top:10px;.stackend-categories-inner{position:relative;width:100%;max-width:1200px;a{padding:0;}}}"]);
exports.Categories = Categories;