"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.zIndex = exports.topMenuHeight = exports.radius = exports.media = exports.margins = exports.focusStyleMixin = exports.communityMenuWidth = exports.communityMenuInnerWidth = exports.colors = exports.buttons = void 0;
var _media = require("../media.js");
var _styledComponents = require("styled-components");
var _StackendTheme = require("@stackend/react/theme/StackendTheme");
var _ComponentType = _interopRequireDefault(require("@stackend/react/theme/ComponentType"));
var _ComponentState = _interopRequireDefault(require("@stackend/react/theme/ComponentState"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// COLORS. HEX colors plz
var colors = {
  background: '#ffffff',
  lighterBackground: '#ffffff',
  darkerBackground: '#7e7e7e',
  text: '#000000',
  link: '#525252',
  accent: '#04b7e6',
  positive: '#5d11e4',
  // '#60B44B',
  danger: '#ef5350',
  textInverse: '#ffffff',
  textDiscrete: '#7e7e7e',
  backgroundFocus: '#E7EFF6',
  borderFocus: '#ADCBE3',
  backgroundHover: '#EEF7E9',
  borderHover: '#5d11e4',
  // '#60B44B',
  hover: '#60B44B',
  menu: {
    background: '#000000',
    //text: '#ffffff',
    text: '#4efdfe'
  },
  bad: '#ef5e5e',
  badBackground: 'rgba(239, 126, 94, 0.1)',
  abuse: '#fba60b',
  good: '#2cc510',
  notification: '#f06060',
  dashed: '#bdbdbd',
  // Module colors used on IndexPage and search
  module: {
    abuse: '#fba60b',
    comment: '#77d7ff',
    question: '#ff788c',
    blog: '#edba5f',
    cms: '#a0a0a0',
    search: '#81db70',
    socialfeed: '#97a6ff',
    chat: '#ef5e5e',
    group: '#d3ca54',
    user: '#ff855a',
    forum: '#df7ae2'
  },
  input: {
    background: '#f5f5f5',
    border: '#e0e0e0',
    borderInverted: '#656565',
    backgroundInverted: '#656565',
    disabled: '#a1a1a1'
  },
  moderate: {
    notPassed: '#ff777d',
    passed: '#4ad266',
    notPassedHover: '#ff777d',
    passedHover: '#4ad266',
    originalCommentBackground: '#e4f7ff',
    originalCommentBorder: '#d2e9f3'
  }
};
exports.colors = colors;
var focusStyleMixin = (0, _styledComponents.css)(["margin:3px;border-radius:5px;&:focus{border-color:", ";box-shadow:0 0 2px 2px ", ";}"], function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BUTTON, 'borderColor', _ComponentState.default.FOCUS);
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BUTTON, 'borderColor', _ComponentState.default.FOCUS);
});
exports.focusStyleMixin = focusStyleMixin;
var buttons = {};

/**
 * OK / Create buttons. White on green
 */
exports.buttons = buttons;
buttons.positive = (0, _styledComponents.css)(["color:", ";background-color:", ";border-color:", ";&:hover{background-color:", ";color:", ";}"], colors.textInverse, colors.positive, colors.positive, colors.backgroundHover, colors.positive);

/**
 * Delete etc. White on red
 */
buttons.danger = (0, _styledComponents.css)(["color:", ";background-color:", ";border-color:", ";&:hover{background-color:", ";color:", ";}"], colors.textInverse, colors.danger, colors.danger, colors.textInverse, colors.danger);

/**
 * Default button style. Default text on none
 * @deprecated Now covered by StackendAdminTheme.js
 */
buttons.default = (0, _styledComponents.css)(["color:", ";background-color:unset;border:1px solid #4b4b4b;padding:0.25em 0.5em;vertical-align:middle;line-height:normal;cursor:pointer;display:inline-flex;flex-direction:row;flex-wrap:nowrap;align-items:center;justify-content:center;gap:0.25em;&:hover,&:active{background-color:", ";border-color:", ";}", ";&.positive-action,&[type='submit']{", ";}&.danger-action{", ";}&:disabled,&:hover:disabled{background-color:", ";border-color:", ";color:", ";cursor:not-allowed;}"], function (props) {
  return props.theme.color;
}, colors.backgroundHover, colors.borderHover, focusStyleMixin, buttons.positive, buttons.danger, colors.darkerBackground, colors.darkerBackground, colors.backgroundFocus);

// MARGINS
var margins = {
  small: '10px',
  medium: '20px',
  large: '40px'
};

// Media selectors
exports.margins = margins;
var media = _media.media;

// Border radius
exports.media = media;
var radius = {
  normal: '3px'
};

// Z-indexes
exports.radius = radius;
var zIndex = {
  communityMenu: 50,
  topMenu: 100,
  communityDropdown: 200,
  userMenu: 300,
  communityMenuMobile: 800,
  /**
   * Non modal dialogs
   */
  dialog: 1000,
  /**
   * Overlay used to gray out page when modal dialogs are used
   */
  modalDialogOverlay: 2000,
  /**
   * Modal dialogs
   */
  modalDialog: 2100,
  /**
   * Generic z-index for items like local popup menus
   */
  onTop: 5000
};

/**
 * Height of the top menu
 */
exports.zIndex = zIndex;
var topMenuHeight = '60px';

/**
 * Inner width of community menu
 */
exports.topMenuHeight = topMenuHeight;
var communityMenuInnerWidth = '310px';

/**
 * Total width of the community menu
 */
exports.communityMenuInnerWidth = communityMenuInnerWidth;
var communityMenuWidth = '310px'; // communityMenuInnerWidth + 45 margin left
exports.communityMenuWidth = communityMenuWidth;