"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getTitle;
function getTitle(_ref) {
  var theme = _ref.theme,
    communityName = _ref.communityName,
    section = _ref.section,
    subSection = _ref.subSection,
    action = _ref.action;
  if (!!theme && !communityName && !section && !subSection && !action) {
    return '';
  }
  var title = !!action ? "".concat(action, " - ") : '';
  title += !!subSection ? "".concat(subSection, " - ") : '';
  title += !!section ? "".concat(section, " - ") : '';
  title += !!communityName ? "".concat(communityName, " - ") : '';
  return title;
}