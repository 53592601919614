"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TOGGLE_REPLY_BOX = exports.TOGGLE_EDIT_COMMENT = exports.TOGGLE_COMMENT_SECTION = exports.OPEN_REPLY_BOX = exports.OPEN_COMMENT_SECTION = exports.CLOSE_REPLY_BOX = exports.CLOSE_COMMENT_SECTION = void 0;
exports.openCommentSection = openCommentSection;
exports.openEditComment = openEditComment;
exports.openReplyBoxes = openReplyBoxes;
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
var TOGGLE_REPLY_BOX = 'TOGGLE_REPLY_BOX';
exports.TOGGLE_REPLY_BOX = TOGGLE_REPLY_BOX;
var OPEN_REPLY_BOX = 'OPEN_REPLY_BOX';
exports.OPEN_REPLY_BOX = OPEN_REPLY_BOX;
var CLOSE_REPLY_BOX = 'CLOSE_REPLY_BOX';
exports.CLOSE_REPLY_BOX = CLOSE_REPLY_BOX;
var OPEN_COMMENT_SECTION = 'OPEN_COMMENT_SECTION';
exports.OPEN_COMMENT_SECTION = OPEN_COMMENT_SECTION;
var CLOSE_COMMENT_SECTION = 'CLOSE_COMMENT_SECTION';
exports.CLOSE_COMMENT_SECTION = CLOSE_COMMENT_SECTION;
var TOGGLE_COMMENT_SECTION = 'TOGGLE_COMMENT_SECTION';
exports.TOGGLE_COMMENT_SECTION = TOGGLE_COMMENT_SECTION;
var TOGGLE_EDIT_COMMENT = 'TOGGLE_EDIT_COMMENT';
exports.TOGGLE_EDIT_COMMENT = TOGGLE_EDIT_COMMENT;
function openReplyBoxes() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case OPEN_REPLY_BOX:
      return state.concat(action.parentId);
    case CLOSE_REPLY_BOX:
      {
        var i = state.indexOf(action.parentId);
        if (i === -1) {
          return state;
        }
        state.splice(i, 1);
        return _extends([], state);
      }
    case TOGGLE_REPLY_BOX:
      {
        //if reply box is closed
        var _i = state.indexOf(action.parentId);
        if (_i === -1) {
          //open replybox
          return state.concat(action.parentId);
        } else {
          //close replybox
          state.splice(_i, 1);
          return _extends([], state);
        }
      }
    default:
      return state;
  }
}
function openEditComment() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case TOGGLE_EDIT_COMMENT:
      {
        //if Edit Comment is closed
        var i = state.indexOf(action.id);
        if (i === -1) {
          //open Edit Comment
          return state.concat(action.id);
        } else {
          //close Edit Comment
          state.splice(i, 1);
          return _extends([], state);
        }
      }
    default:
      return state;
  }
}
function openCommentSection() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case OPEN_COMMENT_SECTION:
      return true;
    case CLOSE_COMMENT_SECTION:
      return false;
    case TOGGLE_COMMENT_SECTION:
      return !state;
    default:
      return state;
  }
}