"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VotesAndScore = exports.RatingInfo = exports.RatingByScore = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var RatingByScore = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend', 'stackend-rating-by-score', props.className)
  };
}).withConfig({
  displayName: "RatingByScorestyle__RatingByScore",
  componentId: "sc-193t0q4-0"
})([""]);
exports.RatingByScore = RatingByScore;
var RatingInfo = _styledComponents.default.span.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-rating-info', props.className)
  };
}).withConfig({
  displayName: "RatingByScorestyle__RatingInfo",
  componentId: "sc-193t0q4-1"
})([""]);
exports.RatingInfo = RatingInfo;
var VotesAndScore = _styledComponents.default.div.attrs(function (props) {
  return {
    className: 'stackend-votes-and-score'
  };
}).withConfig({
  displayName: "RatingByScorestyle__VotesAndScore",
  componentId: "sc-193t0q4-2"
})([".stackend &{margin-top:1em;display:grid;grid-template-columns:auto 1fr;gap:0.5em 1em;align-items:center;}"]);
exports.VotesAndScore = VotesAndScore;