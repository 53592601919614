"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.removeTags = void 0;
var removeTags = function removeTags(props) {
  var text = props.text;
  if (text === null || text === '') {
    return '';
  }

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with an empty string.
  return text.replace(/(<([^>]+)>)/gi, '');
};
exports.removeTags = removeTags;