"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AdminModalContent = void 0;
var _StackendBaseStyle = require("../styled-components/Stackend/StackendBaseStyle.js");
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var AdminModalContent = (0, _styledComponents.default)(_StackendBaseStyle.StackendBaseStyle).withConfig({
  displayName: "AdminModalstyle__AdminModalContent",
  componentId: "sc-dzwnop-0"
})([".stackend &{background:white;overflow:auto;-webkit-overflow-scrolling:touch;height:100%;}"]);
exports.AdminModalContent = AdminModalContent;