"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = randomHtmlId;
/**
 * Generate a valid random html id
 * @returns {string}
 */
function randomHtmlId() {
  return ('i-' + Math.random()).replace(/[-\\.]/g, '');
}