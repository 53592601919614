"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.StatusBarButton = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var StatusBarButton = _styledComponents.default.button.attrs({
  className: 'stackend-status-bar-button stackend-icon'
}).withConfig({
  displayName: "StatusBarButton",
  componentId: "sc-8hyr35-0"
})([".stackend &{", ";}"], function (props) {
  return !!props.theme.StatusBarButton && props.theme.StatusBarButton;
});
exports.StatusBarButton = StatusBarButton;
var _default = StatusBarButton;
exports.default = _default;