"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LOGIN_MODAL_NAME = exports.LOGIN_MENU_ACCORDION = void 0;
exports.closeLoginMenu = closeLoginMenu;
exports.closeLoginModal = closeLoginModal;
exports.isLoginMenuOpen = isLoginMenuOpen;
exports.openLoginMenu = openLoginMenu;
exports.openLoginModal = openLoginModal;
var _modalActions = require("../xcap/modalActions");
var _api = require("@stackend/api/api");
var _accordionReducer = require("../Accordion/accordionReducer.jsx");
var LOGIN_MODAL_NAME = 'loginModal';
exports.LOGIN_MODAL_NAME = LOGIN_MODAL_NAME;
function openLoginModal() {
  return function (dispatch, getState) {
    var _getState = getState(),
      communities = _getState.communities;
    if (!communities.community || communities.community.settings.loginEnabled === false) {
      console.debug('Stackend: login disabled');
      return;
    }
    dispatch((0, _modalActions.openModal)({
      modalName: LOGIN_MODAL_NAME
    }));
  };
}
function closeLoginModal() {
  return function (dispatch) {
    dispatch((0, _modalActions.closeModal)({
      modalName: LOGIN_MODAL_NAME
    }));
  };
}

/**
 * Login menu accordion name
 * @type {string}
 */
var LOGIN_MENU_ACCORDION = 'stackend-loginmenu';
exports.LOGIN_MENU_ACCORDION = LOGIN_MENU_ACCORDION;
function openLoginMenu() {
  return function (dispatch) {
    dispatch((0, _accordionReducer.openAccordion)({
      name: LOGIN_MENU_ACCORDION
    }));
  };
}
function closeLoginMenu() {
  return function (dispatch) {
    dispatch((0, _accordionReducer.closeAccordion)({
      name: LOGIN_MENU_ACCORDION
    }));
  };
}
function isLoginMenuOpen() {
  return (0, _accordionReducer.isAccordionOpen)(LOGIN_MENU_ACCORDION);
}