"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EventTitle = exports.EventRow = exports.EventLocation = exports.EventLink = exports.EventDateRange = exports.Event = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _EventDate = require("./EventDate.style");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var Event = _styledComponents.default.div.attrs({
  className: 'stackend-event'
}).withConfig({
  displayName: "EventOutputstyle__Event",
  componentId: "sc-64e86u-0"
})([".stackend &{margin:1em 0;display:flex;flex-direction:column;gap:0.25em;", "{margin:0.5em 0;}}"], _EventDate.EventDate);
exports.Event = Event;
var EventTitle = _styledComponents.default.h3.attrs({
  className: 'stackend-event-title'
}).withConfig({
  displayName: "EventOutputstyle__EventTitle",
  componentId: "sc-64e86u-1"
})([".stackend &{font-size:1.5em;font-weight:bold;}"]);
exports.EventTitle = EventTitle;
var EventRow = _styledComponents.default.div.attrs({
  className: 'stackend-event-row'
}).withConfig({
  displayName: "EventOutputstyle__EventRow",
  componentId: "sc-64e86u-2"
})([".stackend &{display:flex;flex-direction:row;gap:1em;}"]);
exports.EventRow = EventRow;
var EventDateRange = _styledComponents.default.div.attrs({
  className: 'stackend-event-date-range'
}).withConfig({
  displayName: "EventOutputstyle__EventDateRange",
  componentId: "sc-64e86u-3"
})([""]);
exports.EventDateRange = EventDateRange;
var EventLocation = _styledComponents.default.div.attrs({
  className: 'stackend-event-location'
}).withConfig({
  displayName: "EventOutputstyle__EventLocation",
  componentId: "sc-64e86u-4"
})([""]);
exports.EventLocation = EventLocation;
var EventLink = _styledComponents.default.div.attrs({
  className: 'stackend-event-link'
}).withConfig({
  displayName: "EventOutputstyle__EventLink",
  componentId: "sc-64e86u-5"
})([""]);
exports.EventLink = EventLink;