"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ViewForumThread = exports.ForumEntry = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var sc = _interopRequireWildcard(require("../../style-common/styled-component-variables"));
var _StackendTheme = require("@stackend/react/theme/StackendTheme");
var _ComponentType = _interopRequireDefault(require("@stackend/react/theme/ComponentType"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var ViewForumThread = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-forum-grid-wrapper', props.className)
  };
}).withConfig({
  displayName: "ViewForumThread",
  componentId: "sc-14wsns-0"
})([".stackend &{width:100%;column-count:3;gap:10px;}"]);
exports.ViewForumThread = ViewForumThread;
var ForumEntry = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-forum-entry', props.className)
  };
}).withConfig({
  displayName: "ViewForumThread__ForumEntry",
  componentId: "sc-14wsns-1"
})([".stackend &{display:grid;grid-template-rows:1fr auto;break-inside:avoid;grid-row-gap:1em;margin-bottom:", ";background-color:", ";padding:", " ", ";}"], function (props) {
  return props.theme.margins.medium;
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'backgroundColor');
}, function (props) {
  return props.theme.margins.small;
}, function (props) {
  return props.theme.margins.medium;
});
exports.ForumEntry = ForumEntry;