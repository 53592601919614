"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ProfileMenuIntro = exports.FeedWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var FeedWrapper = _styledComponents.default.div.attrs({
  className: 'stackend-feed-wrapper'
}).withConfig({
  displayName: "FeedWrapper",
  componentId: "sc-1kcm4gy-0"
})([".stackend &{display:grid;grid-template-columns:repeat(2,1fr);grid-gap:1em;> div:first-of-type{grid-column:1 / span 2;}> div{overflow:hidden;}.stackend-section-title{text-align:center;display:block;margin:40px auto 0;}", ";}"], function (props) {
  return !!props.theme.FeedWrapper && props.theme.FeedWrapper(props);
});
exports.FeedWrapper = FeedWrapper;
var ProfileMenuIntro = _styledComponents.default.div.attrs(function (props) {
  return {
    className: 'stackend-feed-wrapper-profile-intro'
  };
}).withConfig({
  displayName: "FeedWrapper__ProfileMenuIntro",
  componentId: "sc-1kcm4gy-1"
})([".stackend &{padding:20px;margin-top:20px;background:", ";border-radius:", ";}"], function (props) {
  return props.theme.shopifyMainColor;
}, function (props) {
  return props.theme.shopifyBorderRadius;
});
exports.ProfileMenuIntro = ProfileMenuIntro;
var _default = FeedWrapper;
exports.default = _default;