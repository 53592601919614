"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.CommentReply = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
var _User = require("../../user/User.style");
var _AuthorImage = _interopRequireDefault(require("../User/AuthorImage"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var CommentReply = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-comment-reply', props.className)
  };
}).withConfig({
  displayName: "CommentReply",
  componentId: "sc-1ns0o29-0"
})([".stackend &{display:flex;flex-direction:row;gap:0.5em;padding-top:0.5em;margin-left:1em;", "{margin:0;flex:0 0 auto;}", "{aspect-ratio:1;}", ";}"], _AuthorImage.default, _User.ProfileWrapper, function (props) {
  return !!props.theme.CommentReply && props.theme.CommentReply(props);
});
exports.CommentReply = CommentReply;
var _default = CommentReply;
exports.default = _default;