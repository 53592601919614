"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = loadCss;
/**
 * Dynamically load a stylesheet
 * @param file
 */
function loadCss(file) {
  var l = document.createElement('link');
  l.setAttribute('rel', 'stylesheet');
  l.setAttribute('type', 'text/css');
  l.setAttribute('href', file);
  var head = document.getElementsByTagName('head')[0];
  head.appendChild(l);
}