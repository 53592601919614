"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LiveEventListing = exports.LiveEvent = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _reactRouterDom = require("react-router-dom");
var _media = require("../../../styled-components/media");
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var LiveEvent = (0, _styledComponents.default)(_reactRouterDom.Link).attrs(function (props) {
  return {
    className: 'stackend-live-event-link'
  };
}).withConfig({
  displayName: "LiveEventListingstyle__LiveEvent",
  componentId: "sc-kf8p9b-0"
})([".stackend &{cursor:pointer;display:grid;grid-template-columns:auto 1fr min-content;grid-template-rows:min-content 1fr;grid-template-areas:'thumbnail title date' 'thumbnail video-title video-title';align-items:start;justify-content:flex-start;align-content:space-evenly;column-gap:1em;row-gap:0.5em;position:relative;", "{grid-template-columns:auto 1fr;grid-template-areas:'thumbnail title' 'thumbnail date';}.stackend-thumbnail{grid-area:thumbnail;align-self:start;aspect-ratio:16/9;height:90px;background:black;display:flex;align-items:flex-start;justify-content:center;img{height:90px;}}.stackend-title{grid-area:title;font-weight:bold;display:flex;align-items:center;gap:0.5em;white-space:nowrap;}.stackend-video-title{grid-area:video-title;align-self:start;font-size:0.75em;line-clamp:2;-webkit-line-clamp:2;-webkit-box-orient:vertical;max-height:3em;white-space:break-spaces;}.stackend-title,.stackend-video-title{overflow:hidden;text-overflow:ellipsis;}.stackend-date{white-space:nowrap;grid-area:date;}.stackend-title,.stackend-date{align-self:start;}", "{.stackend-video-title{display:none;}}}"], _media.media.mobileScreen, _media.media.mobileScreen);
exports.LiveEvent = LiveEvent;
var LiveEventListing = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-live-event-listing', props.className)
  };
}).withConfig({
  displayName: "LiveEventListingstyle__LiveEventListing",
  componentId: "sc-kf8p9b-1"
})([""]);
exports.LiveEventListing = LiveEventListing;