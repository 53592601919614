"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCommentContext = getCommentContext;
exports.getCommentMediaContext = getCommentMediaContext;
var _comments = require("@stackend/api/comments");
// FIXME: hardcoded crap. Move to @stackend/api
/**
 * Get the comment contex for a given comment module
 * @param commentModule
 * @returns {string}
 */
function getCommentContext(commentModule) {
  switch (commentModule) {
    case _comments.CommentModule.BLOG:
      return 'newscomment';
    case _comments.CommentModule.FORUM:
      return 'forumcomment';
    case _comments.CommentModule.PERSONAL_SHOPPER:
      return 'personalshoppercomment';
    default:
      return 'comments';
  }
}

/**
 * Get the media context for a given comment module
 * @param commentModule
 * @returns {string}
 */
function getCommentMediaContext(commentModule) {
  return commentModule === _comments.CommentModule.BLOG ? 'newscomment' : 'comments';
}