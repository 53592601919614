"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getStackendProductBlotValue = getStackendProductBlotValue;
var _shop = require("@stackend/api/shop");
var _ProductModule = require("@stackend/react/cms/page-content/ProductModule");
function getStackendProductBlotValue(communityId, product, layout) {
  var image = (0, _shop.getFirstImage)(product);
  return {
    communityId: communityId,
    handle: product.handle,
    title: product.title,
    image: image ? image.url || image.transformedSrc : null,
    layout: layout ? layout.toLowerCase() : _ProductModule.ProductLayoutOptions.COMPACT
  };
}