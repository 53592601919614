"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CommunityFeedStyle = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Comment = require("../comments/comment/Comment.style");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var CommunityFeedStyle = _styledComponents.default.div.attrs(function () {
  return {
    className: 'stackend-community-feed'
  };
}).withConfig({
  displayName: "CommunityFeedstyle__CommunityFeedStyle",
  componentId: "sc-1ubmdsn-0"
})([".stackend &{", "{display:none;}}"], _Comment.CommentActions);
exports.CommunityFeedStyle = CommunityFeedStyle;