"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OPEN_MODAL = exports.CLOSE_MODAL = void 0;
exports.default = openModals;
var OPEN_MODAL = 'OPEN_MODAL';
exports.OPEN_MODAL = OPEN_MODAL;
var CLOSE_MODAL = 'CLOSE_MODAL';
exports.CLOSE_MODAL = CLOSE_MODAL;
function openModals() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case OPEN_MODAL:
      return state = {
        modalName: action.modalName,
        modalProps: action.modalProps
      };
    case CLOSE_MODAL:
      return state = {};
    default:
      return state;
  }
}