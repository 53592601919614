"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SetBodyClassReducer = exports.SET_BODY_CLASS = void 0;
var _immutabilityHelper = _interopRequireDefault(require("immutability-helper"));
var _api = require("@stackend/api/api");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
////Action Type
var SET_BODY_CLASS = 'SET_BODY_CLASS';

//Reducer
exports.SET_BODY_CLASS = SET_BODY_CLASS;
var SetBodyClassReducer = function SetBodyClassReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    community: '',
    param: ''
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SET_BODY_CLASS:
      if ((0, _api.isRunningInBrowser)()) {
        var html = document.getElementsByTagName('html')[0];
        var body = document.getElementsByTagName('body')[0];

        //let $html = $('html');
        //let $body = $('body');
        if (!!action.name.community) {
          html.classList.remove(state.community);
          body.classList.remove(state.community);
          //$html.removeClass(state.community);
          //$body.removeClass(state.community);
          if (typeof action.name !== 'undefined') {
            html.classList.remove('community-name-Stackend');
            body.classList.remove('community-name-Stackend');
            //$html.removeClass('community-name-Stackend');
            //$body.removeClass('community-name-Stackend');
            html.classList.add(action.name.community);
            body.classList.add(action.name.community);
            //$html.addClass(action.name.community);
            //$body.addClass(action.name.community);
          }
        } else {
          html.classList.remove(state.param);
          body.classList.remove(state.param);
          //$html.removeClass(state.param);
          //$body.removeClass(state.param);
          if (typeof action.name !== 'undefined') {
            //$html.addClass(action.name.param);
            //$body.addClass(action.name.param);
            html.classList.add(action.name.param);
            body.classList.add(action.name.param);
          }
        }
      }
      if (typeof action.name === 'undefined') {
        return state;
      }
      if (typeof action.name === 'string') {
        return (0, _immutabilityHelper.default)(state, {
          param: {
            $set: action.name
          }
        });
      }
      return (0, _immutabilityHelper.default)(state, {
        $merge: action.name
      });
    default:
      return state;
  }
};
exports.SetBodyClassReducer = SetBodyClassReducer;