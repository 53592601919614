"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.HeaderMenuLoggedOut = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var sc = _interopRequireWildcard(require("../../style-common/styled-component-variables.js"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var HeaderMenuLoggedOut = _styledComponents.default.ul.withConfig({
  displayName: "HeaderMenuLoggedOut",
  componentId: "sc-w3av3d-0"
})([".stackend &{display:flex;align-items:center;justify-content:flex-end;width:200px;right:0;position:relative;", "{justify-content:center;}li{display:inline-block;vertical-align:top;margin:0 5px;padding:20px 5px;cursor:pointer;", "{padding:17px 5px 18px;&.search{padding-left:0;}}&:last-child{padding-right:0;margin-right:0;}#search-icon{fill:#464646;width:18px;height:18px;}a{color:#ffffff;}.icon{position:relative;}}", ";}"], sc.Mobile, sc.Mobile, function (props) {
  return !!props.theme.HeaderMenuLoggedOut && props.theme.HeaderMenuLoggedOut(props);
});
exports.HeaderMenuLoggedOut = HeaderMenuLoggedOut;
var _default = HeaderMenuLoggedOut;
exports.default = _default;