"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TypeListing = exports.SingleLiveEvent = exports.NoLiveEvents = exports.LiveEventListingModule = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var sc = _interopRequireWildcard(require("../../../style-common/styled-component-variables"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
var _FullScreen = require("../../../ui/FullScreen.style");
var _StackendTheme = require("@stackend/react/theme/StackendTheme");
var _ComponentType = _interopRequireDefault(require("@stackend/react/theme/ComponentType"));
var _media = require("../../../styled-components/media");
var _LiveEventListing = require("./LiveEventListing.style");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var NoLiveEvents = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-live-event-none', props.className)
  };
}).withConfig({
  displayName: "LiveEventListingModulestyle__NoLiveEvents",
  componentId: "sc-1s0hwto-0"
})([".stackend &{text-align:center;padding:", ";border-radius:", ";background:", ";box-shadow:0 0 5px 0 ", ";}"], function (props) {
  return props.theme.margins.large;
}, function (props) {
  return props.theme.borderRadius;
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'backgroundColor');
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'borderColor') + '7F';
});
exports.NoLiveEvents = NoLiveEvents;
var SingleLiveEvent = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-live-event-single', props.className)
  };
}).withConfig({
  displayName: "LiveEventListingModulestyle__SingleLiveEvent",
  componentId: "sc-1s0hwto-1"
})([".stackend &{", "{&.stackend-live-event-active{}}}"], _LiveEventListing.LiveEvent);
exports.SingleLiveEvent = SingleLiveEvent;
var TypeListing = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-live-event-type-listing', props.className)
  };
}).withConfig({
  displayName: "LiveEventListingModulestyle__TypeListing",
  componentId: "sc-1s0hwto-2"
})([""]);
exports.TypeListing = TypeListing;
var LiveEventListingModule = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-live-event-listing-module', props.className)
  };
}).withConfig({
  displayName: "LiveEventListingModulestyle__LiveEventListingModule",
  componentId: "sc-1s0hwto-3"
})([".stackend &{.stackend-latest-active-live-event.stackend-responsive-list{grid-template-columns:1fr;.stackend-thumbnail{height:180px;img{height:180px;}}}", "{border-radius:", ";background:", ";padding:", ";box-shadow:0 0 5px 0 ", ";text-decoration:none;.stackend-thumbnail{height:180px;img{height:180px;aspect-ratio:16/9;}}", "{grid-template-columns:1fr;grid-template-rows:auto auto 3em auto;grid-template-areas:'date' 'title' 'video-title' 'thumbnail';}}.stackend-live-event-slideshow{", "{grid-template-columns:1fr;grid-template-rows:auto auto 3em auto;grid-template-areas:'date' 'title' 'video-title' 'thumbnail';gap:", ";justify-items:stretch;margin:", ";width:calc(", ");.stackend-video-title{text-align:left;}}.slick-list{height:fit-content !important;}.slick-track{display:flex;flex-direction:row;flex-wrap:nowrap;}.slick-arrow{position:absolute;font-size:4rem;width:1.5em;top:0;height:100%;border:none;color:", ";text-shadow:0 0 5px ", ";z-index:1;outline:none;padding:0;margin:0;transform:none;display:flex;align-items:center;&:before{content:unset;}&:focus{outline:none;box-shadow:none;}&.slick-next{right:0;background:linear-gradient( 90deg,", " 0%,", " 67%,", " );}&.slick-prev{left:0;background:linear-gradient( 90deg,", " 0%,", " 33%,", " );}}&.stackend-live-event-slideshow-small{", "{margin:0 auto;.stackend-thumbnail,.stackend-thumbnail img{width:100%;height:auto;}", "{.stackend-video-title{display:block;}}}}}", "{.stackend-close{position:absolute;top:1em;right:1em;border-radius:100%;width:2em;height:2em;background:white;color:black;box-shadow:0 0 5px 0 black;z-index:", ";display:flex;align-items:center;}}", "{background:black;}}"], _LiveEventListing.LiveEvent, function (props) {
  return props.theme.borderRadius;
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'backgroundColor');
}, function (props) {
  return props.theme.margins.medium;
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'borderColor') + '7F';
}, _media.media.mobilePortrait, _LiveEventListing.LiveEvent, function (props) {
  return props.theme.margins.medium;
}, function (props) {
  return props.theme.margins.small;
}, function (props) {
  return '2 * ' + props.theme.margins.medium + ' + 320px';
}, function (props) {
  return props.theme.backgroundColor;
}, function (props) {
  return props.theme.color;
}, function (props) {
  return props.theme.backgroundColor + '00';
}, function (props) {
  return props.theme.backgroundColor + '00';
}, function (props) {
  return props.theme.backgroundColor + 'FF';
}, function (props) {
  return props.theme.backgroundColor + 'FF';
}, function (props) {
  return props.theme.backgroundColor + '00';
}, function (props) {
  return props.theme.backgroundColor + '00';
}, _LiveEventListing.LiveEvent, _media.media.mobileScreen, _FullScreen.FullScreen, sc.zIndexes.userMenu - 5, _FullScreen.FullScreenContent);
exports.LiveEventListingModule = LiveEventListingModule;