"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ShopModal = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
var _ShippingOptionsForm = require("@stackend/react/shop/checkout/ShippingOptionsForm.style");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var ShopModal = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-shop-modal', props.className)
  };
}).withConfig({
  displayName: "ShopModalstyle__ShopModal",
  componentId: "sc-1036gtl-0"
})([".stackend &{img{width:100%;}select{width:auto;}", "{input[type='radio']{width:auto;margin-right:1em;}label,input{cursor:pointer;}}}"], _ShippingOptionsForm.ShippingOption);
exports.ShopModal = ShopModal;
var _default = ShopModal;
exports.default = _default;