"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ModuleWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var ModuleWrapper = _styledComponents.default.div.attrs({
  className: 'stackend-module-wrapper'
}).withConfig({
  displayName: "ModuleWrapper",
  componentId: "sc-127fw8y-0"
})([".stackend &{", ";}"], function (props) {
  return !!props.theme.ModuleWrapper && props.theme.ModuleWrapper(props);
});
exports.ModuleWrapper = ModuleWrapper;
var _default = ModuleWrapper;
exports.default = _default;