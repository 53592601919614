"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TagListingContainer = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var TagListingContainer = _styledComponents.default.ul.attrs({
  className: 'stackend-tag-listing'
}).withConfig({
  displayName: "TagListingstyle__TagListingContainer",
  componentId: "sc-7plwb-0"
})([".stackend &{list-style-type:none;li{float:left;margin-right:0.5em;}}"]);
exports.TagListingContainer = TagListingContainer;