"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = IdComparator;
var _Order = _interopRequireDefault(require("@stackend/api/api/Order"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * Order comments by id
 * @param a
 * @param b
 * @returns {number}
 * @constructor
 */
function IdComparator(a, b, order) {
  return b.id - a.id;
}