"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ProductPlaceHolder = void 0;
var _styledComponents = require("styled-components");
var sc = _interopRequireWildcard(require("../../style-common/styled-component-variables.js"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var ProductPlaceHolder = function ProductPlaceHolder() {
  return (0, _styledComponents.css)([".stackend-product-placeholder{border:", ";border-radius:5px;width:100%;margin:1em 0;overflow:hidden;display:flex;flex-direction:row;align-items:center;padding:0.5em;img{height:6em;width:auto;}.stackend-product-placeholder-title{margin-left:1em;}&.stackend-product-placeholder-horizontal{display:grid;grid-gap:1em;grid-template-columns:max-content auto;align-items:center;img{width:4em;height:auto;}}&.stackend-product-placeholder-full{width:100%;flex-direction:row;img{height:20em;width:auto;}.stackend-product-placeholder-title{flex:1 1 auto;}}}"], sc.border);
};
exports.ProductPlaceHolder = ProductPlaceHolder;
var _default = ProductPlaceHolder;
exports.default = _default;