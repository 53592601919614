"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserLink = exports.UserData = exports.ProfileWrapper = exports.ExternalUserLink = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _reactRouterDom = require("react-router-dom");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var superUserProfile = function superUserProfile(_ref) {
  var isAdmin = _ref.isAdmin;
  return isAdmin && (0, _styledComponents.css)([""]);
};

/* FIXME: Removed
		&:after {
			content: '';
			position: absolute;
			z-index: 1;
			bottom: -2px;
			left: 0;
			right: 0;
			padding-bottom: 29%;
			height: 0;
		}
	`;
*/

var adminProfile = function adminProfile(_ref2) {
  var isSuperUser = _ref2.isSuperUser,
    comment = _ref2.comment,
    tiny = _ref2.tiny,
    medium = _ref2.medium,
    large = _ref2.large;
  return isSuperUser && (0, _styledComponents.css)([""]);
};

/* FIXME: was
    css``
		&:after {
			content: '';
			position: absolute;
			z-index: 1;
			top: ${tiny ? '-5px' : comment ? '-5px' : large ? '5px' : '-5px'};
			right: ${large ? '5px' : '-5px'};
			width: ${tiny ? '15px' : medium ? '25px' : large ? '40px' : '20px'};
			height: ${tiny ? '15px' : medium ? '25px' : large ? '40px' : '20px'};
		}
	`;
*/

var ProfileWrapper = _styledComponents.default.div.withConfig({
  displayName: "Userstyle__ProfileWrapper",
  componentId: "sc-wk6bi6-0"
})([".stackend &{width:100%;height:100%;position:relative;", ";", ";}"], adminProfile, superUserProfile);
exports.ProfileWrapper = ProfileWrapper;
var singleLine = function singleLine(_ref3) {
  var isSingleLine = _ref3.isSingleLine;
  return isSingleLine && (0, _styledComponents.css)(["display:inline-block;vertical-align:middle;margin-left:5px;"]);
};
var UserData = _styledComponents.default.div.withConfig({
  displayName: "Userstyle__UserData",
  componentId: "sc-wk6bi6-1"
})([".stackend &{text-align:center;", ";}"], singleLine);
exports.UserData = UserData;
var UserLinkStyle = (0, _styledComponents.css)(["display:flex;width:100%;span{width:100%;display:inline-block;overflow:hidden;}", "{height:auto;}"], ProfileWrapper);
var UserLink = (0, _styledComponents.default)(_reactRouterDom.Link).withConfig({
  displayName: "Userstyle__UserLink",
  componentId: "sc-wk6bi6-2"
})([".stackend &{", ";}"], UserLinkStyle);
exports.UserLink = UserLink;
var ExternalUserLink = _styledComponents.default.a.withConfig({
  displayName: "Userstyle__ExternalUserLink",
  componentId: "sc-wk6bi6-3"
})([".stackend &{", ";}"], UserLinkStyle);
exports.ExternalUserLink = ExternalUserLink;