"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TabMenu = exports.Label = exports.Item = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
var admin = _interopRequireWildcard(require("../styled-components/Stackend/admin-vars.js"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var Label = _styledComponents.default.span.attrs(function (props) {
  return {
    className: 'stackend-tab-menu-label'
  };
}).withConfig({
  displayName: "TabMenustyle__Label",
  componentId: "sc-15ztk9k-0"
})([""]);
exports.Label = Label;
var Item = _styledComponents.default.li.attrs(function (props) {
  return {
    className: 'stackend-tab-menu-item'
  };
}).withConfig({
  displayName: "TabMenustyle__Item",
  componentId: "sc-15ztk9k-1"
})([""]);
exports.Item = Item;
var TabMenu = _styledComponents.default.nav.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-tab-menu', props.className)
  };
}).withConfig({
  displayName: "TabMenustyle__TabMenu",
  componentId: "sc-15ztk9k-2"
})([".stackend &{margin-bottom:1em;line-height:2em;> ul{margin:", ";", "{display:inline-block;vertical-align:middle;margin-right:1em;", "{margin-right:0.5em;}:last-child{margin-right:0;}a{display:inline-flex;align-items:center;padding:0.25em;user-select:none;border-radius:0;border-bottom:2px solid transparent;.material-icons{margin-right:0.5em;}&.active{border-bottom:2px solid ", ";}&:focus{background-color:", ";}}}}}"], function (props) {
  return props.theme.borderWidth;
}, Item, admin.media.mobileScreen, admin.colors.text, admin.colors.backgroundFocus);
exports.TabMenu = TabMenu;