"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TOOLBAR_FULL = exports.TOOLBAR_DEFAULT = exports.PLUGINS_FULL = exports.PLUGINS_DEFAULT = exports.DEFAULT_VALID_ELEMENTS = exports.DEFAULT_INVALID_ELEMENTS = void 0;
exports.getConfiguration = getConfiguration;
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
/**
 * Don't change this file. Instead pass in overrides to getConfiguration()
 */
/**
 * Default toolbar with basic stuff
 * @type {string}
 */
var TOOLBAR_DEFAULT = 'xcapmedia_upload xcapmedia_browse xcapmedia_video emotions';

/* FIXME: Styling of link not ok
export const TOOLBAR_DEFAULT: string =
	'link | xcapmedia_upload xcapmedia_browse xcapmedia_video emotions';
*/

/**
 * Plugins needed to support the default toolbar
 * @type {string}
 */
exports.TOOLBAR_DEFAULT = TOOLBAR_DEFAULT;
var PLUGINS_DEFAULT = 'autolink autoresize anchor image link placeholder paste noneditable media xcapmedia contentdetection';

/**
 * A toolbar with most of the useful options
 * @type {string}
 */
exports.PLUGINS_DEFAULT = PLUGINS_DEFAULT;
var TOOLBAR_FULL = 'bold italic underline | bullist numlist | styleselect | justifyleft justifycenter justifyright justifyfull | xcapmedia drafts';

/**
 * Plugins needed to support the full toolbar
 * @type {string}
 */
exports.TOOLBAR_FULL = TOOLBAR_FULL;
var PLUGINS_FULL = 'autoresize anchor fullscreen image link lists placeholder paste searchreplace spellchecker table xcapmedia code drafts noneditable contentdetection textcolor';
exports.PLUGINS_FULL = PLUGINS_FULL;
var CUSTOM_PLUGINS = {
  placeholder: 'placeholder',
  xcapmedia: 'xcapmedia',
  drafts: 'drafts',
  contentdetection: 'contentdetection'
};

/**
 * Default valid elements. Includes iframe to allow for youtube embedding etc
 * @type {string}
 */
var DEFAULT_VALID_ELEMENTS = 'iframe[width|height|src|frameborder|scrolling|marginheight|marginwidth],#xcap-list-comments[reference-id|component],.xcap-user[data-userid]';

/**
 * Default invalid elements.
 * @type {string}
 */
exports.DEFAULT_VALID_ELEMENTS = DEFAULT_VALID_ELEMENTS;
var DEFAULT_INVALID_ELEMENTS = 'applet,button,embed,form,input,object,option,optgroup,script,select,textarea';

/**
 * Get a tiny mce configuration.
 *
 * Example:
 * let config = getConfiguration(1, "news", { toolbar : TOOLBAR_FULL, height: "100px" });
 * @param config
 * @param request
 * @param lang Language code. Default: 'en'
 * @param referenceId Reference id (blog entry id)
 * @param mediaContext (Media context, for example "news"
 * @param overrides Additional options
 *
 *
 */
exports.DEFAULT_INVALID_ELEMENTS = DEFAULT_INVALID_ELEMENTS;
function getConfiguration(_ref) {
  var config = _ref.config,
    request = _ref.request,
    _ref$language = _ref.language,
    language = _ref$language === void 0 ? 'en' : _ref$language,
    _ref$referenceId = _ref.referenceId,
    referenceId = _ref$referenceId === void 0 ? 0 : _ref$referenceId,
    mediaContext = _ref.mediaContext,
    overrides = _ref.overrides;
  language = language === 'en_US' ? 'en' : language;
  var pfx = request.absoluteUrl + request.contextPath;
  var css = pfx + '/css/tinymce.css';
  var customTinyMceHome = pfx + '/js/tinymce';
  var defaultTinyMceHome = pfx + '/js/tinymce-4.3.8';
  var cfg = _extends({
    height: '4em',
    width: '100%',
    language: language,
    base_url: customTinyMceHome,
    language_url: "".concat(pfx, "/js/tiny-mce-langs/").concat(language, ".js"),
    skin: 'xpress',
    skin_url: customTinyMceHome + '/skins/xpress',
    theme: 'modern',
    theme_url: customTinyMceHome + '/themes/modern/theme.min.js',
    menubar: false,
    //plugins: PLUGINS_DEFAULT,
    external_plugins: {},
    toolbar: TOOLBAR_DEFAULT,
    autoresize_max_height: 700,
    autoresize_min_height: 60,
    autoresize_bottom_margin: 8,
    content_css: css,
    body_class: 'style-context-' + mediaContext,
    constrain_menus: true,
    remove_linebreaks: true,
    apply_source_formatting: false,
    // Add iframes and allow comments with padding
    extended_valid_elements: DEFAULT_VALID_ELEMENTS,
    invalid_elements: DEFAULT_INVALID_ELEMENTS,
    convert_urls: false,
    tabfocus_elements: ':prev,:next',
    xcapmedia_context: mediaContext,
    xcapmedia_reference_id: referenceId,
    xcapmedia_drop_anywhere_to_upload: false /* Would interfer with slideshow */,

    //contentdetection_embedding : self.embedding,

    init_instance_callback: function init_instance_callback(editor) {
      // Disabled: will jump around and interfer with hash link scrolling
      //editor.focus();
      console.log('Editor ok', editor);
    },
    style_formats: [{
      title: 'Text'
    }, {
      title: 'Header 1',
      block: 'h1'
    }, {
      title: 'Header 2',
      block: 'h2'
    }, {
      title: 'Header 3',
      block: 'h3'
    }, {
      title: 'Quotation',
      block: 'blockquote'
    }]
  }, overrides);

  // HACK: set urls for all plugins
  var plugins = PLUGINS_DEFAULT.split(/\s+/);
  //let plugins = cfg.plugins.split(/\s+/);
  plugins.forEach(function (p) {
    if (CUSTOM_PLUGINS[p]) {
      cfg.external_plugins[p] = customTinyMceHome + '/plugins/' + p + '/plugin.min.js';
    } else {
      cfg.external_plugins[p] = defaultTinyMceHome + '/plugins/' + p + '/plugin.min.js';
    }
  });
  console.debug('Stackend: Tinymce config:', cfg);
  return cfg;
}