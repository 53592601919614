"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VSeparator = exports.HSeparator = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var sc = _interopRequireWildcard(require("../style-common/styled-component-variables"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
var _StackendTheme = require("@stackend/react/theme/StackendTheme");
var _ComponentType = _interopRequireDefault(require("@stackend/react/theme/ComponentType"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * Vertical separator line
 */
var VSeparator = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-v-separator', props.className)
  };
}).withConfig({
  displayName: "Separatorstyle__VSeparator",
  componentId: "sc-8dvlow-0"
})([".stackend &{height:1em;width:", ";margin:0 ", ";background:", ";}"], function (props) {
  return props.theme.borderWidth || '1px';
}, function (props) {
  return props.theme.margins.medium || '';
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'borderColor') || sc.borderColor;
});

/**
 * Horizontal separator line
 */
exports.VSeparator = VSeparator;
var HSeparator = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-h-separator', props.className)
  };
}).withConfig({
  displayName: "Separatorstyle__HSeparator",
  componentId: "sc-8dvlow-1"
})([".stackend &{height:", ";margin:", " 0;background:", ";}"], function (props) {
  return props.theme.borderWidth || '1px';
}, function (props) {
  return props.theme.margins.medium;
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'borderColor') || sc.borderColor;
});
exports.HSeparator = HSeparator;