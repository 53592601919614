"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseModuleId = parseModuleId;
/**
 * Parse a simple or complex module id.
 * @param id
 * @returns {null|{communityId: number, moduleId: number}}
 */
function parseModuleId(id) {
  if (!id) {
    return null;
  }
  var communityId = 0;
  var moduleId = 0;
  var i = id.indexOf(':');
  if (i === -1) {
    moduleId = parseInt(id);
  } else {
    communityId = parseInt(id.substring(0, i));
    moduleId = parseInt(id.substring(i + 1));
  }
  if (isNaN(moduleId) || isNaN(communityId)) {
    return null;
  }
  return {
    communityId: communityId,
    moduleId: moduleId
  };
}