"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReplyAllowedOn = void 0;
exports.mayReplyTo = mayReplyTo;
var ReplyAllowedOn = {
  /**
   * Replies allowed on threads and comments (other replies)
   */
  THREAD_AND_COMMENT: 'THREAD_AND_COMMENT',
  /**
   * Replies allowed on threads only
   */
  THREAD_ONLY: 'THREAD_ONLY',
  /**
   * Replies not allowed
   */
  NONE: 'NONE'
};

/**
 * Check if replies are allowed for this comment
 * @param comment
 * @param replyAllowedOn
 */
exports.ReplyAllowedOn = ReplyAllowedOn;
function mayReplyTo(comment, replyAllowedOn) {
  switch (replyAllowedOn) {
    case ReplyAllowedOn.THREAD_AND_COMMENT:
      return true;
    case ReplyAllowedOn.THREAD_ONLY:
      return comment.id === comment.parentId;
    default:
      return false;
  }
}