"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getListProductsRequest = getListProductsRequest;
var _shop = require("@stackend/api/shop");
function getListProductsRequest(parameters) {
  if (!parameters) {
    return {};
  }

  // FIXME: Module type must be updated to support first parameter
  var q = parameters.q,
    productTypes = parameters.productTypes,
    tags = parameters.tags,
    sort = parameters.sort,
    imageMaxWidth = parameters.imageMaxWidth,
    first = parameters.first;
  var pt = undefined;
  if (productTypes) {
    pt = productTypes.split(/\\s*[,;]\\s*/);
  }
  var t = undefined;
  if (tags) {
    t = tags.split(/\\s*[,;]\\s*/);
  }
  var f = 10;
  if (first) {
    f = parseInt(first) || 10;
  }
  var r = {
    q: q,
    productTypes: pt,
    tags: t,
    sort: sort,
    imageMaxWidth: imageMaxWidth,
    first: f
  };
  return r;
}