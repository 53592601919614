"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.closeModal = closeModal;
exports.isModalOpen = isModalOpen;
exports.openModal = openModal;
var modalReducer = _interopRequireWildcard(require("./modalReducer.js"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
//Action Creator open modal with name
function openModal(_ref) {
  var modalName = _ref.modalName,
    modalProps = _ref.modalProps;
  return {
    type: modalReducer.OPEN_MODAL,
    modalName: modalName,
    modalProps: modalProps
  };
}

//Action Creator open modal with name
function closeModal(_ref2) {
  var modalName = _ref2.modalName;
  return {
    type: modalReducer.CLOSE_MODAL,
    modalName: modalName
  };
}

/**
 * Check if a modal is open
 * @param state
 * @param modalName
 */
function isModalOpen(state, modalName) {
  return state && state.modalName && state.modalName === modalName;
}