"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ProfileSettings = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var sc = _interopRequireWildcard(require("../../style-common/styled-component-variables.js"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//This is kind of ugly because most of it is jsp

var ProfileSettings = _styledComponents.default.div.withConfig({
  displayName: "ProfileSettings",
  componentId: "sc-133zk1g-0"
})([".stackend &{display:flex;flex-direction:column;position:relative;width:100%;margin:100px auto 0;max-width:", ";", "{max-width:", ";}", "{padding:0 10px;}", "{margin-top:0;}.settingsform{display:flex;border-radius:", ";box-shadow:", ";border:", ";width:100%;padding:20px;", "{flex-direction:column;align-items:center;}}.profile-image-wrapper{display:flex;width:200px;margin-right:20px;flex:1;position:relative;}.profile-image-forms{position:relative;}.button.button-remove{display:flex;align-items:center;justify-content:center;margin:0;background:rgba(251,54,64,1);position:absolute;top:0;right:0;width:50px;height:50px;border-radius:50%;&:hover{background:rgba(251,54,64,0.8);}&:active{background:rgba(251,54,64,0.6);}}#xcapSettingsForm{display:flex;flex:3;flex-direction:column;justify-content:space-evenly;height:100%;", "{width:100%;}}input,.inputwrapper.uneditable{display:flex;align-items:center;padding:0 10px;border:1px solid #ccc;height:40px;width:100%;font-family:", ";font-size:", " !important;border-radius:", ";color:#464646;}.inputwrapper.uneditable{cursor:not-allowed;background:#eee;}label{display:flex;align-items:center;margin:10px 0 5px;font-family:", ";font-size:", " !important;color:#464646;}textarea{display:flex;align-items:center;padding:10px;width:100%;border:1px solid #ccc;font-family:", ";font-size:", " !important;border-radius:", ";color:#464646;}", ";}"], sc.maxContentWidthDesktop, sc.BigDesktop, sc.maxContentWidthLargeDesktop, sc.TabletAndMobile, sc.Mobile, sc.borderRadius, sc.boxShadow, sc.border, sc.Mobile, sc.Mobile, sc.fontNormal, sc.fontSizeBodyText, sc.borderRadius, sc.fontMedium, sc.fontSizeBodyText, sc.fontNormal, sc.fontSizeBodyText, sc.borderRadius, function (props) {
  return !!props.theme.ProfileSettings && props.theme.ProfileSettings(props);
});
exports.ProfileSettings = ProfileSettings;
var _default = ProfileSettings;
exports.default = _default;