"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FullScreenContent = exports.FullScreen = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _styledComponentVariables = require("../style-common/styled-component-variables");
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
var _media = require("../styled-components/media");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var FullScreenContent = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-full-screen-content', props.className)
  };
}).withConfig({
  displayName: "FullScreenstyle__FullScreenContent",
  componentId: "sc-1tjej29-0"
})([".stackend &{width:100%;height:100%;background-color:", ";", "{width:80%;height:80%;box-shadow:0 0 ", " 0 ", ";}}"], function (props) {
  return props.theme.backgroundColor;
}, _media.media.overMobile, function (props) {
  return props.theme.margins.large;
}, function (props) {
  return props.theme.color;
});
exports.FullScreenContent = FullScreenContent;
var FullScreen = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-full-screen', props.className)
  };
}).withConfig({
  displayName: "FullScreenstyle__FullScreen",
  componentId: "sc-1tjej29-1"
})([".stackend &{position:fixed;width:100vw;height:100vh;height:-webkit-fill-available;top:0;left:0;max-width:unset;max-height:unset;border:none;border-radius:unset;transform:none;padding:0;overflow:hidden;z-index:", ";background-color:", ";display:flex;align-items:center;justify-content:center;}"], _styledComponentVariables.zIndexes.userMenu - 10, function (props) {
  return props.theme.color + '37';
});
exports.FullScreen = FullScreen;