"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ShopItems = void 0;
var _styledComponents = require("styled-components");
var _ProductListingItem = require("@stackend/react/shop/ProductListingItem.style");
var _Product = require("@stackend/react/shop/Product.style");
var _Shop = require("@stackend/react/shop/Shop.style");
var _ProductImageBrowser = require("@stackend/react/shop/ProductImageBrowser.style");
var _media = require("../media");
/**
 * Styling for shop items when they appear in rich content
 */
var ShopItems = function ShopItems() {
  return (0, _styledComponents.css)(["", ",", "{margin:1em 0;}", "{> .stackend-product-link{width:auto;}}.stackend-shop-product-module-horizontal{", "{.stackend-product-link{padding:0;}}}", "{", "{margin:0.5em 0;}", "{grid-template-rows:minmax(1em,min-content) minmax(1em,min-content) 1fr;}", "{", "{margin:0 0.5em;}}}", "{", "{margin-left:1em;}> a > img{width:100%;}}", "{list-style:none;> li{display:inline-block;margin:0.5em;button{cursor:pointer;}}}"], _ProductListingItem.ProductListingItem, _Product.Product, _ProductListingItem.ProductListingItem, _ProductListingItem.ProductListingItem, _Product.Product, _Shop.Title, _media.media.overMobile, _media.media.mobileScreen, _Shop.Description, _ProductImageBrowser.ProductImageBrowser, _media.media.overMobile, _ProductImageBrowser.ProductImageBrowserThumbnails);
};
exports.ShopItems = ShopItems;