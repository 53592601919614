"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRegistrationProvider = getRegistrationProvider;
var _login = require("@stackend/api/login");
var _RegistrationProvider = require("./RegistrationProvider.js");
var _FacebookRegistrationProvider = require("./FacebookRegistrationProvider.js");
var _XcapRegistrationProvider = require("./XcapRegistrationProvider.js");
var _GoogleRegistrationProvider = require("./GoogleRegistrationProvider.js");
var _OAuth2RegistrationProvider = require("./OAuth2RegistrationProvider.js");
/**
 * Get a registration provider for the specified autenticationType
 * @param autenticationType
 * @param props
 * @returns {*}
 */
function getRegistrationProvider(autenticationType, props) {
  var p = props || {};
  switch (autenticationType) {
    case _login.AuthenticationType.FACEBOOK:
      return new _FacebookRegistrationProvider.FacebookRegistrationProvider(p);
    case _login.AuthenticationType.XCAP:
      return new _XcapRegistrationProvider.XcapRegistrationProvider(p);
    case _login.AuthenticationType.GOOGLE:
      return new _GoogleRegistrationProvider.GoogleRegistrationProvider(p);
    case _login.AuthenticationType.OAUTH2:
      return new _OAuth2RegistrationProvider.OAuth2RegistrationProvider(p);
    default:
      throw 'Autentication Type ' + autenticationType + ' not supported';
  }
}