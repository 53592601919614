"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getGaObjectName = getGaObjectName;
exports.sendEventToGA = sendEventToGA;
exports.sendPageViewToGA = sendPageViewToGA;
exports.sendTimingEventToGA = sendTimingEventToGA;
var _analyticsActions = require("./analyticsActions.js");
var _object = _interopRequireDefault(require("lodash/object"));
var _lang = require("lodash/lang");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function sendEventToGA(event) {
  return function (dispatch, getState) {
    if (!(0, _lang.isEqual)(getState().analyticsReducer.event, event)) {
      dispatch((0, _analyticsActions.setAnalyticsEvent)(event));
      var eventAction = getEventName(event);
      var normalizedEvent = getNormalizedEvent(event);
      if (typeof stackendGA === 'function') {
        stackendGA('event', eventAction, normalizedEvent);
      } else {
        /*	Stop warning for stackendGA in stackend
        	console.warn('Could not send GA-event, stackendGA is undefined')
         */
      }
    }
  };
}
function sendTimingEventToGA(timingEvent) {
  return function (dispatch, getState) {
    if (!getState().analyticsReducer.analyticsTimingSet) {
      dispatch((0, _analyticsActions.setAnalyticsTiming)());
      dispatch(sendEventToGA(timingEvent));
    }
  };
}
function sendPageViewToGA(pageViewEvent) {
  return function (dispatch, getState) {
    var state = getState();
    if (!(0, _lang.isEqual)(state.analyticsReducer.pageView, pageViewEvent)) {
      dispatch((0, _analyticsActions.setAnalyticsPageview)(pageViewEvent));
      if (!getState().analyticsReducer.firstPageView) {
        stackendGA('config', _object.default.get(state, 'communities.community.settings.gaid'), pageViewEvent);
      } else {
        dispatch((0, _analyticsActions.firstPageView)());
      }
    }
  };
}
function getEventName(event) {
  return event.event_action;
}
function getNormalizedEvent(event) {
  var normalized = _objectSpread(_objectSpread({}, event), {});
  delete normalized.event_action;
  return normalized;
}
var objectNameConverter = {
  BlogEntry_groups: 'Community post',
  CommentImpl_groups: 'Community comment',
  CommentImpl_commentModule: 'Module comment',
  BlogEntry_blog: 'Blog post',
  CommentImpl_blog: 'Blog comment',
  ForumThreadImpl_question_threadParent: 'QNA question',
  ForumThreadEntryImpl_question_threadParent: 'QNA question',
  ForumThreadEntryImpl_question: 'QNA answer'
};
function getGaObjectName(_ref) {
  var object = _ref.object,
    relatedToObject = _ref.relatedToObject;
  if (!object) {
    console.warn('getGaObjectName: no object supplied: ', object, relatedToObject);
    return '';
  }
  var objectName = "".concat(object.substring(object.lastIndexOf('.') + 1), "_").concat(relatedToObject);
  var forumThreadRegex = /([^_]+)_([^_]+)(_threadParent)?.*/.exec(objectName);
  if (forumThreadRegex && forumThreadRegex[1].indexOf('Forum') > -1 && forumThreadRegex[2] !== 'question') {
    return 'Forum ' + forumThreadRegex[2] + (forumThreadRegex[3] === '_threadParent' ? ' Thread parent' : ' Thread child');
  }
  return objectNameConverter[objectName] || '';
}