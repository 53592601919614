"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = createImgTag;
var _media = require("@stackend/api/media");
/**
 * Given an image, create an img element with correct styling for resposive display
 * @param image
 * @param thumbnailConfigName Optional thumbnail config name
 */
function createImgTag(_ref) {
  var image = _ref.image,
    thumbnailConfigName = _ref.thumbnailConfigName;
  if (!image) {
    return null;
  }
  var url = image.url;
  if (thumbnailConfigName) {
    url = (0, _media.getThumbnailUrl)({
      url: image.url,
      size: thumbnailConfigName
    });
  }
  return '<img src="' + url + '" style="max-width: ' + image.width + 'px; height: auto"/>';
}