"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Loading = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var Loading = _styledComponents.default.div.attrs(function (props) {
  return {
    className: 'stackend-loading'
  };
}).withConfig({
  displayName: "Loadingstyle__Loading",
  componentId: "sc-15h31yb-0"
})([".stackend &{display:flex;justify-content:center;align-content:center;text-align:center;padding:", ";}"], function (props) {
  return props.theme.margins.small;
});
exports.Loading = Loading;
var _default = Loading;
exports.default = _default;