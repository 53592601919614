"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generateCommentHtmlId;
exports.generateCommentHtmlId2 = generateCommentHtmlId2;
/**
 * Generate an html id for a comment
 * @param comment
 * @param parentHashLink
 * @returns {string}
 */
function generateCommentHtmlId(comment, parentHashLink) {
  return generateCommentHtmlId2(comment.id, comment.referenceId, parentHashLink);
}
function generateCommentHtmlId2(id, referenceId, parentHashLink) {
  var s = '';
  if (parentHashLink) {
    var parentLink = parentHashLink ? parentHashLink.replace(/^#/, '') : '';
    s = parentLink + ';/comment/' + referenceId + '/' + id;
  } else {
    s = '/comment/' + referenceId + '/' + id;
  }
  return s;
}