"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RecentRepliesFirstCommentSort;
var _CreatedDateComparator = _interopRequireDefault(require("../../xcap/CreatedDateComparator"));
var _CommentSort = require("./CommentSort");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ReplyComparator(a, b) {
  if (a === b) {
    return 0;
  }
  if (a.subject && !isNaN(parseInt(a.subject))) {
    return (0, _CreatedDateComparator.default)(b, a);
  }
  return (0, _CreatedDateComparator.default)(a, b);
}

/**
 * Order comments by threads, parent id, stabilize by id.
 * FIXME: There is no backend sorting implemented that matches this 100%
 * @constructor
 */
function RecentRepliesFirstCommentSort(entries) {
  return (0, _CommentSort.sortComments)(entries, _CreatedDateComparator.default, ReplyComparator);
}