"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DisplayType = void 0;
/**
 * Display type for blog entries.
 */
var DisplayType = {
  FeedEntry: 'feedEntry',
  PersonalFeedEntry: 'personalFeedEntry',
  BlogEntry: 'blogEntry',
  BLOG_ENTRY_PORTFOLIO: 'BLOG_ENTRY_PORTFOLIO',
  TopList: 'toplist',
  FeedEntryTopList: 'FeedEntryTopList',
  BlogEntryTopList: 'BlogEntryTopList',
  //SearchListing: 'searchListing',
  EntrySearchResult: 'entrySearchResult'
};
exports.DisplayType = DisplayType;