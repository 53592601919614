"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.SearchListingItemNoContent = exports.SearchListingItem = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _ForumBodyWrapper = _interopRequireDefault(require("../Wrappers/ForumBodyWrapper.js"));
var sc = _interopRequireWildcard(require("../../style-common/styled-component-variables.js"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var SearchListingItem = _styledComponents.default.span.withConfig({
  displayName: "SearchListingItem",
  componentId: "sc-cbqnyg-0"
})([".stackend &{position:relative;display:inline-block;width:100%;font-size:", " !important;font-family:", ";border-bottom:", ";color:", ";&:last-child{border-bottom:none;}a h3,h2,h1{display:block;font-size:", " !important;color:", ";font-family:", ";font-weight:normal;margin:0;h1{font-family:", ";font-weight:normal;}div{font-family:", ";color:rgba(70,70,70,0.5);}}a{font-family:", ";font-size:", " !important;color:", ";font-weight:normal;margin:0;}p{font-size:", ";color:rgba(70,70,70,0.5);}", " p,.blog-text-content{font-size:", ";}", ";}"], sc.fontSizeBodyText, sc.fontNormal, sc.border, sc.colorText, sc.fontSizeBodyText, sc.colorText, sc.fontMedium, sc.fontMedium, sc.fontNormal, sc.fontNormal, sc.fontSizeBodyText, sc.colorText, sc.fontSizeSmall, _ForumBodyWrapper.default, sc.fontSizeSmall, function (props) {
  return !!props.theme.SearchListingItem && props.theme.SearchListingItem(props);
});
exports.SearchListingItem = SearchListingItem;
var SearchListingItemNoContent = (0, _styledComponents.default)(SearchListingItem).withConfig({
  displayName: "SearchListingItem__SearchListingItemNoContent",
  componentId: "sc-cbqnyg-1"
})([".stackend &{padding:5px 40px 5px 20px;display:flex;align-items:center;a:not(.stackend-user-small){display:block;padding:20px 0;}.stackend__UserImage__small,.stackend__User__user-name-short{display:inline-block;vertical-align:middle;margin-right:10px;}.stackend-user-name{display:inline-block;vertical-align:middle;margin-left:10px;}", ";}"], function (props) {
  return !!props.theme.SearchListingItemNoContent && props.theme.SearchListingItemNoContent(props);
});
exports.SearchListingItemNoContent = SearchListingItemNoContent;
var _default = SearchListingItem;
exports.default = _default;