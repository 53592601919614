"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.H4 = exports.H3 = exports.H2 = exports.H1 = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// Sizes are set in StackendModulesGlobalStyle.js

var H1 = _styledComponents.default.h1.withConfig({
  displayName: "Titles__H1",
  componentId: "sc-igr0uy-0"
})([".stackend &{margin:", " 0;text-align:", ";", ";}"], function (props) {
  return props.theme.margins.small;
}, function (props) {
  return props.center ? 'center' : props.right ? 'right' : 'left';
}, function (props) {
  return !!props.theme.H1 && props.theme.H1(props);
});
exports.H1 = H1;
var H2 = _styledComponents.default.h2.withConfig({
  displayName: "Titles__H2",
  componentId: "sc-igr0uy-1"
})([".stackend &{text-align:", ";", ";margin:0 0 10px 0;}"], function (props) {
  return props.center ? 'center' : props.right ? 'right' : 'left';
}, function (props) {
  return !!props.theme.H2 && props.theme.H2(props);
});
exports.H2 = H2;
var H3 = _styledComponents.default.h3.withConfig({
  displayName: "Titles__H3",
  componentId: "sc-igr0uy-2"
})([".stackend &{margin:", " 0;text-align:", ";", ";}"], function (props) {
  return props.theme.margins.small;
}, function (props) {
  return props.center ? 'center' : props.right ? 'right' : 'left';
}, function (props) {
  return !!props.theme.H3 && props.theme.H3(props);
});
exports.H3 = H3;
var H4 = _styledComponents.default.h4.withConfig({
  displayName: "Titles__H4",
  componentId: "sc-igr0uy-3"
})([".stackend &{margin:", " 0;text-align:", ";", ";}"], function (props) {
  return props.theme.margins.small;
}, function (props) {
  return props.center ? 'center' : props.right ? 'right' : 'left';
}, function (props) {
  return !!props.theme.H4 && props.theme.H4(props);
});
exports.H4 = H4;
var _default = H1;
exports.default = _default;