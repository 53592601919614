"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.BasketButton = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
var sc = _interopRequireWildcard(require("../style-common/styled-component-variables.js"));
var _StackendTheme = require("@stackend/react/theme/StackendTheme");
var _ComponentType = _interopRequireDefault(require("@stackend/react/theme/ComponentType"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var BasketButton = _styledComponents.default.button.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-shop-open-basket', props.className)
  };
}).withConfig({
  displayName: "BasketButtonstyle__BasketButton",
  componentId: "sc-os29qs-0"
})([".stackend &{border-radius:100%;width:", ";height:", ";position:relative;cursor:pointer;background:", ";color:", ";border:none;padding:0;&:hover,&:active,&:focus{background:", ";color:", ";}&.stackend-shop-basket-has-items{:before{position:absolute;content:' ';background:", ";width:0.5em;height:0.5em;top:0;right:0;border-radius:100%;}}}"], sc.profileImageSize.small, sc.profileImageSize.small, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'backgroundColor');
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'color');
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'backgroundColor');
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'color');
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.TEXT, 'color');
});
exports.BasketButton = BasketButton;
var _default = BasketButton;
exports.default = _default;