"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.PollVote = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var PollVote = _styledComponents.default.button.attrs({
  className: 'stackend-poll-vote'
}).withConfig({
  displayName: "PollVotestyle__PollVote",
  componentId: "sc-1qu9ao2-0"
})([".stackend &{position:relative;overflow:hidden;width:100%;outline:none;border:none;background:transparent;flex-direction:column;justify-content:center;align-items:flex-start;&:hover{background-color:unset;}", ";}"], function (props) {
  return !!props.theme.PollVote && props.theme.PollVote(props);
});
exports.PollVote = PollVote;
var _default = PollVote;
exports.default = _default;