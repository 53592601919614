"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VerifyEmailForm = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _WhiteWrapper = require("../styled-components/Wrappers/WhiteWrapper.js");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var VerifyEmailForm = _styledComponents.default.div.withConfig({
  displayName: "VerifyEmailFormstyle__VerifyEmailForm",
  componentId: "sc-2822jc-0"
})([".stackend &{.stackend-resend-activation-code{text-align:left;background:unset;color:black;font-size:unset;}", "{box-shadow:none;}.buttons{display:flex;flex-direction:column;justify-content:center;align-items:center;}.stackend-verify-messages{text-align:center;padding:20px;}.stackend-verify-buttons{display:grid;grid-template-rows:repeat(2,1fr);gap:20px;place-items:center;button{min-width:134px;}}}"], _WhiteWrapper.WhiteWrapper);
exports.VerifyEmailForm = VerifyEmailForm;