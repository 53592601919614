"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SetBodyClass = SetBodyClass;
var _SetBodyClassReducer = require("../reducers/SetBodyClassReducer.jsx");
//Action Creator
function SetBodyClass(name) {
  return {
    type: _SetBodyClassReducer.SET_BODY_CLASS,
    name: name
  };
}