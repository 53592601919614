"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.FlexBoxItem = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var FlexBoxItem = _styledComponents.default.div.withConfig({
  displayName: "FlexBoxItem",
  componentId: "sc-veppmd-0"
})([".stackend &{display:flex;flex-direction:", ";flex:", ";justify-content:space-evenly;align-self:center;padding:20px;", ";}"], function (props) {
  return props.column ? 'column' : 'wrap';
}, function (props) {
  return props.flex || '1';
}, function (props) {
  return !!props.theme.FlexBoxItem && props.theme.FlexBoxItem(props);
});
exports.FlexBoxItem = FlexBoxItem;
var _default = FlexBoxItem;
exports.default = _default;