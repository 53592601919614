"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.CorrectBanner = exports.Correct = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var sc = _interopRequireWildcard(require("../../style-common/styled-component-variables.js"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var Correct = _styledComponents.default.span.withConfig({
  displayName: "Correct",
  componentId: "sc-2p7prh-0"
})([".stackend &{position:absolute;text-align:left;right:10px;bottom:10px;display:block;margin:0;padding:10px 20px;font-size:14px;font-family:", ";width:150px;color:", ";border-radius:5px;", "{position:relative;margin:0 auto;}", ";}"], sc.fontNormal, sc.colorTextAccent, sc.Mobile, function (props) {
  return !!props.theme.Correct && props.theme.Correct(props);
});
exports.Correct = Correct;
var CorrectBanner = _styledComponents.default.span.withConfig({
  displayName: "Correct__CorrectBanner",
  componentId: "sc-2p7prh-1"
})([".stackend &{position:absolute;right:10px;bottom:10px;display:block;width:17px;", ";}"], function (props) {
  return !!props.theme.CorrectBanner && props.theme.CorrectBanner(props);
});
exports.CorrectBanner = CorrectBanner;
var _default = Correct;
exports.default = _default;