"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MinimalisticEditor = exports.EditorBorder = exports.Button = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
var _media = require("../styled-components/media.js");
var _styledComponentVariables = require("../style-common/styled-component-variables");
var _StackendTheme = require("@stackend/react/theme/StackendTheme");
var _ComponentType = _interopRequireDefault(require("@stackend/react/theme/ComponentType"));
var _ComponentState = _interopRequireDefault(require("@stackend/react/theme/ComponentState"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var Button = _styledComponents.default.button.attrs(function (props) {
  return {};
}).withConfig({
  displayName: "MinimalisticEditorstyle__Button",
  componentId: "sc-99mtun-0"
})([""]);
exports.Button = Button;
var EditorBorder = _styledComponents.default.div.attrs(function (props) {
  return {};
}).withConfig({
  displayName: "MinimalisticEditorstyle__EditorBorder",
  componentId: "sc-99mtun-1"
})([""]);
exports.EditorBorder = EditorBorder;
var MinimalisticEditor = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-minimalistic-editor', props.className)
  };
}).withConfig({
  displayName: "MinimalisticEditorstyle__MinimalisticEditor",
  componentId: "sc-99mtun-2"
})([".stackend &{display:flex;flex-direction:row;flex:1 1 auto;align-items:center;", "{flex:1 1 auto;border:", " solid ", ";border-radius:", ";background-color:", ";display:flex;flex-direction:row;gap:0.25em;align-items:center;width:100%;margin:0.25em 0.5em;&:focus-within{outline:", " solid ", ";}.quill.stackend-rich-content-editor-impl{flex:1 1 auto;.ql-container{border:none;}.ql-editor{display:flex;align-items:center;margin-left:", ";padding-left:1px;padding-top:", ";padding-bottom:", ";min-height:1.5em;color:", ";&:focus-visible{border:none;box-shadow:none;outline:none;}&.ql-blank::before{left:", ";color:", ";", "{font-size:0.75em;white-space:nowrap;overflow:hidden;}}img{max-height:8em;width:auto;}.stackend-product-placeholder{border:", ";background-color:", ";color:", ";border-radius:", ";display:flex;flex-direction:row;gap:0.5em;padding:0.25em;align-items:center;margin:0.25em;margin-right:1em;overflow:hidden;img{max-height:2em;", "{max-height:1em;}}}}}}", "{padding:0.5rem;font-size:1rem;cursor:pointer;&:first-child{padding-left:0;}&:disabled{color:", ";}}}"], EditorBorder, function (props) {
  return props.theme.borderWidth;
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.TEXT_INPUT, 'borderColor');
}, function (props) {
  return props.theme.borderRadius;
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.TEXT_INPUT, 'backgroundColor');
}, function (props) {
  return props.theme.borderWidth;
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.TEXT_INPUT, 'outlineColor', _ComponentState.default.FOCUS);
}, function (props) {
  return props.theme.margins.small;
}, function (props) {
  return props.theme.margins.small;
}, function (props) {
  return props.theme.margins.small;
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.TEXT_INPUT, 'color');
}, function (props) {
  return props.theme.margins.small;
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.TEXT_INPUT, 'color', _ComponentState.default.DISABLED);
}, _media.media.mobileScreen, function (props) {
  return (0, _StackendTheme.getComponentBorder)(props.theme, _ComponentType.default.ACCENT);
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.ACCENT, 'backgroundColor');
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.ACCENT, 'color');
}, function (props) {
  return props.theme.borderRadius;
}, _media.media.mobileScreen, Button, _styledComponentVariables.colorLight);
exports.MinimalisticEditor = MinimalisticEditor;