"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.YouTubePlayer = exports.VideoPlaceholder = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var VideoPlaceholder = _styledComponents.default.div.attrs(function (props) {
  return {
    className: 'stackend-youtube-video-placeholder'
  };
}).withConfig({
  displayName: "YouTubePlayerstyle__VideoPlaceholder",
  componentId: "sc-vsn5o4-0"
})([""]);
exports.VideoPlaceholder = VideoPlaceholder;
var YouTubePlayer = _styledComponents.default.div.attrs(function (props) {
  return {
    className: 'stackend-youtube-player'
  };
}).withConfig({
  displayName: "YouTubePlayerstyle__YouTubePlayer",
  componentId: "sc-vsn5o4-1"
})([".stackend &{background:black;}"]);
exports.YouTubePlayer = YouTubePlayer;