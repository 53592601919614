"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.NarrowWrapperBox = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _FullWrapperBox = require("./FullWrapperBox.js");
var _CenteredTitle = require("../Titles/CenteredTitle.js");
var _SearchResultHeader = require("../Search/SearchResultHeader.js");
var sc = _interopRequireWildcard(require("../../style-common/styled-component-variables.js"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/*
 **  E.g. used on Home (Startpage.jsx) or Support (SupportPage.jsx) preferably together with a <Sc.Intro> beside
 */

var NarrowWrapperBox = _styledComponents.default.div.withConfig({
  displayName: "NarrowWrapperBox",
  componentId: "sc-qssczr-0"
})([".stackend &{display:inline-block;vertical-align:top;width:70%;margin:0 0 0 2%;padding:0;", "{display:block;padding:0;width:100%;max-width:initial;margin:40px auto 0;}", "{margin-top:10px;}", "{margin-top:", ";margin-bottom:", ";}", "{background:", ";box-shadow:", ";border:", ";}", "{display:", ";}", ";}"], sc.TabletAndMobile, sc.Mobile, _CenteredTitle.CenteredTitle, function (props) {
  return props.tabbed && '40px';
}, function (props) {
  return props.tabbed && '80px';
}, _FullWrapperBox.FullWrapperBox, function (props) {
  return props.transparent && 'none';
}, function (props) {
  return props.transparent && 'none';
}, function (props) {
  return props.transparent && 'none';
}, _SearchResultHeader.SearchResultHeader, function (props) {
  return props.transparent && 'none';
}, function (props) {
  return !!props.theme.NarrowWrapperBox && props.theme.NarrowWrapperBox(props);
});
exports.NarrowWrapperBox = NarrowWrapperBox;
var _default = NarrowWrapperBox;
exports.default = _default;