"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ModalThrobberOverlay = exports.ModalThrobber = exports.LoadingThrobber = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var ModalThrobberOverlay = _styledComponents.default.div.withConfig({
  displayName: "Throbber__ModalThrobberOverlay",
  componentId: "sc-12dpcbw-0"
})([".stackend &{position:fixed;top:0;left:0;width:100vw;height:100vh;z-index:32765;background:rgba(0,0,0,0.5);", ";}"], function (props) {
  return !!props.theme.ModalThrobberOverlay && props.theme.ModalThrobberOverlay(props);
});
exports.ModalThrobberOverlay = ModalThrobberOverlay;
var ModalThrobber = _styledComponents.default.div.withConfig({
  displayName: "Throbber__ModalThrobber",
  componentId: "sc-12dpcbw-1"
})([".stackend &{width:8em;height:8em;margin:20vh auto;text-align:center;border-radius:50%;background-image:url(", ");", ";}"], require('./throbber-unbranded.svg'), function (props) {
  return !!props.theme.ModalThrobber && props.theme.ModalThrobber(props);
});
exports.ModalThrobber = ModalThrobber;
var LoadingThrobber = _styledComponents.default.div.withConfig({
  displayName: "Throbber__LoadingThrobber",
  componentId: "sc-12dpcbw-2"
})([".stackend &{height:8px;width:100vw;position:fixed;top:0;left:0;z-index:800;overflow:hidden;background-color:#ffbbbb;@keyframes loading{0%{left:-30%;width:30%;}50%{width:30%;}70%{width:70%;}80%{left:50%;}95%{left:120%;}100%{left:100%;}}&:before{display:block;position:absolute;content:'';left:-200px;width:200px;height:100%;background-color:#ef5350;animation:loading 2s linear infinite;}}"]);
exports.LoadingThrobber = LoadingThrobber;