"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.CommentListWrapper = exports.CommentListIntro = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var CommentListWrapper = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-comment-list-wrapper', props.className)
  };
}).withConfig({
  displayName: "CommentListWrapper",
  componentId: "sc-19hg9lj-0"
})([".stackend &{vertical-align:top;width:100%;margin:", " 0 0;display:flex;flex-direction:column;.stackend-loading-text{display:none;}button.stackend-login-to-comment{align-self:end;}", ";}"], function (props) {
  return props.theme.margins.small;
}, function (props) {
  return !!props.theme.CommentListWrapper && props.theme.CommentListWrapper(props);
});
exports.CommentListWrapper = CommentListWrapper;
var CommentListIntro = _styledComponents.default.div.attrs(function (props) {
  return {
    className: 'stackend-comment-list-intro'
  };
}).withConfig({
  displayName: "CommentListWrapper__CommentListIntro",
  componentId: "sc-19hg9lj-1"
})([".stackend &{padding:20px;margin-top:20px;background:", ";border-radius:", ";}"], function (props) {
  return props.theme.shopifyMainColor;
}, function (props) {
  return props.theme.shopifyBorderRadius;
});
exports.CommentListIntro = CommentListIntro;
var _default = CommentListWrapper;
exports.default = _default;