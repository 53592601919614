"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PersonalShopperStyle = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Comment = require("../comments/comment/Comment.style");
var _styledComponents2 = require("../styled-components");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var PersonalShopperStyle = _styledComponents.default.div.attrs(function (props) {
  return {
    className: 'stackend-personal-shopper'
  };
}).withConfig({
  displayName: "PersonalShopperstyle__PersonalShopperStyle",
  componentId: "sc-1fnv4y0-0"
})([".stackend &{", "{display:none;}", "{padding:20px;padding-top:40px;border-radius:0 ", " ", ";position:relative;margin-top:20px;background-color:", ";:after{content:'';position:absolute;display:block;width:0;border-style:solid;border-width:0px 20px 20px 0px;top:-19px;left:0%;margin-left:0px;border-color:", " transparent;}}}"], _Comment.CommentActions, _styledComponents2.WriteCommentWrapper, function (props) {
  return props.theme.shopifyBorderRadius;
}, function (props) {
  return props.theme.shopifyBorderRadius;
}, function (props) {
  return props.theme.shopifyMainColor || '#ccc';
}, function (props) {
  return props.theme.shopifyMainColor || '#ccc';
});
exports.PersonalShopperStyle = PersonalShopperStyle;