"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getLoadingStateClass;
var _LoadingState = _interopRequireDefault(require("@stackend/api/api/LoadingState"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function getLoadingStateClass(loadingState) {
  switch (loadingState) {
    case _LoadingState.default.NOT_STARTED:
      return 'stackend-loading-not-started';
    case _LoadingState.default.LOADING:
      return 'stackend-loading';
    default:
      return 'stackend-loading-ready';
  }
}