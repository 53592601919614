"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Register = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var Register = _styledComponents.default.div.withConfig({
  displayName: "Register",
  componentId: "sc-1qc2oyx-0"
})([".stackend &{.error{color:#fb3640;display:inline-block;margin:0 20px 20px 25px;}input[type='checkbox']{width:auto;}button{border:solid 10px pink;}", ";}"], function (props) {
  return !!props.theme.Register && props.theme.Register(props);
});
exports.Register = Register;
var _default = Register;
exports.default = _default;