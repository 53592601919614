"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VerifyEmailPage = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var VerifyEmailPage = _styledComponents.default.div.withConfig({
  displayName: "VerifyEmailPagestyle__VerifyEmailPage",
  componentId: "sc-1xei533-0"
})([".stackend &{text-align:center;.error{color:red;font-weight:bold;}.send-new-code{margin-top:4em;}", ";}"], function (props) {
  return !!props.theme.VerifyEmailPage && props.theme.VerifyEmailPage(props);
});
exports.VerifyEmailPage = VerifyEmailPage;