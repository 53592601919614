"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.WhiteWrapper = void 0;
var _react = _interopRequireDefault(require("react"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var sc = _interopRequireWildcard(require("../../style-common/styled-component-variables.js"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var buttonVerification = "\n\tpadding: 0 3%;\n\tmargin: 0;\n\tvertical-align: middle;\n\tmargin-top: -0.2em;\n\theight: 33px;\n\tmargin-left: 10px;\n\tdisplay: inline-block;\n\t\n\t".concat(sc.Mobile, " {\n\t\tmargin: 0% 2% 7px 3%;\n\t}\n");
var buttonRegistration = "\n\tmargin: 50px 2% 7px 2%;\n\n\t".concat(sc.Mobile, " {\n\t\tmargin: 10% 2% 7px 2%;\n\t}\n");
var WhiteWrapper = _styledComponents.default.div.withConfig({
  displayName: "WhiteWrapper",
  componentId: "sc-sjzl5u-0"
})([".stackend &{background:#fff;padding:25px 65px;border-radius:5px;box-shadow:0px 0px 6px 0px rgba(0,0,0,0.4);width:100%;max-width:600px;text-align:center;margin:0 auto;padding-bottom:40px;overflow:hidden;", "{max-width:90%;padding:5%;margin-bottom:5%;}h2,a{color:#389894;font-weight:normal;}h2{font-size:3em;margin:18px 0;", "{font-size:2.5em;}}p{font-weight:300;font-size:1.3em;margin:18px 0;", "{font-size:1.2em;}&.error-message{color:#ff292e;margin:0 auto;font-size:15px;font-weight:normal;}}button{", ";}.email{font-size:1.3em;margin:18px 0;display:block;", "{font-size:1.2em;}}fieldset{margin:0;}input[type='text'],input[type='email'],input[type='password']{width:46%;margin:2%;font-weight:300;&.error{border:1px solid red;}", "{width:100%;margin:0;margin-bottom:3%;}}.line-divider{font-size:26px;font-weight:bold;vertical-align:middle;padding:0 4px;}", ";}"], sc.Mobile, sc.Mobile, sc.Mobile, function (props) {
  if (props.verification) {
    return buttonVerification;
  } else if (props.registration) {
    return buttonRegistration;
  } else if (props.iwantin) {
    return buttonRegistration;
  }
}, sc.Mobile, sc.Mobile, function (props) {
  return !!props.theme.WhiteWrapper && props.theme.WhiteWrapper(props);
});
exports.WhiteWrapper = WhiteWrapper;
var _default = WhiteWrapper;
exports.default = _default;