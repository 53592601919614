"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EventDateMonth = exports.EventDateDay = exports.EventDate = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _StackendTheme = require("@stackend/react/theme/StackendTheme");
var _ComponentType = _interopRequireDefault(require("@stackend/react/theme/ComponentType"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var EventDateDay = _styledComponents.default.div.attrs({
  className: 'stackend-event-date-day'
}).withConfig({
  displayName: "EventDatestyle__EventDateDay",
  componentId: "sc-1j3iqoc-0"
})([".stackend &{font-size:1.5em;}"]);
exports.EventDateDay = EventDateDay;
var EventDateMonth = _styledComponents.default.div.attrs({
  className: 'stackend-event-date-month'
}).withConfig({
  displayName: "EventDatestyle__EventDateMonth",
  componentId: "sc-1j3iqoc-1"
})([".stackend &{background-color:", ";color:", ";}"], function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'backgroundColor');
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'color');
});
exports.EventDateMonth = EventDateMonth;
var EventDate = _styledComponents.default.div.attrs({
  className: 'stackend-event-date'
}).withConfig({
  displayName: "EventDatestyle__EventDate",
  componentId: "sc-1j3iqoc-2"
})([".stackend &{border:", ";border-radius:", ";overflow:hidden;font-weight:bold;", ",", "{padding:", " ", ";text-align:center;}}"], function (props) {
  return (0, _StackendTheme.getComponentBorder)(props.theme, _ComponentType.default.BOX);
}, function (props) {
  return props.theme.borderRadius;
}, EventDateDay, EventDateMonth, function (props) {
  return props.theme.margins.small;
}, function (props) {
  return props.theme.margins.medium;
});
exports.EventDate = EventDate;