"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.HeroCharacters = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var HeroCharacters = _styledComponents.default.div.withConfig({
  displayName: "HeroCharacters",
  componentId: "sc-7yoerg-0"
})([".stackend &{position:relative;align-self:flex-end;", ";}"], function (props) {
  return !!props.theme.HeroCharacters && props.theme.HeroCharacters(props);
});
exports.HeroCharacters = HeroCharacters;
var _default = HeroCharacters;
exports.default = _default;