"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.VerificationCodeField = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var VerificationCodeField = _styledComponents.default.div.withConfig({
  displayName: "VerificationCodeFieldstyle__VerificationCodeField",
  componentId: "sc-10b2ig7-0"
})([".stackend &{margin:1em 0;text-align:center;display:flex;align-items:center;justify-content:center;input[type='text'].stackend-code-field{width:2.5em;text-align:center;text-transform:uppercase;font-family:monospace;margin:0.2em;font-size:2em;padding:0.2em;}", ";}"], function (props) {
  return !!props.theme.VerificationCodeField && props.theme.VerificationCodeField(props);
});
exports.VerificationCodeField = VerificationCodeField;
var _default = VerificationCodeField;
exports.default = _default;