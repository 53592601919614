"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledUserLink = exports.StyledUserImage = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _reactRouterDom = require("react-router-dom");
var _styledComponentVariables = _interopRequireDefault(require("../style-common/styled-component-variables.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var StyledUserImage = _styledComponents.default.img.attrs(function (props) {
  return {
    className: 'stackend-user-profile-image'
  };
}).withConfig({
  displayName: "UserImagestyle__StyledUserImage",
  componentId: "sc-2s2bhp-0"
})([".stackend &{background:none;border-radius:calc(", " * 0.5);outline:2px solid black;outline-offset:-2px;&.stackend-user-image-single-line{vertical-align:middle;}&.stackend-user-image-tiny{width:", ";height:", ";}&.stackend-user-image-small{width:", ";height:", ";}&.stackend-user-image-medium{width:", ";height:", ";}&.stackend-user-image-large{width:", ";height:", ";}", ";}"], function (props) {
  return props.theme.shopifyBorderRadius;
}, _styledComponentVariables.default.profileImageSize.tiny, _styledComponentVariables.default.profileImageSize.tiny, _styledComponentVariables.default.profileImageSize.small, _styledComponentVariables.default.profileImageSize.small, _styledComponentVariables.default.profileImageSize.medium, _styledComponentVariables.default.profileImageSize.medium, _styledComponentVariables.default.profileImageSize.large, _styledComponentVariables.default.profileImageSize.large, function (props) {
  return !!props.theme.StyledUserImage && props.theme.StyledUserImage(props);
});
exports.StyledUserImage = StyledUserImage;
var StyledUserLink = (0, _styledComponents.default)(_reactRouterDom.Link).withConfig({
  displayName: "UserImagestyle__StyledUserLink",
  componentId: "sc-2s2bhp-1"
})([""]);
exports.StyledUserLink = StyledUserLink;