"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AuthorUserDataWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _AuthorName = _interopRequireDefault(require("./AuthorName"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var AuthorUserDataWrapper = _styledComponents.default.div.attrs({
  className: 'stackend-author-user-data-wrapper'
}).withConfig({
  displayName: "AuthorUserData__AuthorUserDataWrapper",
  componentId: "sc-1t0gk6c-0"
})([".stackend &{display:grid;grid-template-columns:1fr;grid-template-rows:auto;grid-row-gap:10px;", "{font-size:16px;font-weight:700;line-height:16px;text-align:left;}.stackend-author-user-data-ai-flag{display:block;height:22px;width:auto;background-repeat:no-repeat;background-size:contain;background-position:left;background-image:url(", ");}.stackend-comment-created-date{display:grid;align-items:end;justify-items:end;font-size:10px;font-weight:500;line-height:16px;text-align:left;}}"], _AuthorName.default, require('../../ui/icons/img/ai-black-flag.svg'));
exports.AuthorUserDataWrapper = AuthorUserDataWrapper;