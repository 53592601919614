"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EmbedMediaModal = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var EmbedMediaModal = _styledComponents.default.form.attrs(function (props) {
  return {
    className: 'stackend-embed-media'
  };
}).withConfig({
  displayName: "EmbedMediaModalstyle__EmbedMediaModal",
  componentId: "sc-1qid9n3-0"
})([".stackend &{display:flex;flex-direction:column;gap:1em;input{width:100%;}}"]);
exports.EmbedMediaModal = EmbedMediaModal;