"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCurrentProductComment = getCurrentProductComment;
exports.hasProduct = hasProduct;
exports.liveEventActiveFirstComparator = liveEventActiveFirstComparator;
exports.liveEventNameComparator = liveEventNameComparator;
var _commentReducer = require("@stackend/api/comments/commentReducer");
var _comments = require("@stackend/api/comments");
var _stackend = require("@stackend/api/stackend");
var _live = require("@stackend/api/live");
var _LIVE_EVENT_STATE_SOR;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
/**
 * Has the comment a product tag?
 * @param comment
 * @returns {boolean}
 */
function hasProduct(comment) {
  if (!comment) {
    return false;
  }
  // FIXME: Improve this
  var body = comment.body;
  return body.indexOf('data-stackend-type="stackend-shop-product"') !== -1;
}

/**
 * Get the latest comment with a product
 * @param comments
 * @returns {Comment|null}
 */
function getCurrentProductComment(comments) {
  var bestMatch = null;
  if (comments !== null && comments !== void 0 && comments.entries) {
    for (var i = comments.entries.length; i--; i > 0) {
      var c = comments.entries[i];
      if (c && c.id === c.parentId && hasProduct(c)) {
        if (bestMatch === null || c.createdDate > bestMatch.createdDate) {
          bestMatch = c;
        }
      }
    }
  }
  return bestMatch;
}

/**
 * Sort live events. Enabled and active first
 * @param m1
 * @param m2
 * @returns {number}
 */
function liveEventActiveFirstComparator(m1, m2) {
  if (m1 === m2 || m1.id === m2.id) {
    return 0;
  }
  var e1 = m1.enabled ? 1 : 0;
  var e2 = m2.enabled ? 1 : 0;
  var e = e2 - e1;
  if (e !== 0) {
    return e;
  }
  var s1 = m1.settings;
  var s2 = m2.settings;
  var st1 = LIVE_EVENT_STATE_SORT_ORDER[s1.state || _live.LiveEventState.SCHEDULED];
  var st2 = LIVE_EVENT_STATE_SORT_ORDER[s2.state || _live.LiveEventState.SCHEDULED];
  var x = st1 - st2;
  if (x !== 0) {
    return x;
  }
  return liveEventNameComparator(m1, m2);
}

/**
 * Compare live events by name
 * @param m1
 * @param m2
 * @returns {number|*}
 */
function liveEventNameComparator(m1, m2) {
  if (m1 === m2 || m1.id === m2.id) {
    return 0;
  }
  var n1 = m1.name || '';
  var n2 = m1.name || '';
  var x = n1.localeCompare(n2);
  if (x !== 0) {
    return x;
  }
  return m1.id - m2.id;
}
var LIVE_EVENT_STATE_SORT_ORDER = (_LIVE_EVENT_STATE_SOR = {}, _defineProperty(_LIVE_EVENT_STATE_SOR, _live.LiveEventState.ACTIVE, 0), _defineProperty(_LIVE_EVENT_STATE_SOR, _live.LiveEventState.SCHEDULED, 1), _defineProperty(_LIVE_EVENT_STATE_SOR, _live.LiveEventState.COMPLETED, 2), _LIVE_EVENT_STATE_SOR);