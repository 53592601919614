"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ScrolledView = exports.ScrolledElements = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
var _HideScrollbar = require("../styled-components/mixins/HideScrollbar");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var ScrolledView = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-scrolled-view', props.className)
  };
}).withConfig({
  displayName: "ScrolledViewstyle__ScrolledView",
  componentId: "sc-1fzb3nv-0"
})([".stackend &{height:100%;", ";}"], _HideScrollbar.HideScrollbar);
exports.ScrolledView = ScrolledView;
var ScrolledElements = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-scrolled-elements', props.className)
  };
}).withConfig({
  displayName: "ScrolledViewstyle__ScrolledElements",
  componentId: "sc-1fzb3nv-1"
})([".stackend &{display:flex;flex-direction:column;justify-content:flex-end;padding-top:4em;min-height:100%;}"]);
exports.ScrolledElements = ScrolledElements;