"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ScrollShadow = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var sc = _interopRequireWildcard(require("../style-common/styled-component-variables.js"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var ScrollShadow = _styledComponents.default.span.withConfig({
  displayName: "ScrollShadow",
  componentId: "sc-1iigpao-0"
})([".stackend &{position:absolute;z-index:1000;height:100%;width:10px;top:0;left:", ";right:", ";box-shadow:", ";cursor:pointer;", "{right:-10px;}&:after{content:'';position:absolute;cursor:pointer;display:inline-block;vertical-align:top;transform:", ";top:calc(50% - 5px);right:0px;width:10px;height:10px;border-right:2px solid ", ";border-bottom:2px solid ", ";}", ";}"], function (props) {
  return props.prev ? '-10px' : 'inherit';
}, function (props) {
  return props.prev ? 'inhert' : '-10px';
}, function (props) {
  return props.prev ? '7px 1px 5px -1px rgba(0,0,0,0.2)' : '-7px 1px 5px -1px rgba(0,0,0,0.2)';
}, sc.Mobile, function (props) {
  return props.prev ? 'rotate(135deg)' : 'rotate(-45deg)';
}, sc.AccentColor, sc.AccentColor, function (props) {
  return !!props.theme.ScrollShadow && props.theme.ScrollShadow(props);
});
exports.ScrollShadow = ScrollShadow;
var _default = ScrollShadow;
exports.default = _default;