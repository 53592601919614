"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CloseLink = exports.CloseAndBackButton = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
var _media = _interopRequireDefault(require("@stackend/react/style-common/media"));
var _reactRouterDom = require("react-router-dom");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var CloseAndBackButton = _styledComponents.default.button.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-button')
  };
}).withConfig({
  displayName: "CloseAndBackButtonstyle__CloseAndBackButton",
  componentId: "sc-1sjkloc-0"
})([".stackend &{&.stackend-close-button,&.stackend-back-button{background:none;font-weight:bold;border:none;padding:0;height:auto;margin:0;color:", ";vertical-align:middle;font-size:3rem;", "{font-size:2rem;}&:hover,&:active{background:none;border:none;color:", ";}}}"], function (props) {
  return props.theme.color || 'white';
}, _media.default.overTablet, function (props) {
  return props.theme.color || 'white';
});
exports.CloseAndBackButton = CloseAndBackButton;
var CloseLink = CloseAndBackButton.withComponent(_reactRouterDom.Link);
exports.CloseLink = CloseLink;