"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = insertProduct;
var _shop = require("@stackend/api/shop");
var _stackend = require("@stackend/api/stackend");
var _getStackendProductBlotValue = require("./getStackendProductBlotValue");
var _ProductModule = require("@stackend/react/cms/page-content/ProductModule");
function insertProduct(Quill, editor, community, product, layout) {
  var value = (0, _getStackendProductBlotValue.getStackendProductBlotValue)(community.id, product, layout);
  var range = editor.getSelection(true);
  editor.insertEmbed(range.index, 'product', value, Quill.sources.USER);
  editor.setSelection(range.index + 1, Quill.sources.SILENT);
}