"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createProductHtml = createProductHtml;
var _modules = require("@stackend/api/stackend/modules");
var _shop = require("@stackend/api/shop");
var _getStackendProductBlotValue = require("../../../editor/quill/getStackendProductBlotValue.js");
/**
 * Create the html for a product
 * @param communityId
 * @param product
 * @param layout
 * @param value
 * @param node optional element
 * @returns {null|HTMLDivElement}
 */
function createProductHtml(_ref) {
  var communityId = _ref.communityId,
    product = _ref.product,
    _ref$layout = _ref.layout,
    layout = _ref$layout === void 0 ? 'compact' : _ref$layout,
    value = _ref.value,
    node = _ref.node;
  // FIXME: duplicates StackendProductBlot
  if (!communityId || !product && !value) {
    return null;
  }
  var p = node ? node : document.createElement('div');
  var handle = product ? product.handle : value.handle;
  var title = product ? product.title : value.title;
  p.setAttribute('data-stackend-type', _modules.ModuleType.SHOP_PRODUCT);
  p.setAttribute('data-stackend-id', communityId);
  p.setAttribute('data-stackend-handle', handle);
  p.setAttribute('data-stackend-layout', layout);
  var placeholder = p.ownerDocument.createElement('div');
  placeholder.setAttribute('contenteditable', 'false');
  placeholder.setAttribute('class', 'stackend-product-placeholder stackend-product-placeholder-' + layout);
  placeholder.setAttribute('contenteditable', 'false');
  placeholder.setAttribute('class', 'stackend-product-placeholder stackend-product-placeholder-' + layout);
  var imageUrl = null;
  if (product) {
    var i = (0, _shop.getFirstImage)(product);
    if (i) {
      imageUrl = i.url || i.transformedSrc; // For backwards compatibility
    }
  } else {
    imageUrl = value.image;
  }
  if (imageUrl) {
    var img = p.ownerDocument.createElement('img');
    img.setAttribute('src', imageUrl);
    placeholder.appendChild(img);
  }
  var textDiv = p.ownerDocument.createElement('div');
  textDiv.setAttribute('class', 'stackend-product-placeholder-title');
  var text = p.ownerDocument.createTextNode(title);
  textDiv.appendChild(text);
  placeholder.appendChild(textDiv);
  p.appendChild(placeholder);
  return p;
}