"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createReduxStore = createReduxStore;
exports.getOrCreateReduxStore = getOrCreateReduxStore;
exports.getReduxStore = getReduxStore;
exports.hasPreloadedState = hasPreloadedState;
var _redux = require("redux");
var _reduxThunk = _interopRequireDefault(require("redux-thunk"));
var _crashReporter = _interopRequireDefault(require("./crashReporter.js"));
var _ClientSideApi = require("./ClientSideApi.js");
var _api = require("@stackend/api/api");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
// Covers the stackend admin case
var CLIENT_SIDE_REDUX_STORE = null;

/**
 * Create a redux store with the specified reducers
 * @param reducers
 * @returns {*}
 */
function createReduxStore(reducers) {
  var preloadedState = window.__PRELOADED_STATE__;
  var devToolOpts = global.isProd ? {} : {
    trace: true,
    traceLimit: 25
  };
  var store = (0, _redux.createStore)(reducers, preloadedState, (0, _redux.compose)((0, _redux.applyMiddleware)(_reduxThunk.default, _crashReporter.default), window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__(devToolOpts) : function (f) {
    return f;
  }
  /* Old redux window.devToolsExtension ? window.devToolsExtension() : f => f*/));

  // Add the store to the api
  var api = (0, _ClientSideApi.getClientSideApi)();
  if (api) {
    api.reduxStore = store;
  }
  if ((0, _api.isRunningInBrowser)()) {
    CLIENT_SIDE_REDUX_STORE = store;
  }
  return store;
}

/**
 * Check if there is a preloaded state
 * @returns {boolean}
 */
function hasPreloadedState() {
  return _typeof(window.__PRELOADED_STATE__) === 'object';
}

/**
 * Get the store, or create it, if missing
 */
function getOrCreateReduxStore(reducers) {
  var store = getReduxStore();
  if (store) {
    return store;
  }
  return createReduxStore(reducers);
}

/**
 * Get the redux store, or null if not set up
 * @returns {Store|null}
 */
function getReduxStore() {
  // Module case
  var api = (0, _ClientSideApi.getClientSideApi)();
  if (api) {
    return api.reduxStore;
  }

  // Admin case
  if ((0, _api.isRunningInBrowser)()) {
    return CLIENT_SIDE_REDUX_STORE;
  }
  return null;
}