"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProfileMenuBasket = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _Basket = require("@stackend/react/shop/Basket.style");
var _Shop = require("@stackend/react/shop/Shop.style");
var _NumberEntry = require("@stackend/react/ui/NumberEntry.style");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
/* This is a fix until styled components supports container querries.
 * Basicaly the mobile styling when visible in the menu
 */
var ProfileMenuBasket = (0, _styledComponents.css)(["", "{", "{grid-template-columns:33% min-content auto auto min-content;grid-template-rows:repeat(2,auto);grid-template-areas:'image title title title remove' 'image quantity quantity price price';align-items:center;justify-items:start;grid-gap:0.5em;font-size:0.9em;.stackend-product-title{align-self:start;", "{margin:0;max-height:4.5em;overflow:hidden;text-overflow:ellipsis;line-clamp:3;-webkit-line-clamp:3;-webkit-box-orient:vertical;}}.stackend-remove-product{justify-self:end;align-self:start;margin:0;padding-top:0;padding-right:0;}a.stackend-product-image-link{align-self:start;}", ",", "{align-self:end;}", " button{margin-bottom:0;margin-top:0;padding-bottom:0;padding-top:0;}", " button:first-child{margin-left:0;}", "{justify-self:end;margin-bottom:1em;}}}"], _Basket.BasketList, _Basket.BasketItem, _Shop.Title, _NumberEntry.NumberEntry, _Shop.Price, _NumberEntry.NumberEntry, _NumberEntry.NumberEntry, _Shop.Price);
exports.ProfileMenuBasket = ProfileMenuBasket;