"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.YOUTUBE_VIDEO_PATTERN = exports.YOUTUBE_CHANNEL_URL_PATTERN_STRING = exports.YOUTUBE_CHANNEL_PATTERN = exports.YOUTUBE_CHANNEL_PATHNAME_PATTERN = exports.PlayerState = void 0;
exports.getChannelId = getChannelId;
exports.getChannelUrl = getChannelUrl;
exports.getVideo = getVideo;
exports.getVideoId = getVideoId;
exports.getVideoUrl = getVideoUrl;
exports.isYoutubeDomain = isYoutubeDomain;
exports.loadYouTubeIframeApi = loadYouTubeIframeApi;
exports.searchChannelVideos = searchChannelVideos;
var _api = require("@stackend/api/api");
/**
 * Search for videos from a channel
 * @param channelId
 * @param q
 * @param live
 * @returns {*}
 */
function searchChannelVideos(_ref) {
  var channelId = _ref.channelId,
    q = _ref.q,
    live = _ref.live;
  return (0, _api.getJson)({
    url: '/youtube/search',
    parameters: arguments
  });
}
/**
 * Get a video by id
 * @param videoId
 * @returns {*}
 */
function getVideo(_ref2) {
  var videoId = _ref2.videoId;
  return (0, _api.getJson)({
    url: '/youtube/get',
    parameters: arguments
  });
}

/**
 * Given a video id, construct the video url
 * @param videoId
 * @returns {string|null}
 */
function getVideoUrl(videoId) {
  if (!videoId) {
    return null;
  }
  return 'https://www.youtube.com/watch?v=' + encodeURIComponent(videoId);
}

/**
 * Given a channel, id, construct the channel url
 * @param channelId
 * @returns {string|null}
 */
function getChannelUrl(channelId) {
  if (!channelId) {
    return null;
  }
  return 'https://www.youtube.com/channel/' + channelId;
}

/**
 * Match a youtube channel path name.
 * $1 is the channel id
 * @type {RegExp}
 */
var YOUTUBE_CHANNEL_PATHNAME_PATTERN = /^\/channel\/([a-zA-Z0-9_-]+)(?:\/.*)?$/;
exports.YOUTUBE_CHANNEL_PATHNAME_PATTERN = YOUTUBE_CHANNEL_PATHNAME_PATTERN;
var YOUTUBE_CHANNEL_URL_PATTERN_STRING = '^https://www\\.youtube\\.com/channel/[a-zA-Z0-9_-]+(?:/.*)?$';

/**
 * Given an url, get the youtube channel id
 * @param url
 * @returns {null|*}
 */
exports.YOUTUBE_CHANNEL_URL_PATTERN_STRING = YOUTUBE_CHANNEL_URL_PATTERN_STRING;
function getChannelId(url) {
  if (!url) {
    return null;
  }
  try {
    var u = new URL(url);
    if (u && isYoutubeDomain(u)) {
      var v = YOUTUBE_CHANNEL_PATHNAME_PATTERN.exec(u.pathname);
      return v && v.length === 2 ? v[1] : null;
    }
  } catch (e) {
    // ignored
  }
  return null;
}

/**
 * Given a youtube
 * @param url
 * @returns {string|null}
 */
function getVideoId(url) {
  if (!url) {
    return null;
  }
  try {
    var u = new URL(url);
    if (u && isYoutubeDomain(u)) {
      var v = u.searchParams.get('v');
      if (v) {
        return v;
      }
      var x = YOUTUBE_VIDEO_PATTERN.exec(url);
      return x ? x[1] : null;
    }
  } catch (e) {
    // ignored
  }
  return null;
}
function isYoutubeDomain(url) {
  if (!url) {
    return false;
  }
  return url.host === 'www.youtube.com' || url.host === 'youtube.com' || url.host === 'youtu.be';
}

/**
 * Match a youtube video url: https://www.youtube.com/watch?v=hT7x1NvGf5k&ab_channel=ClownC0re
 * @type {RegExp}
 */
var YOUTUBE_VIDEO_PATTERN = /^https?:\/\/(?:www\.youtube\.com\/watch\?.*v=|youtu\.be\/)([a-zA-Z0-9_]+).*$/;

/**
 * Match a youtube channel url.
 * $1 is the channel id
 * @type {RegExp}
 */
exports.YOUTUBE_VIDEO_PATTERN = YOUTUBE_VIDEO_PATTERN;
var YOUTUBE_CHANNEL_PATTERN = /^https?:\/\/www\.youtube\.com\/channel\/([a-zA-Z0-9_]+)(?:\/.*)?$/;

/**
 * Load the iframe api (browser only)
 * @param onYouTubeIframeAPIReady Invoked when the api is ready
 */
exports.YOUTUBE_CHANNEL_PATTERN = YOUTUBE_CHANNEL_PATTERN;
function loadYouTubeIframeApi(onYouTubeIframeAPIReady) {
  if (!window.YT) {
    var tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  } else {
    onYouTubeIframeAPIReady();
  }
}

/**
 * Youtube video player state
 * @type {{BUFFERING: number, PAUSED: number, UNSTARTED: number, VIDEO_CUED: number, ENDED: number, PLAYING: number}}
 */
var PlayerState = {
  UNSTARTED: -1,
  ENDED: 0,
  PLAYING: 1,
  PAUSED: 2,
  BUFFERING: 3,
  VIDEO_CUED: 5
};
exports.PlayerState = PlayerState;