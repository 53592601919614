"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AccordionComponent = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var AccordionComponent = _styledComponents.default.div.withConfig({
  displayName: "Accordionstyle__AccordionComponent",
  componentId: "sc-7m3oag-0"
})([".stackend &{.stackend-accordion-header{cursor:pointer;background:transparent;border:none;margin:0;padding:0;display:flex;align-items:center;&:hover{background:transparent;}h2{margin:0;}}}"]);
exports.AccordionComponent = AccordionComponent;