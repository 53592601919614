"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Menu = exports.MENU_COLLAPSE_SCREEN_WIDTH = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var admin = _interopRequireWildcard(require("../styled-components/Stackend/admin-vars.js"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * Screen width where the menu colapses into a hamburger.
 * Same as media.smallerScreen
 **/
var MENU_COLLAPSE_SCREEN_WIDTH = 1300;
exports.MENU_COLLAPSE_SCREEN_WIDTH = MENU_COLLAPSE_SCREEN_WIDTH;
var Menu = _styledComponents.default.div.withConfig({
  displayName: "Menustyle__Menu",
  componentId: "sc-1k7apqi-0"
})([".stackend &{position:fixed;top:", ";left:0px;padding:20px;width:", ";color:", ";background:#ffffff;z-index:", ";max-height:calc(100vh - ", ");overflow-y:auto;overflow-x:hidden;box-sizing:border-box;border-right:1px solid ", ";margin-top:20px;padding-top:0;", "{top:0;max-height:unset;margin-left:0;margin-top:0;padding:20px;z-index:", ";height:100vh;width:100vw;height:-webkit-fill-available;}.close{display:none;text-align:right;", "{display:block;}.close-button{border:none;font-size:24px;line-height:1em;height:1em;padding:0;}}.items{.item{", ";display:flex;align-items:center;margin-bottom:1.5em;font-size:20px;.material-icons{width:20px;margin-right:20px;line-height:1.3em;}.text{font-weight:bold;font-size:16px;flex:1 1 auto;}.notifications{margin-left:10px;color:", ";font-size:14px;}}a.item.modules.selected-alt{margin-bottom:10px;}}}"], admin.topMenuHeight, admin.communityMenuInnerWidth, admin.colors.text, admin.zIndex.communityMenu, admin.topMenuHeight, admin.colors.textDiscrete, admin.media.smallerScreen, admin.zIndex.communityMenuMobile, admin.media.smallerScreen, admin.focusStyleMixin, admin.colors.notification);
exports.Menu = Menu;