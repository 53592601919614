"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = analyticsReducer;
var _immutabilityHelper = _interopRequireDefault(require("immutability-helper"));
var _analyticsActions = require("./analyticsActions.js");
var _reactRouterRedux = require("react-router-redux");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var defaultState = {
  settingsAnalytics: false,
  analyticsSet: false,
  analyticsTimingSet: false,
  pageView: null,
  event: null,
  firstPageView: true,
  pageTracked: false
};
function analyticsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case _reactRouterRedux.LOCATION_CHANGE:
      return (0, _immutabilityHelper.default)(state, {
        pageTracked: {
          $set: false
        }
      });
    case _analyticsActions.SET_ANALYTICS_PAGEVIEW:
      return (0, _immutabilityHelper.default)(state, {
        pageView: {
          $set: action.data
        },
        pageTracked: {
          $set: true
        }
      });
    case _analyticsActions.SET_ANALYTICS_EVENT:
      return (0, _immutabilityHelper.default)(state, {
        event: {
          $set: action.data
        }
      });
    case _analyticsActions.ANALYTICS_TIMING_SET:
      return (0, _immutabilityHelper.default)(state, {
        analyticsTimingSet: {
          $set: true
        }
      });
    case _analyticsActions.FIRST_PAGEVIEW:
      return (0, _immutabilityHelper.default)(state, {
        firstPageView: {
          $set: false
        }
      });
    default:
      return state;
  }
}