"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.GroupInfo = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var sc = _interopRequireWildcard(require("../../style-common/styled-component-variables.js"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var GroupInfo = _styledComponents.default.div.withConfig({
  displayName: "GroupInfo",
  componentId: "sc-17u0l8w-0"
})([".stackend &{position:relative;display:", ";", "{width:", ";display:", ";vertical-align:top;float:left;}", "{display:", ";width:", ";}", ";}"], function (props) {
  return props.desktop ? 'block' : props.mobile ? 'none' : 'block';
}, sc.TabletAndMobile, function (props) {
  return props.desktop ? 'calc(100% - 210px)' : props.mobile ? '100%' : 'calc(100% - 210px)';
}, function (props) {
  return props.desktop ? 'block' : props.mobile ? 'none' : 'inline-block';
}, sc.Mobile, function (props) {
  return props.mobile ? 'block' : 'none';
}, function (props) {
  return props.mobile && '100%';
}, function (props) {
  return !!props.theme.GroupInfo && props.theme.GroupInfo(props);
});
exports.GroupInfo = GroupInfo;
var _default = GroupInfo;
exports.default = _default;