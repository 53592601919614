"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VBox = exports.HBox = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _react = _interopRequireDefault(require("react"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * A horizontal box with item padding
 */
var HBox = _styledComponents.default.div.attrs({
  className: 'stackend-horizontal-box'
}).withConfig({
  displayName: "Boxstyle__HBox",
  componentId: "sc-vsd3me-0"
})([".stackend &{display:flex;gap:1em;align-items:center;justify-content:space-between;flex-wrap:wrap;}"]);

/**
 * A vertical box with item padding
 */
exports.HBox = HBox;
var VBox = _styledComponents.default.div.attrs({
  className: 'stackend-vertical-box'
}).withConfig({
  displayName: "Boxstyle__VBox",
  componentId: "sc-vsd3me-1"
})([".stackend &{display:flex;flex-direction:column;gap:1em;align-items:center;}"]);
exports.VBox = VBox;