"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.XcapResponsiveSubsite = void 0;
var _styledComponents = require("styled-components");
var XcapResponsiveSubsite = function XcapResponsiveSubsite() {
  return (0, _styledComponents.css)([".xcap-responsive-subsite{position:relative;height:0;width:100%;iframe{position:absolute;height:100%;width:100%;}}"]);
};
exports.XcapResponsiveSubsite = XcapResponsiveSubsite;
var _default = XcapResponsiveSubsite;
exports.default = _default;