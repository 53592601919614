"use strict";

/****** CONFIG PART *****************/
global.contextPath = '';

/**
 * Enforce links to the node server to use https.
 * Useful when apache/nginex is used as proxy
 */
global.enforceHttps = true;

// FIXME: Remove the following. Should be loaded from backend in Main.jsx
global.__xcapDeployProfile = 'stackend';
global.xcapQNAJsonApiUrl = '';
global.clientSideApiServer = 'https://api.stackend.com';
global.recaptchaSiteKey = '6LeujQMTAAAAAPtMKp5KuWv8UwT75FioWSgfFiUv';
// pre 13 jun 2022 global.gaKey = 'UA-115765407-1';
global.gaKey = 'GTM-KDC5D5D';
global.xcapModuleSettings = {
  server: global.clientSideApiServer,
  contextPath: ''
};
global.__xcap_api_config = {
  'media.forum.media-upload-server': global.clientSideApiServer,
  'media.groups.media-upload-server': global.clientSideApiServer,
  'api-url': '',
  'media.members.media-upload-server': global.clientSideApiServer
};
global.nodeServer = 'https://stackend.com';

/********** END OF CONFIG PART ***************/