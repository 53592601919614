"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.IntroP = exports.Intro = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var sc = _interopRequireWildcard(require("../style-common/styled-component-variables.js"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var Intro = _styledComponents.default.div.withConfig({
  displayName: "Intro",
  componentId: "sc-h22yrl-0"
})([".stackend &{position:relative;text-align:left;width:28%;display:inline-block;vertical-align:top;", "{text-align:center;width:100%;padding:0;margin:", ";max-width:100%;display:block;}", "{display:", ";}", ";}"], sc.TabletAndMobile, function (props) {
  return props.tabbed ? '0 auto 80px' : '0 auto';
}, sc.Mobile, function (props) {
  return props.desktopOnly && 'none';
}, function (props) {
  return !!props.theme.Intro && props.theme.Intro(props);
});
exports.Intro = Intro;
var IntroP = _styledComponents.default.p.withConfig({
  displayName: "Intro__IntroP",
  componentId: "sc-h22yrl-1"
})([".stackend &{font-size:", " !important;font-family:", ";-webkit-margin-after:20px;", ";}"], sc.fontSizeBodyText, sc.fontNormal, function (props) {
  return !!props.theme.IntroP && props.theme.IntroP(props);
});
exports.IntroP = IntroP;
var _default = Intro;
exports.default = _default;