"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EMPTY_TRANSLATIONS = exports.DEFAULT_LANGUAGE = void 0;
exports.getCountry = getCountry;
exports.getLanguageCode = getLanguageCode;
exports.getLanguageName = getLanguageName;
exports.getSupportedLanguage = getSupportedLanguage;
exports.getSupportedLanguageNames = getSupportedLanguageNames;
exports.getSupportedLocales = getSupportedLocales;
exports.getTranslations = getTranslations;
exports.isLanguageSupported = isLanguageSupported;
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
//import path from 'path';
//import { addLocaleData } from 'react-intl';
//import en from 'react-intl/locale-data/en';
//import sv from 'react-intl/locale-data/sv';
//import de from 'react-intl/locale-data/de';
//import fi from 'react-intl/locale-data/fi';
//import { readFileSync } from 'fs';
//addLocaleData([...en, ...de, ...fi, ...sv]);
// NOTE: When updating this file, also add corresponding tiny-mce translations in react/editor/tiny-mce-langs
var SUPPORTED_LANGUAGES = {
  de: 'German',
  en: 'English',
  fi: 'Finnish',
  sv: 'Swedish'
};
var DEFAULT_LANGUAGE = 'en';
exports.DEFAULT_LANGUAGE = DEFAULT_LANGUAGE;
var AVAILABLE_TRANSLATIONS = {
  de: 'de-DE',
  en: 'en-US',
  fi: 'fi-FI',
  sv: 'sv-SE'
};
var EMPTY_TRANSLATIONS = {
  lang: DEFAULT_LANGUAGE,
  messages: {},
  localeData: {}
};
exports.EMPTY_TRANSLATIONS = EMPTY_TRANSLATIONS;
function getCountry(locale) {
  if (!locale) {
    return null;
  }
  var v = locale.split(/[-_]/);
  if (v.length === 1) {
    var x = AVAILABLE_TRANSLATIONS[v[0]];
    if (x) {
      return getCountry(x);
    }
  } else if (v.length === 2) {
    return v[1].toLocaleUpperCase();
  }
  return null;
}

/**
 * Get the languages supported by frontend
 */
function getSupportedLocales() {
  return Object.keys(SUPPORTED_LANGUAGES);
}

/**
 * Get the supported language names.
 * Map from locale to language
 */
function getSupportedLanguageNames() {
  return SUPPORTED_LANGUAGES;
}

/**
 * Given a language code, get the name
 * @param languageCode
 * @returns {*}
 */
function getLanguageName(languageCode) {
  if (languageCode) {
    return SUPPORTED_LANGUAGES[languageCode];
  }
  return null;
}

/**
 * Check if a language is supported
 * @param locale
 */
function isLanguageSupported(locale) {
  if (locale) {
    var c = locale.toLocaleLowerCase();
    if (typeof SUPPORTED_LANGUAGES[c] !== 'undefined') {
      return true;
    }
    c = getLanguageCode(locale);
    if (c != null && typeof SUPPORTED_LANGUAGES[c] !== 'undefined') {
      return true;
    }
  }
  return false;
}

/**
 * Get a supported language, or fall back to the default language
 * @param locale
 * @returns {string}
 */
function getSupportedLanguage(locale) {
  if (locale) {
    var c = locale.toLocaleLowerCase();
    if (typeof SUPPORTED_LANGUAGES[c] !== 'undefined') {
      return c;
    }
    c = getLanguageCode(locale);
    if (c != null && typeof SUPPORTED_LANGUAGES[c] !== 'undefined') {
      return c;
    }
  }
  return DEFAULT_LANGUAGE;
}

/**
 * Given a Locale, get the country code, if present.
 * @param locale
 * @returns {*}
 */
function getLanguageCode(locale) {
  if (locale) {
    var i = locale.indexOf('_');
    if (i !== -1) {
      return locale.substring(0, i).toLowerCase();
    }
    return locale.toLowerCase();
  }
  return null;
}
// Translation cache
var translations = {};

/**
 * Get translations for a given language
 * @param locale
 * @param forceReload
 */
function getTranslations(locale, forceReload) {
  var lang = getSupportedLanguage(locale);
  if (typeof translations[lang] === 'undefined' || typeof forceReload !== 'undefined' && forceReload) {
    try {
      translations[lang] = loadTranslations(lang);
    } catch (e) {
      console.error('Error loading translations for ' + locale, e);
      return EMPTY_TRANSLATIONS;
    }
  }
  return translations[lang];
}
function loadTranslations(lang) {
  var t = {
    lang: lang,
    messages: {},
    localeData: {}
  };

  // Messages from this project
  t.messages = require("./../../translation/" + lang + '.json');

  // Messages from @stackend/react
  // FIXME: Hack
  var locale = lang;
  if (lang.indexOf('-') === -1) {
    locale = AVAILABLE_TRANSLATIONS[lang];
  }
  var m = require('@stackend/react/i18n/' + locale + '.js');
  if (m) {
    t.messages = _extends(t.messages, m.default);
  }
  translations[lang] = t;
  return t;
}