"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.decodeHashParameters = decodeHashParameters;
exports.encodeHashParameters = encodeHashParameters;
/**
 * Encode parameters in a hash url. Supports array values
 *
 * {
 *   param1: [ "value1", "value2" ],
 *   param2: "value3"
 * }
 * Encodes to
 *
 * ;param1=value1;param1=value2;param2=value3
 *
 * @param params
 * @returns {string}
 */
function encodeHashParameters(params) {
  if (!params) {
    return '';
  }
  var l = '';
  Object.keys(params).forEach(function (k) {
    var v = params[k];
    if (v instanceof Array) {
      v.forEach(function (x) {
        l += ';' + encodeURIComponent(k) + '=' + encodeURIComponent(x);
      });
    } else {
      l += ';' + encodeURIComponent(name) + '=' + encodeURIComponent(v);
    }
  });
  return encodeURIComponent(l); // Ensures ; and = are encoded correctly
}

/**
 * Decode hash paramsters
 * @param params
 * @returns {{}}
 */
function decodeHashParameters(params) {
  if (!params) {
    return {};
  }
  var x = decodeURIComponent(params);
  if (!x) {
    return {};
  }
  var values = x.split(';');
  var r = {};
  values.forEach(function (v) {
    if (v === '') {
      return;
    }
    var y = v.split('=');
    if (y.length !== 2) {
      return;
    }
    var name = decodeURIComponent(y[0]);
    var value = decodeURIComponent(y[1]);
    if (typeof params[name] === 'undefined') {
      params[name] = value;
    } else if (params[name] instanceof Array) {
      params[name].push(value);
    } else {
      params[name] = [params[name], value];
    }
  });
}