"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.GroupHeader = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var GroupHeader = _styledComponents.default.div.attrs({
  className: 'stackend-group-header'
}).withConfig({
  displayName: "GroupHeader",
  componentId: "sc-1g6izjp-0"
})([".stackend &{width:100%;height:100%;border-radius:50%;overflow:hidden;background-image:", ";background-repeat:no-repeat;background-position:center;background-size:cover;background-color:#fff;img{width:700px;display:inline-block;margin-left:-250px;vertical-align:top;}", ";}"], function (props) {
  return !!props.background ? "url(".concat(props.background, ")") : "url(".concat(require('./img/header-image-placeholder.png'), ")");
}, function (props) {
  return !!props.theme.GroupHeader && props.theme.GroupHeader(props);
});
exports.GroupHeader = GroupHeader;
var _default = GroupHeader;
exports.default = _default;