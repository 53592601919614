"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ModuleSideMenu = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var admin = _interopRequireWildcard(require("../styled-components/Stackend/admin-vars.js"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var ModuleSideMenu = _styledComponents.default.div.withConfig({
  displayName: "ModuleSideMenustyle__ModuleSideMenu",
  componentId: "sc-1so2vwg-0"
})([".stackend &{margin:0;width:100%;padding:0 0 1.5em 38px;.module-item{display:flex;flex-direction:row;align-items:center;justify-content:space-between;flex-wrap:nowrap;margin:0.75em 0;a{", ";padding:2px;flex:1 1 auto;}button{font-size:0.75em;padding:0.25em 0.5em;}}.side-menu-closed &{", "{padding:0;.quick-change .stackend_accordion-header{justify-content:center;&:hover{.sidemenu-item-text{opacity:1;top:5px;left:65px;transform:scale(1);color:", ";font-weight:normal;}}}}}}"], admin.focusStyleMixin, admin.media.overTablet, admin.colors.accent);
exports.ModuleSideMenu = ModuleSideMenu;