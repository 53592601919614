"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reactSelect = void 0;
var _styledComponents = require("styled-components");
var _StackendTheme = require("@stackend/react/theme/StackendTheme");
var _ComponentType = _interopRequireDefault(require("@stackend/react/theme/ComponentType"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// FIXME: Duplicats StackendReactSelect
var reactSelect = (0, _styledComponents.css)([".stackend-react-select{.stackend-react-select__control{align-items:center;cursor:default;display:flex;flex-wrap:wrap;justify-content:space-between;min-height:38px;outline:0 !important;position:relative;transition:all 100ms;box-sizing:border-box;padding:0 ", ";border-radius:", ";&:focus-within{outline:", " solid ", ";}&.stackend-react-select__control--is-focused{border:", " solid ", ";border-radius:", ";background-color:", ";outline:none;box-shadow:none;}.stackend-react-select__multi-value,.stackend-react-select__multi-value__label,.stackend-react-select__multi-value__remove,.stackend-react-select__input{display:inline-block;vertical-align:middle;input{box-shadow:none;}}.stackend-react-select__multi-value{padding-left:5px;padding-right:5px;margin-right:5px;}.stackend-react-select__multi-value__remove{cursor:pointer;svg{vertical-align:middle;}}.stackend-react-select__input{margin-left:10px;}.stackend-react-select__value-container{padding:2px;}.stackend-react-select__value-container > div:last-child{margin:0;padding:0;display:inline-block;}}}"], function (props) {
  return props.theme.margins.small;
}, function (props) {
  return props.theme.borderRadius;
}, function (props) {
  return props.theme.borderWidth;
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.SELECT, 'outlineColor');
}, function (props) {
  return props.theme.borderWidth;
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.SELECT, 'borderColor');
}, function (props) {
  return props.theme.borderRadius;
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.SELECT, 'backgroundColor');
});
exports.reactSelect = reactSelect;