"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.STACKEND_REACT_SELECT = void 0;
var _styledComponents = require("styled-components");
var _StackendTheme = require("@stackend/react/theme/StackendTheme");
var _ComponentType = _interopRequireDefault(require("@stackend/react/theme/ComponentType"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var STACKEND_REACT_SELECT = (0, _styledComponents.css)([".stackend-react-select{position:relative;box-sizing:border-box;.stackend-react-select__control{-webkit-box-align:center;align-items:center;border-radius:", ";border:", ";cursor:default;display:flex;flex-wrap:wrap;-webkit-box-pack:justify;justify-content:space-between;min-height:38px;position:relative;transition:all 100ms ease 0s;box-sizing:border-box;outline:0px !important;.stackend-react-select__input{display:inline-block;vertical-align:middle;input{box-shadow:none;}}.stackend-react-select__value-container{-webkit-box-align:center;align-items:center;display:flex;flex:1 1 0%;flex-wrap:wrap;padding:2px 8px;position:relative;overflow:hidden;box-sizing:border-box;}.stackend-react-select__indicators{-webkit-box-align:center;align-items:center;align-self:stretch;display:flex;flex-shrink:0;box-sizing:border-box;.stackend-react-select__dropdown-indicator{color:rgb(204,204,204);display:flex;padding:8px;transition:color 150ms ease 0s;box-sizing:border-box;}}}.stackend-react-select__menu{display:border-box;.stackend-react-select__menu-list{padding:8px;}}}"], function (props) {
  return props.theme.borderRadius;
}, function (props) {
  return (0, _StackendTheme.getComponentBorder)(props.theme, _ComponentType.default.TEXT_INPUT);
});
exports.STACKEND_REACT_SELECT = STACKEND_REACT_SELECT;
var _default = STACKEND_REACT_SELECT;
exports.default = _default;