"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Button = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Right = require("../Wrappers/Right.js");
var _Left = require("../Wrappers/Left.js");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var Button = _styledComponents.default.button.withConfig({
  displayName: "Button",
  componentId: "sc-1t37c2k-0"
})([".stackend &{", " &{margin:", " 0 0;float:right;}", " &{margin:", " 0 0;float:left;}", ";}"], _Right.Right, function (props) {
  return props.theme.margins.medium;
}, _Left.Left, function (props) {
  return props.theme.margins.medium;
}, function (props) {
  return !!props.theme.Button && props.theme.Button;
});
exports.Button = Button;
var _default = Button;
exports.default = _default;