"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.StatsContentBox = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Intro = _interopRequireDefault(require("../Intro.js"));
var sc = _interopRequireWildcard(require("../../style-common/styled-component-variables.js"));
var _StackendTheme = require("@stackend/react/theme/StackendTheme");
var _ComponentType = _interopRequireDefault(require("@stackend/react/theme/ComponentType"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var StatsContentBox = _styledComponents.default.div.attrs({
  className: 'stackend-stats-content-box'
}).withConfig({
  displayName: "StatsContentBox",
  componentId: "sc-jhz4f5-0"
})([".stackend &{border:solid ", " ", ";border-radius:", ";padding:", ";white-space:nowrap;align-items:center;text-align:center;display:flex;flex-direction:column;", "{padding:", ";}", " &{background:transparent;}", ";}"], function (props) {
  return props.theme.borderWidth;
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'borderColor');
}, function (props) {
  return props.theme.borderRadius;
}, function (props) {
  return props.theme.margins.medium;
}, sc.Mobile, function (props) {
  return props.theme.margins.small;
}, _Intro.default, function (props) {
  return !!props.theme.StatsContentBox && props.theme.StatsContentBox(props);
});
exports.StatsContentBox = StatsContentBox;
var _default = StatsContentBox;
exports.default = _default;