"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RsvpButtons = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _StackendTheme = require("@stackend/react/theme/StackendTheme");
var _ComponentType = _interopRequireDefault(require("@stackend/react/theme/ComponentType"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var RsvpButtons = _styledComponents.default.div.attrs({
  className: 'stackend-rsvp-buttons'
}).withConfig({
  displayName: "RsvpButtonsstyle__RsvpButtons",
  componentId: "sc-hygvy-0"
})([".stackend &{display:flex;align-items:center;gap:1em;margin:1em 0;button{padding:0.5em;cursor:pointer;display:flex;gap:0.5em;}button.stackend-rsvp-selected{background-color:", ";color:", ";}}"], function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.ACCENT, 'backgroundColor');
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.ACCENT, 'color');
});
exports.RsvpButtons = RsvpButtons;