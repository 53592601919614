"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = insertStackendMedia;
/**
 * Insert stackend media
 * @param Quill Quill api
 * @param editor Editor instance
 * @param html stackend html
 */
function insertStackendMedia(Quill, editor, html) {
  var range = editor.getSelection(true);
  var index = range ? range.index : 0;
  if (html.indexOf('xcap-responsive-subsite')) {
    editor.insertEmbed(index, 'xcap-responsive-subsite', html);
  } else if (html.indexOf('<video') || html.indexOf('<audio')) {
    editor.insertEmbed(index, 'stackend-audio-video', html);
  } else if (html.indexOf('xcap-site-preview')) {
    editor.insertEmbed(index, 'xcap-site-preview', html);
  } else {
    editor.clipboard.dangerouslyPasteHTML(index, '<p>' + html + '</p>');
  }
  if (range) {
    editor.setSelection(index + 1, Quill.sources.SILENT);
  }
}