"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ChangePassword = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var ChangePassword = _styledComponents.default.div.withConfig({
  displayName: "ChangePasswordstyle__ChangePassword",
  componentId: "sc-1adt117-0"
})([".stackend &{text-align:center;fieldset{margin-bottom:20px;}", ";}"], function (props) {
  return !!props.theme.LoginModule && props.theme.LoginModule(props);
});
exports.ChangePassword = ChangePassword;
var _default = ChangePassword;
exports.default = _default;