"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.BasketAndCheckoutModal = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
var _Shop = require("@stackend/react/shop/Shop.style");
var _NumberEntry = require("@stackend/react/ui/NumberEntry.style");
var _Basket = require("@stackend/react/shop/Basket.style");
var _media = require("../styled-components/media");
var sc = _interopRequireWildcard(require("../style-common/styled-component-variables.js"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var BasketAndCheckoutModal = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-basket-and-checkout', props.className)
  };
}).withConfig({
  displayName: "BasketAndCheckoutModalstyle__BasketAndCheckoutModal",
  componentId: "sc-1xy8z5y-0"
})([".stackend &{button.stackend-is-icon{border:none;background:none;&:hover{color:", ";}}", "{", "{", "{grid-template-columns:25% auto auto auto min-content;padding:0.5em 0;", "{font-size:0.6em;word-break:break-word;}", "{font-size:0.5em;font-weight:normal;word-break:break-word;}", ",", "{align-self:center;margin:0;}}}}}"], sc.colorTextAccent, _media.media.mobileScreen, _Basket.Basket, _Basket.BasketItem, _Shop.ProductTitlePart, _Shop.VariantTitlePart, _Shop.Price, _NumberEntry.NumberEntry);
exports.BasketAndCheckoutModal = BasketAndCheckoutModal;
var _default = BasketAndCheckoutModal;
exports.default = _default;