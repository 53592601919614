"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.gaCommentEventObject = gaCommentEventObject;
exports.gaEditCommentEventObject = gaEditCommentEventObject;
exports.gaReplyEventObject = gaReplyEventObject;
exports.getEventObject = getEventObject;
var _comments = require("@stackend/api/comments");
var _analyticsFunctions = require("../analytics/analyticsFunctions.js");
var _groupAnalytics = require("../group/groupAnalytics.js");
function gaCommentEventObject(_ref) {
  var comment = _ref.comment;
  return getEventObject('comment', comment);
}
function gaEditCommentEventObject(_ref2) {
  var comment = _ref2.comment;
  return getEventObject('edit_comment', comment);
}
function gaReplyEventObject(_ref3) {
  var comment = _ref3.comment;
  return getEventObject('comment_reply', comment);
}
function getEventObject(eventAction, comment) {
  var _getGALabels = getGALabels({
      comment: comment
    }),
    eventLabel = _getGALabels.eventLabel,
    eventCategory = _getGALabels.eventCategory;
  return {
    event_action: eventAction,
    event_label: eventLabel,
    event_category: eventCategory
  };
}
function getGALabels(_ref4) {
  var comment = _ref4.comment;
  var objectType = "".concat(comment.__type.substring(comment.__type.lastIndexOf('.') + 1));
  //FIXME: hardcoded that comments is only on blogEntries
  var blogEntry = comment.referenceRef;
  if (!!blogEntry) {
    var _getGAGroupData = (0, _groupAnalytics.getGAGroupData)({
        blog: blogEntry.blogRef
      }),
      groupName = _getGAGroupData.groupName,
      groupType = _getGAGroupData.groupType,
      groupTypeEnum = _getGAGroupData.groupTypeEnum;
    var blogEntryName = !!blogEntry.permalink ? blogEntry.permalink : '';
    var eventCategory = "".concat((0, _analyticsFunctions.getGaObjectName)({
      object: comment.__type,
      relatedToObject: groupType
    }), "_(").concat(objectType, "_").concat(groupTypeEnum, ")");
    var eventLabel = "".concat(groupName, "_").concat(blogEntryName, "_(").concat(blogEntry.blogId, "_").concat(blogEntry.id, ")");
    return {
      eventLabel: eventLabel,
      eventCategory: eventCategory
    };
  } else {
    //FIXME: create backup tracking
    console.warn("using fallback tracking of new comment, didn't find blogEntry");
    var _eventCategory = (0, _analyticsFunctions.getGaObjectName)({
      object: comment.__type,
      relatedToObject: 'commentModule'
    });
    var _eventLabel = "id:".concat(comment.id, ",parentId:").concat(comment.parentId); //FIXME: add proper label for stackend
    return {
      eventLabel: _eventLabel,
      eventCategory: _eventCategory
    };
  }
}