"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ModalButtonBar = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var ModalButtonBar = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-modal-button-bar', props.className)
  };
}).withConfig({
  displayName: "ModalButtonBarstyle__ModalButtonBar",
  componentId: "sc-2izz14-0"
})([".stackend &{display:flex;justify-content:flex-end;margin-bottom:0.5em;}"]);
exports.ModalButtonBar = ModalButtonBar;
var _default = ModalButtonBar;
exports.default = _default;