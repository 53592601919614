"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ShareButton = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var ShareButton = _styledComponents.default.button.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-share-button stackend-icon', props.className)
  };
}).withConfig({
  displayName: "ShareButtonstyle__ShareButton",
  componentId: "sc-116mrur-0"
})([""]);
exports.ShareButton = ShareButton;