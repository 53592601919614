"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BlogEntryComponent = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var BlogEntryComponent = _styledComponents.default.div.withConfig({
  displayName: "BlogEntryComponentstyle__BlogEntryComponent",
  componentId: "sc-17baylm-0"
})([""]);
exports.BlogEntryComponent = BlogEntryComponent;