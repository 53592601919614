"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = mayCreateThread;
var _commentContexts = require("./commentContexts");
var _user = require("@stackend/api/user");
var _privileges = require("@stackend/api/user/privileges");
var _comments = require("@stackend/api/comments");
var _loginReducer = require("@stackend/api/login/loginReducer");
/**
 * Check if a user is allowed to create a thread
 * @param currentUser
 * @param commentType
 * @param privilegedThreadCreation
 * @returns {boolean|*}
 */
function mayCreateThread(currentUser, commentType, privilegedThreadCreation) {
  var _currentUser$user;
  if (!(currentUser !== null && currentUser !== void 0 && currentUser.user)) {
    return false;
  }
  if (typeof privilegedThreadCreation === 'undefined' || privilegedThreadCreation === false) {
    return true;
  }

  // Stackend admins may always creat threads
  if ((currentUser === null || currentUser === void 0 ? void 0 : (_currentUser$user = currentUser.user) === null || _currentUser$user === void 0 ? void 0 : _currentUser$user.admin) === true) {
    return true;
  }
  return (0, _user.hasElevatedPrivilege)(currentUser, (0, _commentContexts.getCommentContext)(commentType), _comments.COMPONENT_CLASS, _privileges.PrivilegeTypeId.TRUSTED);
}