"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TOGGLE_EDIT_OR_COMMENT_BLOG_ENTRY = exports.CLOSE_EDIT_OR_COMMENT_BLOG_ENTRY = void 0;
exports.closeWriteCommentOrEdit = closeWriteCommentOrEdit;
exports.openBlogEntryWriteCommentSection = openBlogEntryWriteCommentSection;
exports.toggleWriteCommentOrEdit = toggleWriteCommentOrEdit;
var _requestReducers = require("@stackend/api/request/requestReducers");
var TOGGLE_EDIT_OR_COMMENT_BLOG_ENTRY = 'TOGGLE_EDIT_OR_COMMENT_BLOG_ENTRY';
exports.TOGGLE_EDIT_OR_COMMENT_BLOG_ENTRY = TOGGLE_EDIT_OR_COMMENT_BLOG_ENTRY;
var CLOSE_EDIT_OR_COMMENT_BLOG_ENTRY = 'CLOSE_EDIT_OR_COMMENT_BLOG_ENTRY';

/**
 * Toggle Reply editor for selected parent comment id
 * @param blogEntryId
 * @param editorType
 */
exports.CLOSE_EDIT_OR_COMMENT_BLOG_ENTRY = CLOSE_EDIT_OR_COMMENT_BLOG_ENTRY;
function toggleWriteCommentOrEdit(_ref) {
  var blogEntryId = _ref.blogEntryId,
    editorType = _ref.editorType;
  return {
    type: TOGGLE_EDIT_OR_COMMENT_BLOG_ENTRY,
    blogEntryId: blogEntryId,
    editorType: editorType
  };
}

/**
 * Close comment editor
 */
function closeWriteCommentOrEdit() {
  return {
    type: CLOSE_EDIT_OR_COMMENT_BLOG_ENTRY
  };
}
function openBlogEntryWriteCommentSection() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case TOGGLE_EDIT_OR_COMMENT_BLOG_ENTRY:
      if (!!state && state.blogEntryId === action.blogEntryId && state.editorType === action.editorType) {
        return false;
      } else {
        return {
          blogEntryId: action.blogEntryId,
          editorType: action.editorType
        };
      }
    case _requestReducers.REACT_ROUTER_REDUX_LOCATION_CHANGE:
    case CLOSE_EDIT_OR_COMMENT_BLOG_ENTRY:
      return false;
    default:
      return state;
  }
}