"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MyReviewComment = exports.CommentsPage = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _RatingStyle = require("../rating/Rating.style.js");
var _styledComponents2 = require("../styled-components");
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
var _StackendTheme = require("@stackend/react/theme/StackendTheme");
var _ComponentType = _interopRequireDefault(require("@stackend/react/theme/ComponentType"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var MyReviewComment = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-my-review', props.className)
  };
}).withConfig({
  displayName: "CommentsPagestyle__MyReviewComment",
  componentId: "sc-1h37hn0-0"
})([".stackend &{margin-top:", ";margin-bottom:", ";background-color:", ";padding:", " ", ";", "{margin-top:", ";margin-left:0;}", "{margin-top:", ";}", ";}"], function (props) {
  return props.theme.margins.medium;
}, function (props) {
  return props.theme.margins.medium;
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'backgroundColor');
}, function (props) {
  return props.theme.margins.small;
}, function (props) {
  return props.theme.margins.medium;
}, _RatingStyle.Rating, function (props) {
  return props.theme.margins.medium;
}, _styledComponents2.RichContent, function (props) {
  return props.theme.margins.medium;
}, function (props) {
  return typeof props.theme.MyReviewComment === 'function' && props.theme.CommentsPage(props);
});
exports.MyReviewComment = MyReviewComment;
var CommentsPage = _styledComponents.default.div.withConfig({
  displayName: "CommentsPagestyle__CommentsPage",
  componentId: "sc-1h37hn0-1"
})([".stackend &{width:100%;", ";}"], function (props) {
  return typeof props.theme.CommentsPage === 'function' && props.theme.CommentsPage(props);
});
exports.CommentsPage = CommentsPage;