"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProfileMenuProduct = void 0;
var _styledComponents = require("styled-components");
var _Product = require("@stackend/react/shop/Product.style");
var _Shop = require("@stackend/react/shop/Shop.style");
/* This is a fix until styled components supports container querries.
 * Basicaly the mobile styling when visible in the menu
 */
var ProfileMenuProduct = (0, _styledComponents.css)(["", "{padding:20px;background-color:", ";border-radius:", ";margin-bottom:20px;grid-template-columns:auto;grid-template-rows:auto auto auto;grid-template-areas:'details' 'actions' 'description';> ", "{display:none;grid-area:title;}", " > ", "{display:block;}}"], _Product.Product, function (props) {
  return props.theme.shopifyMainColor || '#ccc';
}, function (props) {
  return props.theme.shopifyBorderRadius || '10px';
}, _Shop.Title, _Product.ProductDetails, _Shop.Title);
exports.ProfileMenuProduct = ProfileMenuProduct;