"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.RegistrationPage = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var RegistrationPage = _styledComponents.default.div.withConfig({
  displayName: "RegistrationPage",
  componentId: "sc-kqyb33-0"
})([".stackend &{margin-top:200px;", ";}"], function (props) {
  return !!props.theme.RegistrationPage && props.theme.RegistrationPage(props);
});
exports.RegistrationPage = RegistrationPage;
var _default = RegistrationPage;
exports.default = _default;