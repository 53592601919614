"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.media = void 0;
// Media selectors
var media = {
  mobileScreen: '@media only screen and (max-width: 767px)',
  overMobile: '@media only screen and (min-width: 768px)',
  tabletScreen: '@media only screen and (max-width: 991px)',
  overTablet: '@media only screen and (min-width: 992px)',
  smallerScreen: '@media only screen and (max-width: 1300px)',
  largerScreen: '@media only screen and (min-width: 1300px)',
  evenLargerScreen: '@media only screen and (min-width: 1400px)',
  wideScreen1920: '@media only screen and (min-width: 1900px)',
  wideScreen2560: '@media only screen and (min-width: 2500px)',
  smallerThanEvenLargerScreen: '@media only screen and (max-width: 1399px)',
  portrait: '@media (orientation: portrait)',
  landscape: '@media (orientation: landscape)',
  mobilePortrait: '@media only screen and (max-width: 767px) and (orientation: portrait)',
  mobileLandscape: '@media only screen and (max-width: 767px) and (orientation: landscape)'
};
exports.media = media;