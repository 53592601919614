"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.CreateGroup = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var sc = _interopRequireWildcard(require("../../style-common/styled-component-variables.js"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var CreateGroup = _styledComponents.default.div.withConfig({
  displayName: "CreateGroup",
  componentId: "sc-1ot3hwh-0"
})([".stackend &{display:flex;flex-direction:column;position:relative;width:100%;margin:0 auto;max-width:", ";", "{max-width:", ";}.settings-wrapper{display:flex;flex-direction:column;border-radius:", ";box-shadow:", ";border:", ";width:100%;padding:20px;background:#fff;", "{align-items:center;}}fieldset{margin:10px 0;}input{display:flex;align-items:center;padding:0 10px;border:1px solid #ccc;height:50px;width:100%;font-family:", ";font-size:", ";border-radius:", ";color:#464646;}#xcapGroupForm{display:flex;flex-direction:column;justify-content:space-evenly;height:100%;width:100%;}textarea{display:flex;align-items:center;padding:10px;width:100%;border:1px solid #ccc;font-family:", ";font-size:", ";border-radius:", ";color:#464646;}#permalink-group{display:flex;flex-direction:row;position:relative;border-radius:6px;font-size:20px;border:1px solid #dedede;overflow:hidden;", "{flex-direction:column;border:1px solid #ababab;}label{display:flex;align-items:center;flex:2 2 10em;width:100%;min-height:50px;height:100%;margin:0;padding:0 10px;font-weight:normal;cursor:not-allowed;background:rgba(0,0,0,0.1);@media @tablet-and-mobile{flex:0;border:none;}}input{display:flex;align-items:center;flex:1 2 10em;border-radius:0;border:none;border-left:1px solid #dedede;", "{max-width:100%;flex:0;margin:0;border:none;height:auto;}}.glyphicon-remove{position:absolute;top:0;right:0;}}.upload-background{width:280px;overflow:hidden;img{}}", ";}"], sc.maxContentWidthDesktop, sc.BigDesktop, sc.maxContentWidthLargeDesktop, sc.borderRadius, sc.boxShadow, sc.border, sc.Mobile, sc.fontNormal, sc.fontSizeBodyText, sc.borderRadius, sc.fontNormal, sc.fontSizeBodyText, sc.borderRadius, sc.TabletAndMobile, sc.TabletAndMobile, function (props) {
  return !!props.theme.CreateGroup && props.theme.CreateGroup(props);
});
exports.CreateGroup = CreateGroup;
var _default = CreateGroup;
exports.default = _default;