"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.BoxShadow = void 0;
var _styledComponents = require("styled-components");
var BoxShadow = function BoxShadow() {
  return (0, _styledComponents.css)(["box-shadow:1px 1px 4px 1px ", ";padding:0.75em;margin-left:3px;margin-right:5px;margin-bottom:0.75em;"], function (props) {
    var _props$theme;
    return ((_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : _props$theme.borderColor) || '#e6e6e6';
  });
};
exports.BoxShadow = BoxShadow;
var _default = BoxShadow;
exports.default = _default;