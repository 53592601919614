"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CommentDisplayType = void 0;
/**
 * Sort criteria
 */
var CommentDisplayType = {
  COMMENT: 'COMMENT',
  NOTIFICATION: 'NOTIFICATION'
};
exports.CommentDisplayType = CommentDisplayType;