"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Skeleton = exports.MediaFileChooser = exports.MediaFile = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var sc = _interopRequireWildcard(require("../style-common/styled-component-variables.js"));
var _StackendTheme = require("@stackend/react/theme/StackendTheme");
var _ComponentType = _interopRequireDefault(require("@stackend/react/theme/ComponentType"));
var _CloseAndBackButton = require("../ui/CloseAndBackButton.style");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var MediaFileChooser = _styledComponents.default.div.attrs({
  className: 'stackend-media-file-chooser'
}).withConfig({
  displayName: "MediaFileChooserstyle__MediaFileChooser",
  componentId: "sc-1dzx9jg-0"
})([".stackend &{position:relative;height:100%;display:flex;min-width:80vw;flex-direction:column;", "{position:absolute;top:0;right:0;}.stackend-top-section{flex:0 0 auto;text-align:center;p{overflow:hidden;}.stackend-image-upload-dropzone{display:inline-block;}}.stackend-button-box{display:flex;align-items:center;justify-content:center;gap:1em;}.stackend-media-container{overflow-y:auto;overflow-x:hidden;padding:20px 0px;.stackend-image-list{flex:1 1 auto;opacity:1;text-align:left;line-height:0;padding:15px 5px 0 5px;display:grid;grid-gap:10px;grid-template-columns:repeat(auto-fill,minmax(8rem,1fr));grid-auto-rows:1fr;&::before{content:'';width:0;padding-bottom:100%;grid-row:1 / 1;grid-column:1 / 1;}& > *:first-child{grid-row:1 / 1;grid-column:1 / 1;}}}", ";}"], _CloseAndBackButton.CloseAndBackButton, function (props) {
  return !!props.theme.MediaFileChooser && props.theme.MediaFileChooser(props);
});
exports.MediaFileChooser = MediaFileChooser;
var MediaFile = _styledComponents.default.div.withConfig({
  displayName: "MediaFileChooserstyle__MediaFile",
  componentId: "sc-1dzx9jg-1"
})([".stackend &{display:inline-block;width:100%;cursor:pointer;position:relative;border-radius:calc(", " * 0.5);overflow:hidden;user-select:none;&:hover{box-shadow:0px 0px 3px 1px ", ";}.stackend-img-wrapper{display:block;background-repeat:no-repeat;background-position:center;background-size:contain;padding-bottom:100%;}.stackend-generic-icon{text-align:center;.material-icons{width:100%;font-size:3em;line-height:3em;}}.stackend-asset-name{display:flex;position:absolute;bottom:-3px;width:100%;padding:5px 2px 4px;margin:0;line-height:1.5;font-size:0.75em;box-sizing:border-box;text-align:center;background:#ffffff57;.stackend-name{display:inline-block;margin:0;padding:0;}.stackend-name{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;flex:2;}}.stackend-delete{position:absolute;height:30px;width:30px;border-radius:100%;background:rgba(255,255,255,0.8);border:none;top:10px;left:10px;cursor:pointer;color:", ";margin:0;padding:0;font-size:18px;&:hover,&:active{background:rgba(255,255,255,1);color:", ";}}}"], function (props) {
  return props.theme.shopifyBorderRadius;
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'borderColor');
}, sc.colorTextAccent, sc.colorTextAccent);
exports.MediaFile = MediaFile;
var Skeleton = _styledComponents.default.div.attrs({
  className: 'stackend-media-file-chooser-skeleton'
}).withConfig({
  displayName: "MediaFileChooserstyle__Skeleton",
  componentId: "sc-1dzx9jg-2"
})([".stackend &{background:#eeeeee;cursor:wait;}"]);
exports.Skeleton = Skeleton;