"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.GroupSettingsMenu = exports.GroupSettings = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var sc = _interopRequireWildcard(require("../../style-common/styled-component-variables.js"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var GroupSettings = _styledComponents.default.div.attrs({
  className: 'stackend-group-settings'
}).withConfig({
  displayName: "GroupSettings",
  componentId: "sc-1rrarn6-0"
})([".stackend &{position:absolute;bottom:20px;right:25px;", ";}"], function (props) {
  return !!props.theme.GroupSettings && props.theme.GroupSettings(props);
});
exports.GroupSettings = GroupSettings;
var GroupSettingsMenu = _styledComponents.default.ul.withConfig({
  displayName: "GroupSettings__GroupSettingsMenu",
  componentId: "sc-1rrarn6-1"
})([".stackend &{position:absolute;z-index:1001;left:0;padding:10px;border-radius:3px;width:180px;background:white;box-shadow:1px 2px 10px 1px rgba(119,119,119,0.55);a{color:", ";}", ";}"], sc.colorText, function (props) {
  return !!props.theme.GroupSettingsMenu && props.theme.GroupSettingsMenu(props);
});
exports.GroupSettingsMenu = GroupSettingsMenu;
var _default = GroupSettings;
exports.default = _default;