"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PollInput = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var PollInput = _styledComponents.default.div.attrs({
  className: 'stackend-poll-input'
}).withConfig({
  displayName: "PollInputstyle__PollInput",
  componentId: "sc-xscjk7-0"
})([".stackend &{max-width:400px;margin:10px 0;.stackend-poll-description{input{width:100%;}}.stackend-poll-answers{display:grid;grid-template-columns:auto min-content;align-items:center;gap:0.5em;span{white-space:nowrap;}}input{&.select2-container-active{border-color:#09a8dc;box-shadow:none;}}.stackend-poll-add-remove,.stackend-poll-input{margin:0.5em 0;}.stackend-poll-add-remove{display:flex;gap:1em;}.stackend-circle-add,.stackend-circle-subtract{font-size:1.5em;cursor:pointer;}}"]);
exports.PollInput = PollInput;