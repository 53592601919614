"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PageInputHeading = exports.PageInput = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var PageInputHeading = _styledComponents.default.p.attrs(function (props) {
  return {
    className: 'stackend-blog-editor-page-input-title'
  };
}).withConfig({
  displayName: "PageInputstyle__PageInputHeading",
  componentId: "sc-1pu4zqr-0"
})([".stackend &{margin:1em 0 0.5em 0;}"]);
exports.PageInputHeading = PageInputHeading;
var PageInput = _styledComponents.default.input.attrs(function (props) {
  return {
    className: 'stackend-blog-editor-page-input'
  };
}).withConfig({
  displayName: "PageInputstyle__PageInput",
  componentId: "sc-1pu4zqr-1"
})([".stackend &{margin:0;padding:0.25em;width:100%;&:focus::placeholder{color:transparent;}}"]);
exports.PageInput = PageInput;