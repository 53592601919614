"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.RegisterForm = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _media = require("../styled-components/media.js");
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var RegisterForm = _styledComponents.default.form.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-register-form', props.className)
  };
}).withConfig({
  displayName: "RegisterFormstyle__RegisterForm",
  componentId: "sc-f9uhsf-0"
})([".stackend &{fieldset{margin:", " 0;}.stackend-responsive-fields{display:grid;grid-template-columns:1fr 1fr;grid-template-rows:auto;grid-gap:", " ", ";", "{grid-template-columns:1fr;grid-gap:", ";}}fieldset.stackend-register-button{margin-top:", ";p{flex:1 0 100%;margin-top:", ";}}input,select{box-sizing:border-box;}input[type='number']::-webkit-inner-spin-button,input[type='number']::-webkit-outer-spin-button{-webkit-appearance:none;margin:0;}input[type='number']{-moz-appearance:textfield;}option:first-child{color:red;}&.email-hide{display:none;}.stackend-registration-message{margin-bottom:2em;}.stackend-gender-select,.stackend-birth-date-select{margin-top:2em;}", ";}"], function (props) {
  return props.theme.margins.medium;
}, function (props) {
  return props.theme.margins.medium;
}, function (props) {
  return props.theme.margins.large;
}, _media.media.mobileScreen, function (props) {
  return props.theme.margins.medium;
}, function (props) {
  return props.theme.margins.large;
}, function (props) {
  return props.theme.margins.medium;
}, function (props) {
  return !!props.theme.RegisterForm && props.theme.RegisterForm(props);
});
exports.RegisterForm = RegisterForm;
var _default = RegisterForm;
exports.default = _default;