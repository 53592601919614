"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ImageUpload = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var sc = _interopRequireWildcard(require("../style-common/styled-component-variables.js"));
var _StackendTheme = require("@stackend/react/theme/StackendTheme");
var _ComponentType = _interopRequireDefault(require("@stackend/react/theme/ComponentType"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var ImageUpload = _styledComponents.default.div.attrs(function (props) {
  return {};
}).withConfig({
  displayName: "ImageUploadstyle__ImageUpload",
  componentId: "sc-1mkzzb3-0"
})([".stackend &{display:block;position:relative;.stackend-image-upload-dropzone{width:100%;height:100%;cursor:pointer;overflow:hidden;border-radius:calc(", " * 0.5);border:2px solid black;position:relative;&:hover{opacity:1;}}.stackend-image{background-size:cover;background-repeat:no-repeat;width:100%;height:100%;background-position:50% 50%;display:block;}.stackend-choose{margin:0;display:inline-block;position:absolute;bottom:0;right:0;color:", ";cursor:pointer;background:rgba(255,255,255,0.7);padding:10px;border-top-left-radius:10px;.material-icons{top:-4px;}}.stackend-choose-image{padding:20px 0;text-align:right;overflow:hidden;}&.stackend-image-upload{button.stackend-icon{position:absolute;cursor:pointer;z-index:10;margin:0;justify-content:center;background:transparent;border:unset;padding:0.1em 0.5em;border-radius:5px;.material-icons{text-align:center;color:", ";font-size:1.5em;width:1em;height:1em;line-height:1em;}&:hover{background-color:", ";}&.stackend-delete{top:0;right:0;}&.stackend-upload-icon{bottom:0;left:0;}}}&.stackend-square{height:78px;width:78px;}&.stackend-responsive{width:100%;height:auto;.stackend-image-upload-dropzone{border-radius:0;height:auto;text-align:center;margin:0 auto;.stackend-image{min-height:4em;height:auto;}}.stackend-upload-icon{left:5px;bottom:5px;}.stackend-delete{top:5px;right:5px;}}", ";}"], function (props) {
  return props.theme.shopifyBorderRadius;
}, sc.colorTextAccent, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.TEXT_INPUT, 'color');
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.TEXT_INPUT, 'backgroundColor');
}, function (props) {
  return !!props.theme.ImageUpload && props.theme.ImageUpload(props);
});
exports.ImageUpload = ImageUpload;