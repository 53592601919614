"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RatingStars = exports.RatingButton = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var RatingStars = _styledComponents.default.span.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-rating-stars', props.className)
  };
}).withConfig({
  displayName: "RatingButtonstyle__RatingStars",
  componentId: "sc-1mgnhti-0"
})([""]);
exports.RatingStars = RatingStars;
var RatingButton = _styledComponents.default.span.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-rating-button', props.className)
  };
}).withConfig({
  displayName: "RatingButtonstyle__RatingButton",
  componentId: "sc-1mgnhti-1"
})([".stackend &{display:inline-flex;align-items:center;margin:0 ", ";> button{outline:none;cursor:pointer;font-size:1.25em;}", ";}"], function (props) {
  return props.theme.margins.small;
}, function (props) {
  return !!props.theme.RatingButton && props.theme.RatingButton(props);
});
exports.RatingButton = RatingButton;