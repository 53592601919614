"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = setupLikeSubscriptions;
var _xcapObjects = require("@stackend/api/util/xcapObjects");
var _collections = require("@stackend/api/util/collections");
var _ReferenceAble = _interopRequireDefault(require("@stackend/api/api/ReferenceAble"));
var _StackendWebSocket = require("@stackend/api/util/StackendWebSocket");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
/**
 * Set up like subscriptions for a number of objects.
 * Handles subscribe/unsubscribe. Pass [] as entries to remove subscriptions.
 * @param stackendWebSocket
 * @param listener
 * @param entries
 * @param oldEntries (optional)
 */
function setupLikeSubscriptions(stackendWebSocket, listener, entries, oldEntries) {
  if (!entries || !stackendWebSocket || !listener) {
    return;
  }
  var newRefs = (0, _xcapObjects.getObfuscatedReferences)(entries);
  var added = null;
  if (oldEntries) {
    var oldRefs = (0, _xcapObjects.getObfuscatedReferences)(oldEntries);
    var r = (0, _collections.getAddedRemoved)(oldRefs, newRefs);
    added = r.added;
    if (r.removed.size !== 0) {
      stackendWebSocket.unsubscribeMultiple(_StackendWebSocket.RealTimeFunctionName.LIKE, 'like', _toConsumableArray(r.removed), listener);
    }
  } else {
    added = newRefs;
  }
  if (added.size !== 0) {
    stackendWebSocket.subscribeMultiple(_StackendWebSocket.RealTimeFunctionName.LIKE, 'like', _toConsumableArray(added), listener);
  }
}