"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MediaFileChooserButtonRemove = exports.MediaFileChooserButtonImage = exports.MediaFileChooserButton = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
var _StackendTheme = require("@stackend/react/theme/StackendTheme");
var _ComponentType = _interopRequireDefault(require("@stackend/react/theme/ComponentType"));
var _ComponentState = _interopRequireDefault(require("@stackend/react/theme/ComponentState"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var MediaFileChooserButtonRemove = _styledComponents.default.a.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-media-file-chooser-button-remove icon', props.className)
  };
}).withConfig({
  displayName: "MediaFileChooserButtonstyle__MediaFileChooserButtonRemove",
  componentId: "sc-voippt-0"
})([""]);
exports.MediaFileChooserButtonRemove = MediaFileChooserButtonRemove;
var MediaFileChooserButtonImage = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-media-file-chooser-button-image', props.className)
  };
}).withConfig({
  displayName: "MediaFileChooserButtonstyle__MediaFileChooserButtonImage",
  componentId: "sc-voippt-1"
})([""]);
exports.MediaFileChooserButtonImage = MediaFileChooserButtonImage;
var MediaFileChooserButton = _styledComponents.default.button.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-media-file-chooser-button stackend-icon', props.className)
  };
}).withConfig({
  displayName: "MediaFileChooserButtonstyle__MediaFileChooserButton",
  componentId: "sc-voippt-2"
})([".stackend &{&.stackend-media-file-chooser-button{display:inline-block;padding:", ";border-radius:", ";height:auto;border:", ";", ".stackend-media-file-chooser-button-image{width:100px;height:100px;position:relative;cursor:pointer;background-size:contain;background-repeat:no-repeat;background-position-x:center;background-position-y:center;> .material-icons{position:absolute;font-size:90px;top:5px;left:5px;line-height:1em;color:", ";}", ".stackend-media-file-chooser-button-remove{position:absolute;top:2px;right:2px;cursor:pointer;border-radius:50%;background-color:", ";margin:0;padding:0;display:flex;align-items:center;justify-content:center;> .material-icons{font-size:24px;line-height:1em;color:", ";}}}}}"], function (props) {
  return props.theme.margins.small;
}, function (props) {
  return props.theme.borderRadius;
}, function (props) {
  return (0, _StackendTheme.getComponentBorder)(props.theme, _ComponentType.default.TEXT_INPUT);
}, MediaFileChooserButtonImage, function (props) {
  return props.theme.color + '7F';
}, MediaFileChooserButtonRemove, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BUTTON_DANGER, 'backgroundColor');
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BUTTON_DANGER, 'color');
});
exports.MediaFileChooserButton = MediaFileChooserButton;