"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HideScrollbar = void 0;
var _styledComponents = require("styled-components");
/**
 * Hide scrollbars using  scrollbar-width: none;
 */
var HideScrollbar = function HideScrollbar() {
  return (0, _styledComponents.css)(["-ms-overflow-style:none;scrollbar-width:none;overflow-y:scroll;&::-webkit-scrollbar{display:none;}"]);
};
exports.HideScrollbar = HideScrollbar;