"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StackendBaseStyle = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _adminBaseStyle = require("./admin-base-style.js");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var StackendBaseStyle = _styledComponents.default.div.withConfig({
  displayName: "StackendBaseStyle",
  componentId: "sc-8em9ep-0"
})([".stackend &{line-height:1.3em;", ";}"], _adminBaseStyle.adminBaseStyle);
exports.StackendBaseStyle = StackendBaseStyle;