"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var Content = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-cms', props.className)
  };
}).withConfig({
  displayName: "Content",
  componentId: "sc-1y46wil-0"
})([".stackend &{&.stackend-cms-editable{.stackend-edit:hover{", ";}.stackend-rich-content .mce-tinymce{box-shadow:0 0 10px #aaa;iframe{border:none;}.mce-top-part{background:#eee;}.mce-btn.mce-first .mce-txt{margin-left:5px;}}}}"], function (props) {
  return props.editable && {
    background: '#eaf5d0',
    cursor: 'text',
    //outline: 'dashed 1px #777' /* Using outline here to no add a border to the dimensions*/,
    position: 'relative'
  };
});
var _default = Content;
exports.default = _default;