"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.XcapSitePreview = void 0;
var _styledComponents = require("styled-components");
var _media = require("../media.js");
var _StackendTheme = require("@stackend/react/theme/StackendTheme");
var _ComponentType = _interopRequireDefault(require("@stackend/react/theme/ComponentType"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var XcapSitePreview = function XcapSitePreview() {
  return (0, _styledComponents.css)([".xcap-site-preview{display:flex;gap:0;cursor:pointer;background-color:", ";color:", ";border-radius:", ";overflow:hidden;margin:0.5em 0;", "{flex-direction:column;}.image-wrapper{flex:0 0 auto;", "{width:12em;}img{width:100% !important;height:auto !important;}}.info-text{display:flex;flex-direction:column;gap:0.5em;flex:1 1 auto;margin:0.5em;overflow:hidden;text-overflow:ellipsis;.title{font-size:1.25rem;font-weight:bold;white-space:nowrap;", "{white-space:normal;}}.description{", "{display:block;margin-top:1em;}}.text-sample{display:none;}}}"], function (props) {
    return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'backgroundColor');
  }, function (props) {
    return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'color');
  }, function (props) {
    return props.theme.borderRadius;
  }, _media.media.mobileScreen, _media.media.overMobile, _media.media.mobileScreen, _media.media.mobileScreen);
};
exports.XcapSitePreview = XcapSitePreview;
var _default = XcapSitePreview;
exports.default = _default;