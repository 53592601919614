"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.PollTotalVotes = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var PollTotalVotes = _styledComponents.default.div.attrs({
  className: 'stackend-poll-total-votes'
}).withConfig({
  displayName: "PollTotalVotesstyle__PollTotalVotes",
  componentId: "sc-1h4j66y-0"
})([".stackend &{float:right;margin:0.5em 0;padding-right:", ";", ";}"], function (props) {
  return props.theme.margins.small;
}, function (props) {
  return !!props.theme.PollTotalVotes && props.theme.PollTotalVotes(props);
});
exports.PollTotalVotes = PollTotalVotes;
var _default = PollTotalVotes;
exports.default = _default;