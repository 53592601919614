"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ParentIdComparator;
/**
 * Order comments by parent id, stabilize by id
 * @param a
 * @param b
 * @returns {number}
 * @constructor
 */
function ParentIdComparator(a, b) {
  if (b.parentId !== a.parentId) {
    return b.parentId - a.parentId;
  }
  return a.id - b.id;
}