"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RatingValue = exports.RatingStars = exports.RatingInfo = exports.Rating = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var Rating = _styledComponents.default.span.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-rating', props.className)
  };
}).withConfig({
  displayName: "Ratingstyle__Rating",
  componentId: "sc-sr5xcj-0"
})([".stackend &{display:inline-flex;align-items:center;}"]);
exports.Rating = Rating;
var RatingStars = _styledComponents.default.span.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-rating-stars', props.className)
  };
}).withConfig({
  displayName: "Ratingstyle__RatingStars",
  componentId: "sc-sr5xcj-1"
})([".stackend &{display:inline-flex;align-items:center;font-size:1.25em;}"]);
exports.RatingStars = RatingStars;
var RatingInfo = _styledComponents.default.span.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-rating-info', props.className)
  };
}).withConfig({
  displayName: "Ratingstyle__RatingInfo",
  componentId: "sc-sr5xcj-2"
})([""]);
exports.RatingInfo = RatingInfo;
var RatingValue = _styledComponents.default.span.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-rating-value', props.className)
  };
}).withConfig({
  displayName: "Ratingstyle__RatingValue",
  componentId: "sc-sr5xcj-3"
})([".stackend &{margin-left:", ";line-height:1em;white-space:nowrap;}"], function (props) {
  return props.theme.margins.small;
});
exports.RatingValue = RatingValue;