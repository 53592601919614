"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Subsite = void 0;
var _media = require("../styled-components/media.js");
var _styledComponents = require("styled-components");
/* Can not use sc, since this is not within the .stackend selector */
var Subsite = "\n\t.stackend-site .stackend-site {\n\t\t.stackend-site-wrapper {\n\t\t\tdisplay: flex;\n\t\t}\n\n\t\t&.stackend-menu-horizontal {\n\t\t\t.stackend-site-wrapper {\n\t\t\t\tflex-direction: column;\n\t\t\t}\n\t\t}\n\n\t\t&.stackend-menu-vertical {\n\t\t\t.stackend-site-wrapper {\n\t\t\t\tflex-direction: row;\n\n\t\t\t\t".concat(_media.media.tabletScreen, " {\n\t\t\t\t\tflex-direction: column;\n\t\t\t\t}\n\n\t\t\t\t.stackend-menu-container {\n\t\t\t\t\tflex: 0 0 auto;\n\t\t\t\t}\n\n\t\t\t\t.stackend-page {\n\t\t\t\t\tflex: 1 1 auto;\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n");
exports.Subsite = Subsite;