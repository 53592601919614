"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatISODate = formatISODate;
exports.formatISODateTime = formatISODateTime;
exports.formatISOMonth = formatISOMonth;
exports.formatISOTime = formatISOTime;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
/**
 * Format a date as YYYY-MM-DD
 * @param date
 */
function formatISODate(date) {
  var d = getDate(date);
  if (!d) {
    return null;
  }
  return zeroPadYear(d.getFullYear()) + '-' + zeroPad(d.getMonth() + 1) + '-' + zeroPad(d.getDate());
}

/**
 * Format a date as YYYY-MM
 * @param date
 * @returns {null}
 */
function formatISOMonth(date) {
  var d = getDate(date);
  if (!d) {
    return null;
  }
  return d.getFullYear() + '-' + zeroPad(d.getMonth() + 1);
}

/**
 * Format a date as YYYY-MM-DD HH:mm
 * @param date
 */
function formatISODateTime(date) {
  var d = getDate(date);
  if (!d) {
    return null;
  }
  return zeroPadYear(d.getFullYear()) + '-' + zeroPad(d.getMonth() + 1) + '-' + zeroPad(d.getDate()) + ' ' + zeroPad(d.getHours()) + ':' + zeroPad(d.getMinutes());
}

/**
 * Format a time as HH:mm
 * @param date
 * @returns {string|null}
 */
function formatISOTime(date) {
  var d = getDate(date);
  if (!d) {
    return null;
  }
  return zeroPad(d.getHours()) + ':' + zeroPad(d.getMinutes());
}
function getDate(date) {
  var t = _typeof(date);
  if (t === 'number') {
    return new Date(date);
  } else if (t === 'object') {
    return date;
  }
  return null;
}
function zeroPadYear(n) {
  var s = '' + n;
  while (s.length < 4) {
    s = '0' + s;
  }
  return s;
}
function zeroPad(n) {
  return n < 10 ? '0' + n : String(n);
}