"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.HeroImage = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var HeroImage = _styledComponents.default.span.withConfig({
  displayName: "HeroImage",
  componentId: "sc-1a3g61a-0"
})([".stackend &{display:block;position:absolute;top:0;left:0;right:0;width:100%;height:100%;margin:0 auto;background:#f7f7f7;background-size:cover;", ";}"], function (props) {
  return !!props.theme.HeroImage && props.theme.HeroImage(props);
});
exports.HeroImage = HeroImage;
var _default = HeroImage;
exports.default = _default;