"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProfileEditor = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
var _ImageUpload = require("../media/ImageUpload.style");
var _ProfileMenu = require("./ProfileMenu.style");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var ProfileEditor = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-user-profile-editor', props.className)
  };
}).withConfig({
  displayName: "ProfileEditorstyle__ProfileEditor",
  componentId: "sc-f6a3xk-0"
})([".stackend &{display:grid;gap:", ";grid-template-columns:min-content 1fr 1fr;grid-template-areas:'image first-name first-name' 'image last-name last-name' ' image birth-date gender' 'description description description' 'cancel empty save';justify-items:stretch;align-items:center;overflow:hidden;padding:calc(2 * ", ");", "{grid-area:image;}.stackend-profile-first-name{grid-area:first-name;}.stackend-profile-last-name{grid-area:last-name;}.stackend-profile-birth-date{grid-area:birth-date;}.stackend-profile-city{grid-area:city;display:none;}.stackend-profile-description{grid-area:description;}> input,> select{max-width:100%;}button.stackend-danger-action{grid-area:cancel;justify-self:start;}button.stackend-positive-action{grid-area:save;justify-self:end;}}"], function (props) {
  return props.theme.margins.medium || '1em';
}, function (props) {
  return props.theme.borderWidth;
}, _ImageUpload.ImageUpload);
exports.ProfileEditor = ProfileEditor;