"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ProfileDetailsGrid = exports.ProfileDetails = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var ProfileDetails = _styledComponents.default.div.attrs({
  className: 'stackend-profile-details'
}).withConfig({
  displayName: "ProfileDetails",
  componentId: "sc-1g1jb6b-0"
})([".stackend &{p{margin:0;}textarea.stackend-user-description{width:100%;height:4em;margin:", " 0;}p.stackend-user-description{margin-top:", ";margin-bottom:", ";}", ";}"], function (props) {
  return props.theme.margins.medium;
}, function (props) {
  return props.theme.margins.large;
}, function (props) {
  return props.theme.margins.large;
}, function (props) {
  return !!props.theme.ProfileDetails && props.theme.ProfileDetails(props);
});
exports.ProfileDetails = ProfileDetails;
var _default = ProfileDetails;
exports.default = _default;
var ProfileDetailsGrid = _styledComponents.default.div.attrs({
  className: 'stackend-profile-details-grid'
}).withConfig({
  displayName: "ProfileDetails__ProfileDetailsGrid",
  componentId: "sc-1g1jb6b-1"
})([".stackend &{display:grid;grid-template-columns:min-content auto;gap:", " ", ";white-space:nowrap;.super-user,.moderator{grid-column:1 / span 2;}}"], function (props) {
  return props.theme.margins.small;
}, function (props) {
  return props.theme.margins.medium;
});
exports.ProfileDetailsGrid = ProfileDetailsGrid;