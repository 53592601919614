"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ValidatedTextField = exports.FieldError = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _react = _interopRequireDefault(require("react"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var FieldError = _styledComponents.default.p.withConfig({
  displayName: "ValidatedTextFieldstyle__FieldError",
  componentId: "sc-1iajve0-0"
})([""]);
exports.FieldError = FieldError;
var ValidatedTextField = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-validated-textfield', props.className)
  };
}).withConfig({
  displayName: "ValidatedTextFieldstyle__ValidatedTextField",
  componentId: "sc-1iajve0-1"
})([".stackend &{display:flex;flex-direction:column;", "{color:#de4b39;text-align:right;font-size:12px;min-height:1.3em;}}"], FieldError);
exports.ValidatedTextField = ValidatedTextField;