"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = setupGoogleTagManager;
function setupGoogleTagManager(gaid) {
  var gaTagManager = document.createElement('script');
  gaTagManager.setAttribute('src', "https://www.googletagmanager.com/gtag/js?id=".concat(gaid));
  document.body.appendChild(gaTagManager);
  window.dataLayer = window.dataLayer || [];
  function stackendGA() {
    window.dataLayer.push(arguments);
  }
  window.stackendGA = stackendGA;
  stackendGA('js', new Date());
  stackendGA('config', gaid);
}