"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CMS_TOGGLE_EDIT_IN_PLACE = exports.CMS_OPEN_EDITOR = exports.CMS_CLOSE_EDITOR = void 0;
exports.default = _default;
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
var CMS_TOGGLE_EDIT_IN_PLACE = 'CMS_TOGGLE_EDIT_IN_PLACE';
exports.CMS_TOGGLE_EDIT_IN_PLACE = CMS_TOGGLE_EDIT_IN_PLACE;
var CMS_OPEN_EDITOR = 'CMS_OPEN_EDITOR';
exports.CMS_OPEN_EDITOR = CMS_OPEN_EDITOR;
var CMS_CLOSE_EDITOR = 'CMS_CLOSE_EDITOR';
exports.CMS_CLOSE_EDITOR = CMS_CLOSE_EDITOR;
function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    enabled: false,
    editorOpen: false,
    content: null,
    originalId: null,
    originalContent: null,
    originalDisplay: null
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case CMS_TOGGLE_EDIT_IN_PLACE:
      return _extends({}, state, {
        enabled: action.enabled
      });
    case CMS_OPEN_EDITOR:
      return _extends({}, state, {
        editorOpen: true,
        content: action.content,
        originalId: action.originalId,
        originalContent: action.originalContent,
        originalDisplay: action.originalDisplay
      });
    case CMS_CLOSE_EDITOR:
      return _extends({}, state, {
        editorOpen: false,
        content: null,
        originalId: null,
        originalContent: null,
        originalDisplay: null
      });
    default:
      return state;
  }
}