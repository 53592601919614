"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SubMenuItems = exports.MenuLink = exports.MenuItem = exports.Menu = exports.Burger = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var sc = _interopRequireWildcard(require("../style-common/styled-component-variables.js"));
var _media = require("../styled-components/media.js");
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var MenuLink = _styledComponents.default.a.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-menu-link', props.className)
  };
}).withConfig({
  displayName: "Menustyle__MenuLink",
  componentId: "sc-12awnw1-0"
})([".stackend &{white-space:nowrap;}"]);
exports.MenuLink = MenuLink;
var MenuItem = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-menu-item', props.className)
  };
}).withConfig({
  displayName: "Menustyle__MenuItem",
  componentId: "sc-12awnw1-1"
})([""]);
exports.MenuItem = MenuItem;
var SubMenuItems = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-submenu-items', props.className)
  };
}).withConfig({
  displayName: "Menustyle__SubMenuItems",
  componentId: "sc-12awnw1-2"
})([""]);
exports.SubMenuItems = SubMenuItems;
var Burger = _styledComponents.default.button.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-menu-burger', props.className)
  };
}).withConfig({
  displayName: "Menustyle__Burger",
  componentId: "sc-12awnw1-3"
})([".stackend &{padding:0.25em 0;width:100%;text-align:left;}"]);
exports.Burger = Burger;
var Menu = _styledComponents.default.nav.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-site-menu', props.className)
  };
}).withConfig({
  displayName: "Menustyle__Menu",
  componentId: "sc-12awnw1-4"
})([".stackend &{", "{line-height:2em;}", "{display:none;}&.stackend-menu-vertical{margin-right:2em;", "{display:block;margin:0.5em 0;", "{display:inline-block;@media (pointer:coarse){padding:0.5em 0;}&:hover{color:", ";}}", "{display:none;}&.stackend-menu-item-selected{> ", "{font-weight:bold;}", "{display:block;}}}", "{margin-left:1em;}}&.stackend-menu-horizontal{", "{display:inline-block;margin-left:1em;padding:0.5em 1em;position:relative;&:first-of-type{margin-left:0;}", "{font-weight:bold;}&.stackend-submenu-open{> ", "{display:inline-block;position:absolute;left:0;top:2.1em;min-width:100%;}}", "{display:none;background:", ";box-shadow:1px 1px 4px 1px #e6e6e6;", "{margin-left:0;width:100%;", "{width:100%;display:inline-block;text-overflow:ellipsis;white-space:nowrap;}}}}", "{margin-bottom:1em;", "{display:block;}", "{display:block;margin-left:0;", "{display:block;background:inherit;box-shadow:none;margin-left:1em;}&.stackend-submenu-open{> ", "{display:block;position:static;}}}&.stackend-menu-inactive{", "{display:none;}}}}}"], _media.media.tabletScreen, Burger, MenuItem, MenuLink, sc.colorTextAccent(), SubMenuItems, MenuLink, SubMenuItems, SubMenuItems, MenuItem, MenuLink, SubMenuItems, SubMenuItems, sc.backgroundColor(), MenuItem, MenuLink, _media.media.tabletScreen, Burger, MenuItem, SubMenuItems, SubMenuItems, MenuItem);
exports.Menu = Menu;