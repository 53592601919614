"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.UserMenu = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var sc = _interopRequireWildcard(require("../../style-common/styled-component-variables.js"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var UserMenu = _styledComponents.default.ul.withConfig({
  displayName: "UserMenu",
  componentId: "sc-1h8blo7-0"
})([".stackend &{display:flex;flex-direction:column;position:relative;width:315px;top:23px;right:270px;background:#f7f4ef;box-shadow:0 1px 10px rgba(0,0,0,0.15);", "{position:absolute;z-index:-1;display:flex;text-align:left;width:100%;height:calc(100vh);padding-top:70px;top:0;left:0;right:0;margin-bottom:0;border-radius:0;box-shadow:none;}li{display:flex;align-items:center;padding:10px;a{display:flex;width:100%;}}#pointing-arrow{right:23px;position:absolute;top:-20px;fill:#f7f4ef;stroke:rgba(0,0,0,0.15);stroke-dasharray:22;height:20px;", "{display:none;}}.create-group{width:50px;height:32px;position:relative;fill:#333;span{display:block;}}.create-astronaut{position:absolute;top:2px;left:0;width:25px;height:25px;margin:0 auto;", "{right:0;bottom:8px;}}.create-female{float:left;width:23px;height:25px;opacity:0;margin-top:4px;margin-left:-15px;transition:margin 0.5s;", "{opacity:1;margin-left:0;}}.create-male{float:left;width:25px;height:25px;opacity:0;margin-top:2px;margin-left:0;transition:margin 0.5s;", "{opacity:1;margin-left:0;}}@media screen and (min-width:767px){.create-new-group:hover{.create-female{opacity:1;margin-left:-3px;}.create-male{opacity:1;margin-left:-10px;}}}.cog{display:inline-block;position:relative;width:32px;height:32px;fill:#333;.cog-big-wheel{position:absolute;bottom:0;left:0;border-radius:50%;width:25px;height:25px;display:block;}.cog-small-wheel{position:absolute;top:0;right:0;border-radius:50%;width:15px;height:15px;display:block;}}.nav-settings:hover .cog-big-wheel{animation:rotate 7s infinite linear;}.nav-settings:hover .cog-small-wheel{animation:rotate-back 7s infinite linear alternate;}@keyframes rotate{from{transform:rotate(0deg);}to{transform:rotate(360deg);}}@keyframes rotate-back{from{transform:rotate(0deg);}to{transform:rotate(-360deg);}}", ";}"], sc.Mobile, sc.Mobile, sc.Mobile, sc.Mobile, sc.Mobile, function (props) {
  return !!props.theme.UserMenu && props.theme.UserMenu(props);
});
exports.UserMenu = UserMenu;
var _default = UserMenu;
exports.default = _default;