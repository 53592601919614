"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.StatusBar = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var StatusBar = _styledComponents.default.div.attrs({
  className: 'stackend-status-bar'
}).withConfig({
  displayName: "StatusBar",
  componentId: "sc-lf8d0l-0"
})([".stackend &{width:100%;display:flex;align-items:center;margin-top:", ";position:relative;padding:", ";gap:", ";justify-content:end;", ";}"], function (props) {
  return props.comment && props.theme.margins.small;
}, function (props) {
  return props.theme.margins.small;
}, function (props) {
  return props.theme.margins.medium;
}, function (props) {
  return !!props.theme.StatusBar && props.theme.StatusBar(props);
});
exports.StatusBar = StatusBar;
var _default = StatusBar;
exports.default = _default;