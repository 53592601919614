"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.EventInput = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _media = require("../../styled-components/media");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var EventInput = _styledComponents.default.div.attrs({
  className: 'stackend-event-input'
}).withConfig({
  displayName: "EventInputstyle__EventInput",
  componentId: "sc-15z287l-0"
})([".stackend &{display:grid;align-items:center;gap:1em;grid-template-columns:min-content auto;input{flex:1 1 auto;}label{text-align:right;}", "{grid-template-columns:min-content auto min-content auto;input[name='title']{grid-column-start:2;grid-column-end:5;}}", ";}"], _media.media.overTablet, function (props) {
  return !!props.theme.EventInput && props.theme.EventInput(props);
});
exports.EventInput = EventInput;
var _default = EventInput;
exports.default = _default;