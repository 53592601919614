"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
var _media = _interopRequireDefault(require("@stackend/react/style-common/media"));
var _reactRouterDom = require("react-router-dom");
var _StackendTheme = require("@stackend/react/theme/StackendTheme");
var _ComponentType = _interopRequireDefault(require("@stackend/react/theme/ComponentType"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var CounterBadge = _styledComponents.default.span.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-counter-badge', props.className)
  };
}).withConfig({
  displayName: "CounterBadgestyle__CounterBadge",
  componentId: "sc-1usgzy0-0"
})([".stackend &{border-radius:100%;width:2.25em;height:2.25em;padding:0.125em;display:flex;align-items:center;justify-content:center;color:", ";background-color:", ";box-shadow:0 0 5px ", ";}"], function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'backgroundColor');
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'color');
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'backgroundColor');
});
var _default = CounterBadge;
exports.default = _default;