"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RsvpNumber = exports.RsvpCounters = exports.RsvpCounter = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var RsvpCounters = _styledComponents.default.div.attrs({
  className: 'stackend-rsvp-counters'
}).withConfig({
  displayName: "RsvpCountersstyle__RsvpCounters",
  componentId: "sc-1ifb928-0"
})([".stackend &{display:flex;flex-direction:column;justify-content:space-evenly;}"]);
exports.RsvpCounters = RsvpCounters;
var RsvpCounter = _styledComponents.default.div.attrs({
  className: 'stackend-rsvp-counter'
}).withConfig({
  displayName: "RsvpCountersstyle__RsvpCounter",
  componentId: "sc-1ifb928-1"
})([".stackend &{display:flex;flex-direction:row;gap:0.5em;}"]);
exports.RsvpCounter = RsvpCounter;
var RsvpNumber = _styledComponents.default.div.attrs({
  className: 'stackend-rsvp-number'
}).withConfig({
  displayName: "RsvpCountersstyle__RsvpNumber",
  componentId: "sc-1ifb928-2"
})([""]);
exports.RsvpNumber = RsvpNumber;