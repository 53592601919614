"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ResponsiveListContainerMixin = exports.ResponsiveList = exports.ResponsiveItemMixin = void 0;
exports.calculateOptimalPageSize = calculateOptimalPageSize;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var admin = _interopRequireWildcard(require("../../styled-components/Stackend/admin-vars.js"));
var _Page = _interopRequireDefault(require("../../cms/Page"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var ResponsiveItemMixin = (0, _styledComponents.css)(["padding:0.75em;border:1px solid #e7eff6;background-color:#f7f7f7;border-radius:5px;&:hover{background-color:#e5e5e5;}&:focus{background-color:", ";}"], admin.colors.backgroundFocus);
exports.ResponsiveItemMixin = ResponsiveItemMixin;
var ResponsiveListContainerMixin = (0, _styledComponents.css)(["display:grid;grid-template-columns:1fr 1fr;grid-template-rows:auto;grid-gap:1em;padding:2px;margin-bottom:1em;margin-top:1em;", "{grid-template-columns:1fr;}", "{grid-template-columns:1fr 1fr 1fr;}", "{grid-template-columns:1fr 1fr 1fr 1fr;}"], admin.media.mobileScreen, admin.media.wideScreen1920, admin.media.wideScreen2560);

/**
 * Mixin for module listings
 */
exports.ResponsiveListContainerMixin = ResponsiveListContainerMixin;
var ResponsiveList = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-responsive-list', props.className)
  };
}).withConfig({
  displayName: "ResponsiveListstyle__ResponsiveList",
  componentId: "sc-14dwqon-0"
})([".stackend &{", ";> *{", " .name,.description{text-overflow:ellipsis;white-space:nowrap;overflow:hidden;min-width:0;max-width:100%;}.name{font-weight:bold;}.description{color:", ";}.stats{white-space:nowrap;}}> a{&:hover{color:", ";}}}"], ResponsiveListContainerMixin, ResponsiveItemMixin, admin.colors.textDiscrete, admin.colors.text);

/**
 * Get a page size that will be about a screen full of items
 * @param estimatedRowHeight
 */
exports.ResponsiveList = ResponsiveList;
function calculateOptimalPageSize(estimatedRowHeight) {
  var h = window.innerHeight;
  var w = document.body.offsetWidth;
  var nColumns = 1;
  if (w > 2560) {
    nColumns = 4;
  } else if (w >= 1920) {
    nColumns = 3;
  } else if (w >= 767) {
    nColumns = 2;
  }
  var rows = Math.round((h - 340) / estimatedRowHeight); // Estimated height of row
  var pageSize = Math.max(nColumns * rows, nColumns === 1 ? 10 : 4 * nColumns);
  //console.log('pageSize=' + pageSize, rows, nColumns);
  return pageSize;
}