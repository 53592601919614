"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SlideshowModule = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _reactResponsiveCarousel = require("react-responsive-carousel");
var _carouselMin = _interopRequireDefault(require("react-responsive-carousel/lib/styles/carousel.min.css"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//import carousel from '!raw-loader!react-responsive-carousel/lib/styles/carousel.min.css';

var SlideshowModule = (0, _styledComponents.default)(_reactResponsiveCarousel.Carousel).attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-slideshow-module', props.className)
  };
}).withConfig({
  displayName: "SlideshowModulestyle__SlideshowModule",
  componentId: "sc-11jg0ex-0"
})([".stackend &{", ";.carousel{.slide{background:inherit;}&.carousel-slider .control-arrow{padding:20px;&.control-prev:before,&.control-next:before{border-top:20px solid transparent;border-bottom:20px solid transparent;}&.control-prev:before{border-right:20px solid #fff;}&.control-next:before{border-left:20px solid #fff;}}.control-dots .dot{width:20px;height:20px;}}}"], _carouselMin.default);
exports.SlideshowModule = SlideshowModule;