"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.LikeButton = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var LikeButton = _styledComponents.default.button.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-like stackend-icon', props.className)
  };
}).withConfig({
  displayName: "LikeButton",
  componentId: "sc-19g00j1-0"
})([".stackend &{background:none;padding:0;border:none;@keyframes heartBeat{0%{transform:scale(1);}25%{transform:scale(2);}50%{transform:scale(1);}75%{transform:scale(2);}100%{transform:scale(1);}}&.stackend-like-changed .material-icons{animation:0.5s heartBeat;}", ";}"], function (props) {
  return !!props.theme.LikeButton && props.theme.LikeButton;
});
exports.LikeButton = LikeButton;
var _default = LikeButton;
exports.default = _default;