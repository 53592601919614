"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.RichContentEditor = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var RichContentEditor = _styledComponents.default.div.withConfig({
  displayName: "RichContentEditor",
  componentId: "sc-1fvjfin-0"
})([".stackend &{.mce-container-body .mce-resizehandle{display:none;}.mce-edit-area iframe{border-radius:5px;}.mce-branding{display:none;}", ";}"], function (props) {
  return !!props.theme.RichContentEditor && props.theme.RichContentEditor(props);
});
exports.RichContentEditor = RichContentEditor;
var _default = RichContentEditor;
exports.default = _default;