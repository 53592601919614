"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ContentBodyWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var ContentBodyWrapper = _styledComponents.default.div.attrs({
  className: 'stackend-content-body-wrapper'
}).withConfig({
  displayName: "ContentBodyWrapper",
  componentId: "sc-1jo7b82-0"
})([".stackend &{display:block;min-height:100%;width:100%;", ";}"], function (props) {
  return !!props.theme.ContentBodyWrapper && props.theme.ContentBodyWrapper(props);
});
exports.ContentBodyWrapper = ContentBodyWrapper;
var _default = ContentBodyWrapper;
exports.default = _default;