"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RecentThreadsLastCommentSort;
var _CreatedDateComparator = require("../../xcap/CreatedDateComparator");
var _CommentSort = require("./CommentSort");
/**
 * Order comments by threads, newest threads last,
 * FIXME: There is no backend sorting implemented that matches this 100%
 * @constructor
 */
function RecentThreadsLastCommentSort(entries) {
  var r = (0, _CommentSort.sortComments)(entries, _CreatedDateComparator.ReverseCreatedDateComparator, _CreatedDateComparator.ReverseCreatedDateComparator);
  return r;
}