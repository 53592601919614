"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _api = require("@stackend/api/api");
/**
 * A redux middle ware that catches errors in redux actions/reducers and logs the relevant stuff to the console.
 * @param store
 * @returns {function(*): function(*): *}
 */
var crashReporter = function crashReporter(store) {
  return function (next) {
    return function (action) {
      try {
        return next(action);
      } catch (err) {
        if ((0, _api.isRunningServerSide)()) {
          console.error('Caught an exception!', JSON.stringify(err));
        } else {
          console.error('Caught an exception!', err);
          console.error('redux action', action);
          console.error('redux state', store.getState());
        }
        throw err;
      }
    };
  };
};
var _default = crashReporter;
exports.default = _default;