"use strict";

var _ModulesRoot = _interopRequireDefault(require("./functions/ModulesRoot.jsx"));
var _loadCss = _interopRequireDefault(require("./functions/loadCss.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * All dependencies for running modules
 */
global.isProd = process.env.NODE_ENV === 'production';
window.xcap = {};
window.xcapLocale = {
  language: 'en',
  country: 'GB'
};
window.generalTheme = 'stackend';
window.specificTheme = 'basic';
window.__xcapDeployProfile = global.__xcapDeployProfile;
window.xcapModuleSettings = global.xcapModuleSettings;
window.contextPath = global.contextPath;
window.__xcap_api_config = global.__xcap_api_config;
if (global.isProd) {
  (0, _loadCss.default)(global.nodeServer + global.contextPath + '/launch.css');
}
(0, _ModulesRoot.default)();