"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StackendAdminPageWrapper = exports.StackendAdminPageContainer = exports.StackendAdmin = exports.AdminApp = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var admin = _interopRequireWildcard(require("../styled-components/Stackend/admin-vars.js"));
var _StackendBaseStyle = require("../styled-components/Stackend/StackendBaseStyle.js");
var _Pagination = require("@stackend/react/pagination/Pagination.style");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var AdminApp = (0, _styledComponents.default)(_StackendBaseStyle.StackendBaseStyle).withConfig({
  displayName: "AdminAppstyle__AdminApp",
  componentId: "sc-tmx4b6-0"
})([".stackend &{.Toastify__toast-container{.Toastify__toast{display:flex;align-items:center;background-color:", ";color:", ";.Toastify__toast-body{margin:0 1em;}}}", "{gap:0.25em;}}"], admin.colors.accent, admin.colors.textInverse, _Pagination.PaginationWrapper);
exports.AdminApp = AdminApp;
var StackendAdmin = _styledComponents.default.div.withConfig({
  displayName: "AdminAppstyle__StackendAdmin",
  componentId: "sc-tmx4b6-1"
})([".stackend &{display:flex;align-content:flex-start;width:100%;height:100%;background:#ffffff;overflow:hidden;}"]);
exports.StackendAdmin = StackendAdmin;
var StackendAdminPageWrapper = _styledComponents.default.div.withConfig({
  displayName: "AdminAppstyle__StackendAdminPageWrapper",
  componentId: "sc-tmx4b6-2"
})([".stackend &{position:relative;width:calc(100% - ", ");margin:", " 0 0 ", ";transition:width 0.2s,margin-left 0.2s;padding:1em;", "{width:100%;margin-left:0;padding-left:1em;}&.side-menu-closed{width:100%;margin-left:0;&.no-borders{margin-left:0;margin-top:", ";", "{width:100%;}}}}"], admin.communityMenuWidth, admin.topMenuHeight, admin.communityMenuWidth, admin.media.smallerScreen, admin.topMenuHeight, admin.media.smallerScreen);
exports.StackendAdminPageWrapper = StackendAdminPageWrapper;
var StackendAdminPageContainer = _styledComponents.default.div.withConfig({
  displayName: "AdminAppstyle__StackendAdminPageContainer",
  componentId: "sc-tmx4b6-3"
})([".stackend &{margin:0 auto;width:100%;background:#ffffff;text-align:left;overflow:hidden;&.no-borders{box-shadow:none;}", "{box-shadow:none;padding-top:0;padding-right:0;}> div{@keyframes fade-in{0%{opacity:0;}100%{opacity:1;}}animation:fade-in 0.5s ease-in-out;}}"], admin.media.smallerScreen);
exports.StackendAdminPageContainer = StackendAdminPageContainer;