"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ModalContent = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var ModalContent = _styledComponents.default.div.attrs(function (props) {
  return {
    className: 'stackend-modal-content'
  };
}).withConfig({
  displayName: "Modalstyle__ModalContent",
  componentId: "sc-gkwidr-0"
})([".stackend &{overflow:auto;-webkit-overflow-scrolling:touch;height:100%;padding:", ";}"], function (props) {
  return props.theme.margins.small;
});
exports.ModalContent = ModalContent;