"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EditableProfile = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
var _ProfileMenu = require("./ProfileMenu.style");
var _Profile = require("./Profile.style");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var EditableProfile = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-editable-profile', props.classes)
  };
}).withConfig({
  displayName: "EditableProfilestyle__EditableProfile",
  componentId: "sc-knmx6t-0"
})([".stackend &{display:grid;gap:", ";grid-template-areas:'profile empty' 'profile edit' 'logout logout';grid-template-columns:auto min-content;align-items:end;.stackend-edit-profile i{font-size:", ";}&.stackend-edit-mode{display:block;}", "{grid-area:profile;}.stackend-edit-profile{grid-area:edit;}.stackend-logout{grid-area:logout;justify-self:end;}}"], function (props) {
  return props.theme.margins.medium || '1em';
}, _ProfileMenu.ICON_SIZE, _Profile.Profile);
exports.EditableProfile = EditableProfile;