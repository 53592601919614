"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MinimalisticCommentEditor = exports.EditorPlaceholder = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var MinimalisticCommentEditor = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-minimalistic-comment-editor', props.className)
  };
}).withConfig({
  displayName: "MinimalisticCommentEditorstyle__MinimalisticCommentEditor",
  componentId: "sc-9bmvag-0"
})([""]);
exports.MinimalisticCommentEditor = MinimalisticCommentEditor;
var EditorPlaceholder = _styledComponents.default.button.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-minimalistic-comment-editor-placeholder', props.className)
  };
}).withConfig({
  displayName: "MinimalisticCommentEditorstyle__EditorPlaceholder",
  componentId: "sc-9bmvag-1"
})([".stackend &{text-align:center;width:100%;font-size:1rem;text-transform:none;}"]);
exports.EditorPlaceholder = EditorPlaceholder;