"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getGAGroupData = getGAGroupData;
var _blog = require("@stackend/api/blog");
function getGAGroupData(_ref) {
  var blog = _ref.blog;
  var groupName, groupType, groupTypeEnum;
  try {
    var gl = blog.groupRef.permalink;
    groupType = "".concat(gl.substring(0, gl.lastIndexOf('/')));
    groupName = "".concat(gl.substring(gl.lastIndexOf('/') + 1));
    groupTypeEnum = blog.groupRef.type;
  } catch (e) {
    groupName = 'unknown';
    groupType = 'unknown';
    groupTypeEnum = 'unknown';
    console.error('Error setting groupName and groupType in analytics', e);
  }
  return {
    groupName: groupName,
    groupType: groupType,
    groupTypeEnum: groupTypeEnum
  };
}