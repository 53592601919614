"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProductSelectModal = exports.Layout = exports.ButtonBox = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _ProductSelect = _interopRequireDefault(require("./ProductSelect.style"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var ProductSelectModal = _styledComponents.default.div.withConfig({
  displayName: "ProductSelectModalstyle__ProductSelectModal",
  componentId: "sc-qmfxza-0"
})([".stackend &{display:flex;flex-direction:column;justify-content:space-between;min-height:15em;}"]);
exports.ProductSelectModal = ProductSelectModal;
var Layout = _styledComponents.default.div.withConfig({
  displayName: "ProductSelectModalstyle__Layout",
  componentId: "sc-qmfxza-1"
})([".stackend &{margin:2em 0;select{margin-left:0.5em;}", "{margin-bottom:2em;}}"], _ProductSelect.default);
exports.Layout = Layout;
var ButtonBox = _styledComponents.default.div.withConfig({
  displayName: "ProductSelectModalstyle__ButtonBox",
  componentId: "sc-qmfxza-2"
})([".stackend &{margin:2em 0 0 0;display:flex;flex-direction:row;justify-content:space-between;}"]);
exports.ButtonBox = ButtonBox;