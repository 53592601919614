"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addToolbarItems = addToolbarItems;
exports.hasToolbarItem = hasToolbarItem;
exports.isMultiDimensionalToolbar = isMultiDimensionalToolbar;
var _RichContentEditor = require("../RichContentEditor2");
/**
 * Check if the toolbar definition contains multiple
 * @param toolbar
 * @param item
 * @returns {boolean}
 */
function hasToolbarItem(toolbar, item) {
  if (!toolbar) {
    return false;
  }
  for (var i = 0; i < toolbar.length; i++) {
    var ti = toolbar[i];
    if (Array.isArray(ti)) {
      var hasItem = hasToolbarItem(ti, item);
      if (hasItem) {
        return true;
      }
    } else if (ti === item) {
      return true;
    }
  }
  return false;
}
function isMultiDimensionalToolbar(toolbar) {
  if (!toolbar) {
    return false;
  }
  for (var i = 0; i < toolbar.length; i++) {
    var ti = toolbar[i];
    if (Array.isArray(ti)) {
      return true;
    }
  }
  return false;
}
function addToolbarItems(toolbar, items) {
  var fatToolbar = isMultiDimensionalToolbar(toolbar);
  items.forEach(function (i) {
    if (!hasToolbarItem(toolbar, i)) {
      if (fatToolbar) {
        toolbar.push([i]);
      } else {
        toolbar.push(i);
      }
    }
  });
}