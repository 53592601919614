"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FeedEntryInfo = exports.FeedEntryContainer = exports.FeedEntry = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _ProfileHeader = _interopRequireDefault(require("../../styled-components/Profile/ProfileHeader"));
var _styledComponents2 = require("../../styled-components");
var sc = _interopRequireWildcard(require("../../style-common/styled-component-variables.js"));
var _AuthorImage = _interopRequireDefault(require("../../styled-components/User/AuthorImage"));
var _DisplayType = require("../DisplayType");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var FeedEntryContainer = _styledComponents.default.div.attrs(function (props) {
  return {
    className: 'stackend-feed-entry-container' + (props.deleted ? ' deleted' : '') + (props.isPreview ? ' preview' : '') + (props.displayType === _DisplayType.DisplayType.FeedEntry ? ' feed-entry' : '')
  };
}).withConfig({
  displayName: "FeedEntrystyle__FeedEntryContainer",
  componentId: "sc-bb1v0w-0"
})([".stackend &{width:100%;position:relative;opacity:1;display:grid;padding:20px;background-color:", ";border-radius:", ";&.deleted{opacity:0.3;}&.feed-entry:not(.preview){grid-column:1 / span 2;}.read-more-container{place-self:center;align-self:end;}", ";}"], function (props) {
  return props.theme.shopifyMainColor || '#ccc';
}, function (props) {
  return props.theme.shopifyBorderRadius || '10px';
}, function (props) {
  return !!props.theme.FeedEntry && props.theme.FeedEntry(props);
});
exports.FeedEntryContainer = FeedEntryContainer;
var FeedEntry = _styledComponents.default.div.attrs(function (props) {
  return {
    className: 'stackend-feed-entry' + (props.isPreview ? ' preview' : '') + (props.displayType === _DisplayType.DisplayType.FeedEntry ? ' feed-entry' : '') + (props.isAI ? ' is-ai' : ' isNotAI')
  };
}).withConfig({
  displayName: "FeedEntrystyle__FeedEntry",
  componentId: "sc-bb1v0w-1"
})([".stackend &{display:grid;", "{margin-bottom:1em;}&.is-ai{.stackend-shop-product-module{display:unset;}}.stackend-shop-product-module-horizontal{display:unset;}.tag.xcap-tag{padding:0 0.5em 0 0;line-height:1.5em;.xcap-tag-symbol:after{content:'#';}}@media only screen and (min-width:1025px){.stackend-page{.stackend-page-content-live{aspect-ratio:16/11;margin-bottom:1em;}}}@media only screen and (max-width:1024px){.stackend-page{.stackend-page-content-live{height:100vh;}}}", ";}"], _styledComponents2.RichContent, function (props) {
  return !!props.theme.EntryBody && props.theme.EntryBody(props);
});
exports.FeedEntry = FeedEntry;
var FeedEntryInfo = _styledComponents.default.div.attrs({
  className: 'stackend-feed-entry-info'
}).withConfig({
  displayName: "FeedEntrystyle__FeedEntryInfo",
  componentId: "sc-bb1v0w-2"
})([".stackend &{place-self:start;display:flex;flex-direction:row;align-items:center;gap:1em;width:100%;", "{max-width:unset;display:flex;gap:1em;}button{font-size:1.25rem;}button.stackend-undo-delete{font-size:1em;white-space:nowrap;}", "{width:", ";height:", ";font-size:", ";}", "{", "{.stackend-user-name{font-size:0.8em;}}}}"], _ProfileHeader.default, _AuthorImage.default, sc.profileImageSize.small, sc.profileImageSize.small, sc.profileInitialsFontSize.small, sc.Mobile, _ProfileHeader.default);
exports.FeedEntryInfo = FeedEntryInfo;