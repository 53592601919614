"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setAnalyticsTiming = exports.setAnalyticsPageview = exports.setAnalyticsEvent = exports.firstPageView = exports.SET_ANALYTICS_PAGEVIEW = exports.SET_ANALYTICS_EVENT = exports.FIRST_PAGEVIEW = exports.ANALYTICS_TIMING_SET = void 0;
var SET_ANALYTICS_PAGEVIEW = 'SET_ANALYTICS_PAGEVIEW';
exports.SET_ANALYTICS_PAGEVIEW = SET_ANALYTICS_PAGEVIEW;
var SET_ANALYTICS_EVENT = 'SET_ANALYTICS_EVENT';
exports.SET_ANALYTICS_EVENT = SET_ANALYTICS_EVENT;
var ANALYTICS_TIMING_SET = 'ANALYTICS_TIMING_SET';
exports.ANALYTICS_TIMING_SET = ANALYTICS_TIMING_SET;
var FIRST_PAGEVIEW = 'FIRST_PAGEVIEW';
exports.FIRST_PAGEVIEW = FIRST_PAGEVIEW;
var setAnalyticsTiming = function setAnalyticsTiming() {
  return {
    type: ANALYTICS_TIMING_SET
  };
};
exports.setAnalyticsTiming = setAnalyticsTiming;
var setAnalyticsPageview = function setAnalyticsPageview(page) {
  return {
    type: SET_ANALYTICS_PAGEVIEW,
    data: page
  };
};
exports.setAnalyticsPageview = setAnalyticsPageview;
var setAnalyticsEvent = function setAnalyticsEvent(event) {
  return {
    type: SET_ANALYTICS_EVENT,
    data: event
  };
};
exports.setAnalyticsEvent = setAnalyticsEvent;
var firstPageView = function firstPageView() {
  return {
    type: FIRST_PAGEVIEW
  };
};
exports.firstPageView = firstPageView;