"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.FlexBoxWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var sc = _interopRequireWildcard(require("../../style-common/styled-component-variables.js"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var FlexBoxWrapper = _styledComponents.default.div.withConfig({
  displayName: "FlexBoxWrapper",
  componentId: "sc-df2bfl-0"
})([".stackend &{display:flex;flex-wrap:wrap;flex-direction:", ";justify-content:", ";align-items:", ";z-index:", ";width:100%;max-width:", ";margin:", ";", "{max-width:", ";}", "{justify-content:space-between;flex-direction:", ";padding:", ";}", "{margin:0 auto;justify-content:center;flex-direction:", ";}", ";}"], function (props) {
  return props.column ? 'column' : 'row';
}, function (props) {
  return props.justify;
}, function (props) {
  return props.align;
}, function (props) {
  return props.special ? '' : '1';
}, sc.maxContentWidthDesktop, function (props) {
  return props.margin || '20px auto';
}, sc.BigDesktop, sc.maxContentWidthLargeDesktop, sc.TabletAndMobile, function (props) {
  return props.tabletColumn && 'column';
}, function (props) {
  return props.paddingTablet ? '0 10px' : '0';
}, sc.Mobile, function (props) {
  return props.mobileColumn && 'column';
}, function (props) {
  return !!props.theme.FlexBoxWrapper && props.theme.FlexBoxWrapper(props);
});
exports.FlexBoxWrapper = FlexBoxWrapper;
var _default = FlexBoxWrapper;
exports.default = _default;