"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ProfileWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _AuthorImage = require("../User/AuthorImage.js");
var _FeedUserInfo = require("../Feeds/FeedUserInfo.js");
var _ShareButtonStyle = require("../../components/ShareButton.style.js");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var ProfileWrapper = _styledComponents.default.header.attrs({
  className: 'stackend-profile-wrapper'
}).withConfig({
  displayName: "ProfileWrapper",
  componentId: "sc-1l0ije5-0"
})([".stackend &{display:flex;align-items:center;width:100%;", "{flex:0 0 auto;}", "{flex:1 1 auto;}", "{align-self:flex-start;}", ";}"], _AuthorImage.AuthorImage, _FeedUserInfo.FeedUserInfo, _ShareButtonStyle.ShareButton, function (props) {
  return !!props.theme.ProfileWrapper && props.theme.ProfileWrapper(props);
});
exports.ProfileWrapper = ProfileWrapper;
var _default = ProfileWrapper;
exports.default = _default;