"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MEDIA_ELEMENTS = void 0;
exports.default = isValidRichContent;
var MEDIA_ELEMENTS = ['img', 'iframe', 'video', 'audio'];

/**
 * Validate a html string to se if it does contain some useful markup that will produce visible rich content.
 * Client side only.
 * @param html
 * @param extraTags Any extra tags that should be recognized as visible content
 * @returns {boolean}
 */
exports.MEDIA_ELEMENTS = MEDIA_ELEMENTS;
function isValidRichContent(html, extraTags) {
  if (!html) {
    return false;
  }
  var hs = html.trim();
  if (!hs) {
    return false;
  }
  var h = document.createElement('html');
  h.innerHTML = hs;
  if (h.textContent.trim() !== '') {
    return true;
  }
  for (var i = 0; i < MEDIA_ELEMENTS.length; i++) {
    var e = MEDIA_ELEMENTS[i];
    if (h.getElementsByTagName(e).length) {
      return true;
    }
  }
  if (extraTags) {
    for (var _i = 0; _i < extraTags.length; _i++) {
      var _e = extraTags[_i];
      if (h.getElementsByTagName(_e).length) {
        return true;
      }
    }
  }
  return false;
}