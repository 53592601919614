"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.RichContent = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _XcapSitePreview = _interopRequireDefault(require("./mixins/XcapSitePreview.style"));
var _XcapResponsiveSubsite = _interopRequireDefault(require("./mixins/XcapResponsiveSubsite.style"));
var _ShopItems = require("./mixins/ShopItems.style");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * Styling for rich content
 */
var RichContent = _styledComponents.default.div.attrs({
  className: 'stackend-rich-content'
}).withConfig({
  displayName: "RichContent",
  componentId: "sc-14s6a5s-0"
})([".stackend &{overflow:hidden;strong{font-weight:bold;}em{font-style:italic;}ul{list-style:disc;}ol{list-style:decimal;}ul,ol{li{display:list-item;margin-left:2em;}}.ql-align-center{text-align:center;}.ql-align-right{text-align:right;}.ql-align-justify{text-align:justify;}.ql-size-small{font-size:0.8em;}.ql-size-large{font-size:1.5em;}.ql-size-huge{font-size:2em;}img{height:auto;}img.stackend-responsive{width:100%;height:auto;}", ";", ";", "}"], _XcapResponsiveSubsite.default, _XcapSitePreview.default, _ShopItems.ShopItems);
exports.RichContent = RichContent;
var _default = RichContent;
exports.default = _default;