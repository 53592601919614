"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.PollWrapper = exports.PollTitle = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var PollTitle = _styledComponents.default.h3.attrs({
  className: 'stackend-poll-title'
}).withConfig({
  displayName: "PollWrapperstyle__PollTitle",
  componentId: "sc-re69ex-0"
})([".stackend &{", ";}"], function (props) {
  return !!props.theme.PollTitle && props.theme.PollTitle(props);
});
exports.PollTitle = PollTitle;
var PollWrapper = _styledComponents.default.div.attrs({
  className: 'stackend-poll-wrapper'
}).withConfig({
  displayName: "PollWrapperstyle__PollWrapper",
  componentId: "sc-re69ex-1"
})([".stackend &{.stackend-poll-text{height:auto;display:flex;justify-content:space-between;align-items:center;width:100%;margin-top:", ";padding-right:", ";}width:100%;display:flex;flex-direction:column;margin-top:1em;", ";}"], function (props) {
  return props.theme.margins.medium;
}, function (props) {
  return props.theme.margins.small;
}, function (props) {
  return !!props.theme.PollWrapper && props.theme.PollWrapper(props);
});
exports.PollWrapper = PollWrapper;
var _default = PollWrapper;
exports.default = _default;