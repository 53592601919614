"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Toasts = void 0;
var _styledComponents = require("styled-components");
var _StackendTheme = require("@stackend/react/theme/StackendTheme");
var _ComponentType = _interopRequireDefault(require("@stackend/react/theme/ComponentType"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var Toasts = (0, _styledComponents.css)([".stackend-toasts-container{width:auto;max-width:50vw;.Toastify__toast{display:flex;box-sizing:border-box;margin-bottom:1rem;padding:1em;border-radius:", ";color:", ";background-color:", ";word-break:break-all;.Toastify__toast-body{margin:auto 0;}}}"], function (props) {
  return props.theme.borderRadius;
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.MENU, 'color');
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.MENU, 'backgroundColor');
});
exports.Toasts = Toasts;