"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.PollPile = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _StackendTheme = require("@stackend/react/theme/StackendTheme");
var _ComponentType = _interopRequireDefault(require("@stackend/react/theme/ComponentType"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var PollPile = _styledComponents.default.div.attrs({
  className: 'stackend-poll-pile'
}).withConfig({
  displayName: "PollPilestyle__PollPile",
  componentId: "sc-y3rc44-0"
})([".stackend &{border:solid 1px red;width:100%;height:auto;display:flex;flex-direction:row;background:", ";color:", ";.stackend-poll-pile-percentage{height:2em;color:", ";background:", ";}border:", ";width:100%;}"], function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'backgroundColor');
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'color');
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'backgroundColor');
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'color');
}, function (props) {
  return (0, _StackendTheme.getComponentBorder)(props.theme, _ComponentType.default.BOX);
});
exports.PollPile = PollPile;
var _default = PollPile;
exports.default = _default;